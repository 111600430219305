






















import Vue from "vue";
export default Vue.extend({
  name: "FormSectionStarter",
  props: ["section"],
  data() {
    return { hasScrolledToBottom: false };
  },

  created() {
    if (this.section.scroll_bar_height < 300) {
      this.hasScrolledToBottom = true
    }
  },
  methods: {
    handleScroll(el: any) {
      if (
        el.srcElement.offsetHeight + el.srcElement.scrollTop >=
        el.srcElement.scrollHeight - 1
      ) {
        this.hasScrolledToBottom = true;
      }

      this.$emit('scroll', this.hasScrolledToBottom
      )
    },
  }
});


