










































import Vue from "vue";
export default Vue.extend({
  name: "ActiveFormPage",
  props: ["currentPage", "pageCounter", "showPageCounter"],
});
