import { Module, GetterTree, MutationTree, ActionTree, Mutation } from "vuex";
import store, { RootState, Nullable } from "../../store/index";
import customAxios from "@/axios-service";

export interface DashboardInfo {

  dashboard_info: {
    energy_consumption_24_hours: any;
    energy_production_24_hours: any;
  };
}

export const state: DashboardInfo = {
  dashboard_info: JSON.parse(
    sessionStorage.getItem("dashboard-info") || "null"
  ),
};

const getters: GetterTree<DashboardInfo, RootState> = {
  dashboardInfo: (state) => {
    return state.dashboard_info;
  },
};

const mutations: MutationTree<DashboardInfo> = {
  updateDashboardInfo: (state, value: any) => {
    state.dashboard_info = value;
    sessionStorage.setItem(
      "dashboard-info",
      JSON.stringify(state.dashboard_info)
    );
  },
};

const actions: ActionTree<DashboardInfo, RootState> = {
  async getDashboardInfo({ commit }) {
    return customAxios
      .get(`/api/dashboard_data`)
      .then((response) => {
        if (response.data && response.data.energy_consumption_24_hours) {
          response.data.energy_consumption_24_hours.profile.map((obj: any) => {
            if (!!response.data.energy_consumption_24_hours.total && !obj.y) {
              obj.y = 0;
            }
            return obj;
          });
        }
        if (response.data && response.data.energy_production_24_hours) {
          response.data.energy_production_24_hours.profile.map((obj: any) => {
            if (!!response.data.energy_production_24_hours.total && !obj.y) {
              obj.y = 0;
            }
            return obj;
          });
        }
        return commit("updateDashboardInfo", response.data);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
};

const namespaced = true;

const dashboardData: Module<DashboardInfo, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default dashboardData;
