



























































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import FormBody from "@/components/form/form-body.vue";

export default mixins(GeneralMixin).extend({
  name: "EquipmentCaractModal",
  props: [
    "device",
    "name",
    "secondaryButtons",
    "formLink",
    "hasParentAction",
    "excludeItemTypes",
    "excludeItemIds",
    "excludeSectionIds",
    "prevButtonTextOveride",
    "nextButtonTextOveride",
    "prevButtonActionOveride",
    "nextButtonActionOveride",
    "onCompletedActionOveride",
  ],
  components: { FormBody },
  computed: {
    isModalVisible: {
      get(): string {
        return this.$store.getters["modalState"](this.name);
      },
      set() {
        this.closeModal(this.name);
      },
    },
  },
});
