
























































import config from "@/config";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import SolidButton from "@/components/general/solid-button.vue";
import CookieModal from "@/components/general/cookie-modal.vue";

export default mixins(GeneralMixin).extend({
  name: "CookieSnackbar",
  components: {
    SolidButton,
    CookieModal,
  },
  data() {
    return {
      cookie_text: config.cookieText,
    };
  },
  computed: {
    cookieState: {
      get(): any {
        return this.$store.getters["privacyCookie"];
      },
    },
  },
  methods: {
    displayPrivacyCookieModal(status: boolean): void {
      this.$store.commit("toggleModalState", {
        name: "cookie",
        status: status,
      });
    },
  },
});
