














































import Vue from "vue";
import { DateTime } from "luxon";
import config from "@/config";

export default Vue.extend({
  name: "FormDatePicker",
  props: {
    color: {
      type: String,
      required: false,
    },
    type: {
      type: Number,
      required: false,
    },
    isCompressed: {
      type: Boolean,
      default: false,
      required: false,
    },
    labelPlaceholder: {
      type: String,
      default: "",
      required: false,
    },
    isActive: {
      type: Boolean,
      default: true,
      required: false,
    },
    isRangeHour: {
      type: Boolean,
      default: false,
      required: false,
    },
    isWeekday: {
      type: Boolean,
      default: false,
      required: false,
    },
    isMonth: {
      type: Boolean,
      default: false,
      required: false,
    },
    isYear: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabledSelections: {
      type: Array as () => number[],
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      datePicker: [] as any,
      daysWeek: config.daysWeekPT,
      months: config.monthsPT,
      labelDisplay: "",
      multiPlaceholder: "",
      menuModel: false,
      selectedValue: [] as any,
    };
  },
  // Default value should always be the most recent values
  // Unless if there is a pick all option. Might reconsider that then
  mounted() {
    if (this.isWeekday) {
      let i = 0;
      while (i < 7) {
        this.datePicker.push({
          text: this.daysWeek[i],
          value: i,
        });
        i++;
      }
      this.multiPlaceholder = "Selecionar Dias";
    }
    if (this.isRangeHour) {
      let i = 0;
      while (i < config.dayHourIntervals.length) {
        this.datePicker.push({
          text: config.dayHourIntervals[i],
          value: i,
        });
        this.selectedValue.push(i);
        i++;
      }
      this.multiPlaceholder = "Escolher Horário";
    }
    if (this.isMonth) {
      let i = 1;
      while (i <= 12) {
        this.datePicker.push({
          text: this.months[i],
          value: i,
        });
        i++;
      }
      this.multiPlaceholder = "Selecionar Meses";
    }
    if (this.isYear) {
      let currentYear = DateTime.now();
      this.selectedValue = currentYear.year;
      // TODO maybe have a date of when first data is available instead of this ?
      while (currentYear.year >= 2021) {
        this.datePicker.push(currentYear.year);
        currentYear = currentYear.minus({ years: 1 });
      }
    }
    if (this.labelPlaceholder) {
      this.labelDisplay = this.labelPlaceholder;
    }
  },
  methods: {
    emitValueChange() {
      if (this.isActive === false) {
        return;
      }
      if (this.isWeekday && this.selectedValue?.length) {
        const plural = this.selectedValue.length > 1 ? "s" : "";
        this.labelDisplay = `${this.selectedValue.length} dia${plural} selecionado${plural}`;
      }
      if (this.isMonth && this.selectedValue?.length) {
        const plural = this.selectedValue.length > 1 ? "es" : "";
        this.labelDisplay = `${
          this.selectedValue.length
        } mes${plural} selecionado${plural ? plural[1] : plural}`;
      }
      if (this.isRangeHour && this.selectedValue?.length) {
        const plural = this.selectedValue.length > 1 ? "s" : "";
        this.labelDisplay = `${this.selectedValue.length} hora${plural} selecionada${plural}`;
      }
      if (this.isWeekday || this.isMonth) {
        return this.$emit("dateRangeSelected", this.selectedValue);
      }

      this.$emit("dateSelected", this.selectedValue);
    },
  },
  watch: {
    isActive(newValue) {
      if (newValue === false) {
        this.selectedValue = [];
      }
    },
  },
});
