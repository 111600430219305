




























































import mixins from "vue-typed-mixins";
import { FormMixin } from "@/mixins/form-mixin";
import toolTip from "../general/tool-tip.vue";

export default mixins(FormMixin).extend({
  name: "FormTextarea",
  props: {
    isReadOnly: { type: Boolean, default: false },
    height: { type: String },
  },
  components: { toolTip },
});
