






















































































































// this class/component contains the "sidebar" content to be displayed on the chart cards
// generally speaking this contains information regarding the consumption, time range observed or information regarding the
// rooms such as temperature, humidity or room details (name, etc)
import Vue from "vue";
import TimeCard from "../general/time-card.vue";
import TimeRangeCard from "../general/time-range-card.vue";

export interface TimeCardInfo {
  title?: string;
  subtitle?: string;
  dates: Date[];
}

export interface RoomInfo {
  name: string;
  color: string;
  icon: string;
  time: string;
  temperature?: number;
  humidity?: number;
}

export default Vue.extend({
  name: "ChartCard",
  components: {
    TimeCard,
    TimeRangeCard,
  },
  props: {
    check: {
      required: false,
      type: Boolean,
      default: false,
    },
    displayArea: {
      required: false,
      type: String,
      default: "others",
    },
    displayConsumption: {
      required: false,
      type: Boolean,
      default: true,
    },
    totalConsumption: {
      required: false,
      type: Number,
      default: 0,
    },
    totalProduction: {
      required: false,
      type: Number,
      default: 0,
    },
    totalInjection: {
      required: false,
      type: Number,
      default: 0,
    },
    roomInfo: {
      required: false,
      type: Object as () => RoomInfo,
      default: undefined,
    },
    consumptionTimes: {
      required: true,
      type: Array as () => TimeCardInfo[],
    },
  },
});
