var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"custom-card-radius",attrs:{"elevation":"2"}},[_c('div',{staticClass:"chart-card-title-area pb-1 px-5",class:!_vm.isMagicFixStyling ? 'pt-1' : 'pt-2'},[_c('v-card-title',{staticClass:"header-5 py-0 px-0",class:{
        'header-size-fix':
          !(_vm.datePickerRange.length || _vm.weekSelector || _vm.yearSelector) &&
          !_vm.isMagicFixStyling,
      }},[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")]),(_vm.datePickerRange.length || _vm.yearSelector)?_c('form-date-selector',{staticClass:"selector fix-200-width",attrs:{"type":this.type ? this.type : 0,"color":this.type != null
          ? this.type == 0
            ? '#5080e9'
            : '#948eed'
          : '#41c5d3',"isYear":_vm.yearSelector},on:{"dateSelected":_vm.emitCardDate}}):_vm._e(),(_vm.weekSelector || _vm.fullWeekPicker || _vm.monthSingleSelector)?_c('form-date-picker',{staticClass:"selector fix-290-width",attrs:{"type":this.type ? this.type : 0,"color":this.type != null
          ? this.type == 0
            ? '#5080e9'
            : '#948eed'
          : '#41c5d3',"labelPlaceholder":'Período de Tempo',"weekStart":_vm.weekSelector,"fullWeekPicker":_vm.fullWeekPicker,"monthPicker":_vm.monthSingleSelector},on:{"dateSelected":_vm.emitCardDate}}):_vm._e()],1),_c('div',{staticClass:"d-flex flex-column flex-md-row chart-info"},[(_vm.roomInfo || _vm.displayConsumption || _vm.consumptionTimes.length)?_c('chart-add-info',{class:_vm.isMagicFixStyling ? '' : 'sidebar-content-rest',attrs:{"check":_vm.check,"displayConsumption":_vm.displayConsumption,"totalConsumption":_vm.totalConsumption,"totalProduction":_vm.totalProduction,"totalInjection":_vm.totalInjection,"consumptionTimes":_vm.consumptionTimes,"roomInfo":_vm.roomInfo,"displayArea":_vm.displayArea}}):_vm._e(),_c('chart-content',{attrs:{"type":this.type,"weekdaySelector":_vm.weekdaySelector,"monthSelector":_vm.monthSelector,"isSelectorActive":_vm.isSelectorActive,"datePickerRange":_vm.datePickerRange,"isMagicFixStyling":_vm.isMagicFixStyling,"charts":_vm.charts},on:{"dateSelected":_vm.emitChartDate}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }