




































































































































































































































import Vue from "vue";
import LineChart from "@/components/charts/line-chart.vue";
import BarChart from "@/components/charts/bar-chart.vue";
import ApplianceChart, {
  ApplianceConsumption,
} from "@/components/charts/appliance-chart.vue";
import FormDateSelector from "@/components/form/form-date-selector.vue";
import toolTip from "@/components/general/tool-tip.vue";

import config from "@/config";
import ChartAverageConsumption from "@/components/charts/chart-card-snipets/chart-average-consumption.vue";
import ChartSimpleCheckbox from "./chart-card-snipets/chart-simple-checkbox.vue";
import ChartRoomAverage from "./chart-card-snipets/chart-room-average.vue";
import MixedChart from "./mixed-chart.vue";

export interface TimeCardInfo {
  title?: string;
  subtitle?: string;
  dates: Date[];
}

// This component here is responsible for displaying the "main content" of the chart cards
// meaning chart title, specific date pickers, tooltips, whatever extra options/button are and then
// obviously including the different types of charts and legend container
// this is a mess and very confusing due to the constant update of things and as such, might contain some deprecated elements
// This is also responsible for the behaviour of the appliance chart pagination and visibility for some reason :')
// well, it was actually to facilitate changing the visible data which is displayed on the linechart next to it but
// it should've prolly been done somewhere else

export default Vue.extend({
  name: "ChartContent",
  components: {
    ApplianceChart,
    BarChart,
    FormDateSelector,
    LineChart,
    toolTip,
    ChartAverageConsumption,
    ChartSimpleCheckbox,
    ChartRoomAverage,
    MixedChart,
  },
  props: {
    datePickerRange: {
      required: false,
      default: () => [],
      type: Array as () => string[],
    },
    type: {
      required: false,
      type: Number,
    },
    weekdaySelector: {
      required: false,
      default: false,
      type: Boolean,
    },
    monthSelector: {
      required: false,
      default: false,
      type: Boolean,
    },
    isSelectorActive: {
      required: false,
      default: true,
      type: Boolean,
    },
    // This exists because the chart on the dashboard has a quite big ammount of "styling fixes" so this is to distinguish them
    isMagicFixStyling: {
      required: false,
      default: false,
      type: Boolean,
    },
    // this can be either 1 or 2
    // I added more comments on the parent class chart-card so you might want to check that
    charts: {
      required: true,
      type: Array as () => {
        chartTitle: string;
        chartType: string;
        chartInfo?: {
          data: any[];
          id: number;
          displayLegend?: boolean;
          timeInfo: {
            unitType: string;
            displayFormats: {
              [key: string]: string;
            };
            tooltipFormat: string;
          };
          labels?: string[];
          rangeValues: [];
          colors: any[];
        };
        customLabels?: string[];
        chartStack?: boolean;
        chartAppliances?: ApplianceConsumption[];
        chartPagination?: number;
        chartTooltipInfo?: string;
        chartTotalPagination?: number;
        chartExtraOptions?: {
          average?: any;
          checkboxSelection?: {
            list: string[];
            color: string;
          };
        };
      }[],
    },
  },
  data() {
    return {
      applianceDisplayInfo: config.applianceInfo as any,
    };
  },
  methods: {
    updatePagination(chartInfo: any, next: boolean) {
      return next ? chartInfo.chartPagination++ : chartInfo.chartPagination--;
    },
    updateChartDate(date: number[]) {
      this.$emit("dateSelected", date);
    },
    // this is to update the visible appliances based on the selected ones in the appliance chart
    // so it's responsible for showing up different data based on if they are selected or deselected
    // so this only works on a specific scenario where the first chart is a linear chart and second one is a
    // appliance chart
    updateAppliances(appliance: ApplianceConsumption, isSelected: boolean) {
      const chartInfo = this.charts.filter((x) => x.chartType === "line")[0]
        .chartInfo;

      if (isSelected && chartInfo) {
        chartInfo.colors.push({
          border: appliance.color,
        });
        if (chartInfo.labels) {
          if (!chartInfo.labels.includes(appliance.device_type_name)) {
            chartInfo.labels.push(appliance.device_type_name);
          } else {
            chartInfo.labels.push(
              `${appliance.device_type_name}-${appliance.id}`
            );
          }
        }
        chartInfo.data.push(appliance.profile);
      }

      if (!isSelected && chartInfo) {
        if (chartInfo.labels) {
          let pos = chartInfo.labels.indexOf(
            `${appliance.device_type_name}-${appliance.id}`
          );
          if (pos === -1) {
            pos = chartInfo.labels.indexOf(appliance.device_type_name);
          }
          if (pos) {
            chartInfo.labels.splice(pos, 1);
            chartInfo.colors.splice(pos, 1);
            chartInfo.data.splice(pos, 1);
          }
        }
      }
    },
  },
});
