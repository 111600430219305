



























































import mixins from "vue-typed-mixins";

import config from "@/config";
import chartConfig from "@/config-chart";

import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";
import FormDateSelector from "@/components/form/form-date-selector.vue";
import ChartCard from "@/components/charts/chart-card.vue";
import { ChartDataStructure, GeneralMixin } from "@/mixins/general-mixin";
import { HorariosData } from "@/store/participant/horarios";
import { DateTime } from "luxon";

export default mixins(GeneralMixin).extend({
  name: "ParticipantIntervalos",
  components: {
    ParticipantNoContentInfoCard,
    FormDateSelector,
    ChartCard,
  },
  computed: {
    horariosData: {
      get(): HorariosData {
        return this.$store.getters["horariosData/horariosInfo"];
      },
    },
  },
  data() {
    return {
      availableTimeSlots: [] as string[],
      activeSelection: true,
      consumptionChart1: {} as ChartDataStructure,
      consumptionTimes1: [
        {
          title: "Período de Tempo",
          subtitle: "Dia anterior",
          dates: [new Date()] as Date[],
        },
      ],
      consumptionChart2: {} as ChartDataStructure,
      consumptionChart3: {} as ChartDataStructure,
      consumptionTimes2: [
        {
          title: "Período de Tempo",
          subtitle: "Inicio",
          dates: [new Date()],
        },
        {
          subtitle: "Fim",
          dates: [new Date()],
        },
      ],
    };
  },

  created() {
    this.availableTimeSlots = JSON.parse(
      JSON.stringify(config.dayHourIntervals)
    );
    this.initializeChartData();
  },

  methods: {
    initializeChartData() {
      this.consumptionChart1 = JSON.parse(
        JSON.stringify(chartConfig.dayBarChartData(this.availableTimeSlots))
      );
      this.consumptionChart2 = JSON.parse(
        JSON.stringify(
          chartConfig.biggestConsumptionBarChartData(this.availableTimeSlots)
        )
      );
      this.consumptionChart3 = JSON.parse(
        JSON.stringify(
          chartConfig.averageConsumptionBarChartData(this.availableTimeSlots)
        )
      );
      this.retrieveIntervalData();
    },
    async retrieveIntervalData() {
      this.$store.commit("updateState", { loading_overlay: true });
      await this.$store.dispatch("horariosData/getHorariosInfo");
      if (this.horariosData && this.horariosData.grouped_day_before) {
        this.retrieveDayBarData();
        this.retrieveBiggestBarData();
        this.retrieveAverageBarData();
      }
      this.$store.commit("updateState", { loading_overlay: false });
    },
    async updateWeekData(dates: string[]) {
      this.$store.commit("updateState", { loading_overlay: true });
      await this.$store.dispatch("horariosData/getHorariosInfo", {
        start_date: dates[0],
        end_date: dates[1],
      });
      if (this.horariosData && this.horariosData.group_week_before) {
        this.retrieveAverageBarData();
      }
      this.$store.commit("updateState", { loading_overlay: false });
    },
    retrieveDayBarData() {
      const data = this.horariosData.grouped_day_before.profile;

      if (data) {
        this.consumptionChart1.chartInfo.labels = [];
        this.consumptionChart1.chartInfo.colors = [];
        this.consumptionChart1.chartInfo.data = [];

        this.consumptionChart1.chartTotal =
          this.horariosData.grouped_day_before.total;

        data.forEach((app) => {
          if (app.profile && app.profile.length) {
            this.consumptionTimes1[0].dates[0] = new Date(
              DateTime.now().minus({ days: 1 }).toISODate()
            );
            // it's literaly defined at the top ...
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.consumptionChart1.chartInfo.labels.push(app.device_type_name);
            let curColor = app.color;
            if (!curColor) {
              curColor = "#a0a0a0";
            }
            this.consumptionChart1.chartInfo.colors.push({
              border: curColor,
            });
            this.consumptionChart1.chartInfo.data.push(app.profile);
          }
        });

        this.consumptionChart1.isVisible = true;
      }
    },
    // This was scrapped
    retrieveBiggestBarData() {
      this.consumptionChart2.chartInfo.data = [[], []];
      this.consumptionChart2.isVisible = true;

      this.consumptionChart2.chartExtraOptions = {
        average: {
          consumption: "22 - 2h",
          peak: "24",
          fluctuation: "-2",
          increase: "20",
        },
      };
    },
    retrieveAverageBarData() {
      this.consumptionChart3.chartInfo.data = [[], [], []];

      // this.consumptionChart3.chartExtraOptions = {
      //   checkboxSelection: {
      //     list: ["Média", "Dias de Semana", "Fins de semana"],
      //     color: "red",
      //     defaultCheck: true,
      //   },
      // };

      const data = this.horariosData.group_week_before;

      if (data) {
        this.consumptionChart3.chartTotal = data.total;
        if (data.start_date && data.end_date) {
          this.consumptionTimes2[0].dates[0] = new Date(data.start_date);
          this.consumptionTimes2[1].dates[0] = new Date(data.end_date);
        }
        if (data.profile.full) {
          this.consumptionChart3.chartInfo.data[0] = data.profile.full;
        }
        if (data.profile.weekdays) {
          this.consumptionChart3.chartInfo.data[1] = data.profile.weekdays;
        }
        if (data.profile.weekend) {
          this.consumptionChart3.chartInfo.data[2] = data.profile.weekend;
        }

        this.consumptionChart3.isVisible = true;
      }
    },
    updateSelectedTime(times: number[]) {
      const chartInfo1 = this.consumptionChart1.chartInfo;
      const chartInfo2 = this.consumptionChart3.chartInfo;
      this.consumptionChart1.customLabels = [];
      this.consumptionChart3.customLabels = [];

      this.availableTimeSlots = [];

      if (chartInfo1 && chartInfo2) {
        times.sort().forEach((index: number) => {
          const time = config.dayHourIntervals[index];
          if (
            this.consumptionChart1.customLabels !== undefined &&
            this.consumptionChart3.customLabels !== undefined
          ) {
            this.consumptionChart1.customLabels.push(time);
            this.consumptionChart3.customLabels.push(time);

            this.availableTimeSlots.push(time);
          }
        });
      }
    },
    updateSelectedDates(dates: string[]) {
      if (!dates.length) {
        return;
      }

      this.activeSelection = false;
      this.updateWeekData(dates);
      this.activeSelection = true;
    },
  },
});
