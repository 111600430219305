




import Vue from "vue";

export default Vue.extend({
  name: "FormCustom",
  props: ["item"],
  data() {
    return {
      formItem: this.item,
      cp: `<div>${this.item.custom}</div>`,
    };
  },
  computed: {
    vals() {
      return { ...this.$attrs };
    },
    componentToShow(): any {
      return { template: this.cp };
    },
  },
});
