var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"d-none d-md-flex py-4",attrs:{"justify":"center"}},[_c('v-col',{ref:"carousel",staticClass:"d-flex justify-center step-container",staticStyle:{"overflow":"hidden"},attrs:{"cols":"12"}},_vm._l((_vm.pages),function(page,index){return _c('div',{key:page.id},[(
          !_vm.isOverflown ||
          (_vm.pageCounter >= index - 1 && _vm.pageCounter <= index + 1)
        )?_c('div',{staticClass:"step-group"},[_c('div',{staticClass:"step-number rounded-circle",class:{
            'step-number-completed':
              _vm.pageCounter > index || _vm.pageCounter == _vm.pages.length - 1,
            'step-number-current':
              _vm.pageCounter == index && _vm.pageCounter != _vm.pages.length,
          }},[(_vm.pageCounter > index || _vm.pageCounter == _vm.pages.length - 1)?_c('v-icon',{attrs:{"dark":"","size":"30"}},[_vm._v(" mdi-check ")]):_vm._e(),(_vm.pageCounter <= index && _vm.pageCounter != _vm.pages.length)?_c('span',[_vm._v(" "+_vm._s(index + 1)+" ")]):_vm._e()],1),_c('div',{staticClass:"step-title text-center",class:{
            'step-title-completed':
              _vm.pageCounter > index || _vm.pageCounter == _vm.pages.length - 1,
            'step-title-current':
              _vm.pageCounter == index && _vm.pageCounter != _vm.pages.length,
          }},[_vm._v(" "+_vm._s(page.short_title)+" ")])]):_vm._e(),(!_vm.isOverflown || _vm.pageCounter + 1 > index)?_c('div',{staticClass:"step-divider"}):_vm._e()])}),0),_c('v-col',{staticClass:"step-area-border py-0",attrs:{"cols":"12"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }