




















































import Vue from "vue";

export interface StateInfo {
  name: string;
  icon?: string;
  value?: string;
  unit?: string;
}

export default Vue.extend({
  name: "TabSelector",
  props: {
    styleChoice: {
      required: false,
      default: 1,
      type: Number,
    },
    color: {
      required: false,
      default: "light-blue-1",
      type: String,
    },
    tabInfo: {
      required: true,
      type: Array as () => StateInfo[],
    },
    emitMethod: {
      required: false,
      default: "tabState",
      type: String,
    },
  },
  data() {
    return {
      curState: 0,
    };
  },
  methods: {
    updateState(state: number) {
      this.curState = state;
      this.$emit(this.emitMethod, state);
    },
  },
});
