






























































import Vue from "vue";
export default Vue.extend({
  name: "ParticipantNoContentInfoCard",
  props: [
    "min_heightMB",
    "min_heightDT",
    "icon",
    "equipments",
    "size",
    "cardTitle",
    "messageTitle",
    "messageText",
    "roughEdges",
    "check",
    "height",
    "ele",
  ],
});
