import { render, staticRenderFns } from "./tab-button-selector.vue?vue&type=template&id=c95d6b40&scoped=true&"
import script from "./tab-button-selector.vue?vue&type=script&lang=ts&"
export * from "./tab-button-selector.vue?vue&type=script&lang=ts&"
import style0 from "./tab-button-selector.vue?vue&type=style&index=0&id=c95d6b40&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c95d6b40",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VSheet,VSlideGroup,VSlideItem})
