










































































import Vue from "vue";

interface StateInfo {
  name: string;
  icon: string;
  notificationNumber?: number;
}

export default Vue.extend({
  name: "TabButtonSelector",

  props: {
    activities: {
      required: false,
      default: false,
      type: Boolean,
    },
    curTab: {
      required: false,
    },
    tabInfo: {
      required: true,
      type: Array as () => StateInfo[],
    },
    emitMethod: {
      required: false,
      default: "tabButtonState",
      type: String,
    },
  },
  data() {
    return {
      width: window.innerWidth,
      curState: this.curTab ? this.curTab : 0,
      curStateTmp: Number(this.$route.params.curTab) as any,
    };
  },

  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    newHistoryTaskLength: {
      get(): any {
        return this.$store.getters["activityData2/getHistoryNew"];
      },
    },

    newTaskLenght: {
      get(): any {
        return this.$store.getters["activityData2/generalUpdate"](1);
      },
    },
    newProjectLength: {
      get(): any {
        return this.$store.getters["activityData2/generalUpdate"](2);
      },
    },
  },
  methods: {
    updateState(state: number) {
      this.curState = state;
      this.curStateTmp = null;
      this.$emit(this.emitMethod, state);
    },
    onResize(e: any) {
      this.width = window.innerWidth;
    },
  },
});
