var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"appliance-consumption d-flex flex-column",attrs:{"set":(_vm.visibleAppliances = _vm.applianceConsumption.slice(
      _vm.perTabPagination * _vm.pagination,
      _vm.perTabPagination * (_vm.pagination + 1)
    ))}},_vm._l((_vm.visibleAppliances),function(appliance,i){return _c('div',{key:i,staticClass:"appliance-selector d-flex align-center px-4 py-2",class:{
      'mb-2': i !== _vm.visibleAppliances.length - 1,
      'mb-0': i === _vm.visibleAppliances.length - 1,
      'is-responsive': _vm.pagination === 0,
    }},[_c('v-simple-checkbox',{attrs:{"color":"#3bbbc8","value":appliance.id,"ripple":false,"rules":_vm.validateMaximum,"disabled":_vm.validateMaximum(appliance.id)},on:{"input":function($event){return _vm.applianceSelection(appliance, appliance.isSelected)}},model:{value:(
        _vm.applianceConsumption[i + _vm.pagination * _vm.perTabPagination].isSelected
      ),callback:function ($$v) {_vm.$set(_vm.applianceConsumption[i + _vm.pagination * _vm.perTabPagination], "isSelected", $$v)},expression:"\n        applianceConsumption[i + pagination * perTabPagination].isSelected\n      "}}),_c('div',{staticClass:"icon-area d-flex justify-center mr-2"},[_c('img',{staticClass:"appliance-icon",attrs:{"src":_vm.getApplianceIcon(appliance.device_type_id)}})]),_c('div',{staticClass:"full-width-div"},[_c('p',{staticClass:"semi-strong-text text-x mb-0",class:{
          'ellipsis-break': _vm.$vuetify.breakpoint.md,
        }},[_vm._v(" "+_vm._s(appliance.device_type_name)+" ")]),_c('div',{staticClass:"d-flex align-center mt-1 mt-sm-0"},[(appliance.percentageConsumption)?_c('div',{staticClass:"fill-content mr-2",style:(("background-color: " + (appliance.color) + "; width: " + (appliance.percentageConsumption) + "%"))}):_vm._e(),_c('p',{staticClass:"text-2 mb-0 d-flex no-wrap"},[_vm._v(_vm._s(appliance.energy)+" kWh")])])])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }