



























































import moment from "moment";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
export default mixins(GeneralMixin).extend({
  name: "NotificationsModal",
  props: ["notificationTask"],
  methods: {
    toggleHover(i: any) {
      this.turned = 1;
    },
    toggleNotHover(i: any) {
      this.turned = 0;
    },

    getTitle(notification: any): string {
      const date = new Date();
      if (moment(notification.conclusion_date).diff(date, "days") <= 5) {
        return "Acaba as tuas tarefas antes do fim!";
      } else {
        return notification.title;
      }
    },

    getIconProject(notification: any): any {
      const date = new Date();
      // if (moment(notification.conclusion_date).diff(date, "days") <= 5) {
      //   return require("@/assets/icons/icon-caution-notifications.svg");
      // }

      if (notification.is_general_project == true) {
        return require("@/assets/icons/activity/icon-activity.svg");
      } else {
        return require("@/assets/icons/icon-projects.svg");
      }

      // notification.is_general_project == true
      //   ? require("@/assets/icons/activity/icon-activity.svg")
      //   : require("@/assets/icons/icon-projects.svg");
    },

    getTypeTask(notification: any): string {
      const date = new Date();
      if (moment(notification.conclusion_date).diff(date, "days") <= 5) {
        return `Prazo de ${
          notification.is_general_project == true ? "tarefa" : "projeto"
        } a terminar`;
      }
      if (notification.is_general_project == true) {
        return "Nova Atividade LE disponível";
      } else {
        return "Novo Projeto Disponível";
      }
    },
  },
  data() {
    return {
      turned: 0,
      notifications: [
        {
          id: 1,
          title: "Novo Projeto Disponível",
          subtitle: "Mais Vida aos Eletrodomésticos",
        },
        {
          id: 2,
          title: "Nova Atividade LE Disponível",
          subtitle: "Ajude-nos a responder",
        },
        {
          id: 3,
          title: "Novo Voucher Adicionado",
          subtitle: "Mais Vida aos Eletrodomésticos",
        },
        {
          id: 4,
          title: "Nova Secção Disponível",
          subtitle: "Consulta a tua Produção Solar",
        },
        {
          id: 5,
          title: "Nova Secção Disponível",
          subtitle: "Consulta a tua Produção Solar",
        },
        {
          id: 6,
          title: "Nova Secção Disponível",
          subtitle: "Consulta a tua Produção Solar",
        },
      ],
    };
  },
});
