


















































import Vue from "vue";

export default Vue.extend({
  name: "ChartAverageConsumption",
  props: {
    chartData: {
      required: true,
      type: Object as () => {
        consumption: string;
        peak: number;
        fluctuation: number;
        increase: number;
      },
    },
  },
});
