



















































































// this is the main class responsible for showing the card component that contains the charts and related information
// this has some issues which you will be able to see :')
import Vue from "vue";
import { ApplianceConsumption } from "@/components/charts/appliance-chart.vue";
import FormDatePicker from "@/components/form/form-date-picker.vue";
import FormDateSelector from "@/components/form/form-date-selector.vue";
import ChartAddInfo from "./chart-add-info.vue";
import ChartContent from "./chart-content.vue";

export interface TimeCardInfo {
  title?: string;
  subtitle?: string;
  dates: Date[];
}

export default Vue.extend({
  name: "ChartCard",
  data() {
    return {
      injectionTotal: this.totalInjection,
    };
  },
  components: {
    FormDateSelector,
    FormDatePicker,
    ChartAddInfo,
    ChartContent,
  },
  // I know, this has waaaaaay to many props
  props: {
    check: {
      required: false,
      type: Boolean,
      default: false,
    },
    displayArea: {
      required: false,
      type: String,
      default: "others",
    },
    type: {
      required: false,
      type: Number,
    },
    cardTitle: {
      required: true,
      type: String,
    },
    // display consumption value on the sidebar
    displayConsumption: {
      required: false,
      type: Boolean,
      default: true,
    },
    totalConsumption: {
      required: false,
      type: Number,
      default: 0,
    },
    totalProduction: {
      required: false,
      type: Number,
      default: 0,
    },
    totalInjection: {
      required: false,
      type: Number,
      default: null,
    },
    // these are the different values to show on the sidebar
    consumptionTimes: {
      required: false,
      type: Array as () => TimeCardInfo[],
      default: () => [],
    },
    // DEPRECATED (i think) I believe this was to allow/disallow custom values when using date pickers
    datePickerRange: {
      required: false,
      default: () => [],
      type: Array as () => string[],
    },
    // room information to be displayed on the sidebar. Used in the quality page
    roomInfo: {
      required: false,
      default: undefined,
      type: Object as () => any,
    },
    // selectors are basically to define which type of date picker you want to have (if any)
    // So, this is were the main issues of this component start. It was defined that certain types of date pickers would be
    // restricted to a certain place. Monthselector and Weekday selector are restricted date pickers which will be in another
    // child component, while the others are date pickers visibible right next to the card title
    weekdaySelector: {
      required: false,
      default: false,
      type: Boolean,
    },
    weekSelector: {
      required: false,
      default: false,
      type: Boolean,
    },
    fullWeekPicker: {
      required: false,
      default: false,
      type: Boolean,
    },
    // disables date picker. Useful to block the user to do another request before receiving response from the previous one
    isSelectorActive: {
      required: false,
      default: true,
      type: Boolean,
    },
    yearSelector: {
      required: false,
      default: false,
      type: Boolean,
    },
    monthSelector: {
      required: false,
      default: false,
      type: Boolean,
    },
    monthSingleSelector: {
      required: false,
      default: false,
      type: Boolean,
    },
    // This exists because the chart on the dashboard has a quite big ammount of "styling fixes" so this is to distinguish them
    isMagicFixStyling: {
      required: false,
      default: false,
      type: Boolean,
    },
    // this can be either 1 or 2
    // data information to pass to the charts to be created
    charts: {
      required: true,
      type: Array as () => {
        chartTitle: string;
        chartType: string;
        chartInfo?: {
          data: any[];
          id: number;
          displayLegend?: boolean;
          timeInfo: {
            unitType: string;
            displayFormats: {
              [key: string]: string;
            };
            tooltipFormat: string;
          };
          labels?: string[];
          rangeValues: [];
          colors: any[];
        };
        chartStack?: boolean;
        chartAppliances?: ApplianceConsumption[];
        // chart pagination and total pagination are required for the appliance chart selector
        // as it's behaviour is not being controlled on the appliance-chart class but on it's parent component.
        chartPagination?: number;
        chartTotalPagination?: number;
        chartExtraOptions?: {
          average?: any;
          checkboxSelection?: {
            list: string[];
            color: string;
          };
        };
      }[],
    },
  },
  methods: {
    // as mentioned before, datepickers are located in 2 distinct places (near chart title or card title)
    // for this case, there are both of these event emiters to distinguish them on the main activity pages
    emitChartDate(value: any) {
      this.$emit("chartSelection", value);
    },
    emitCardDate(value: any) {
      if (value && (value.length || typeof value === "number")) {
        this.$emit("cardSelection", value);
      }
    },
  },
});
