
























import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
export default mixins(GeneralMixin).extend({
  name: "SuccessModal",
  props: ["modalName", "modalName2", "text", "header", "alt", "icon"],
  methods: {
    closeModals(modalName: any, modalName2: any) {
      this.closeModal(modalName);
      this.closeModal(modalName2);
      this.$store.dispatch("getGeneralTasks")
    }
  },
  computed: {
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
      set(): any {
        this.closeModal(this.modalName);
      },
    },
  },
});
