


























































import mixins from "vue-typed-mixins";
import { FormMixin } from "@/mixins/form-mixin";
import toolTip from "../general/tool-tip.vue";

export default mixins(FormMixin).extend({
  name: "FormCheckImg",
  components: { toolTip },
  props: {
    isReadOnly: { type: Boolean, default: false },
  },
  created() {
    this.parseFieldOptions();
  },
  methods: {
    changeValue(value: any) {
      this.fieldValue = value;
      this.setVal();
    },
  },
});
