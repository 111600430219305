import { Module, GetterTree, MutationTree, ActionTree, Mutation } from "vuex";
import store, { RootState, Nullable } from "../../store/index";
import customAxios from "@/axios-service";

export interface GeneralInfo {
  profile_info: Nullable<[]>;
}

export const state: GeneralInfo = {
  profile_info: JSON.parse(sessionStorage.getItem("profile-info") || "null"),
};

const getters: GetterTree<GeneralInfo, RootState> = {
  profileInfo: (state) => {
    return state.profile_info;
  },
};

const mutations: MutationTree<GeneralInfo> = {
  updateProfileInfo: (state, value: any) => {
    state.profile_info = value;
    sessionStorage.setItem("profile-info", JSON.stringify(state.profile_info));
  },
};

const actions: ActionTree<GeneralInfo, RootState> = {
  async getProfileInfo({ commit }) {
    return customAxios
      .get(`/record/profile_info`)
      .then((response) => {
        return commit("updateProfileInfo", response.data);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
};

const namespaced = true;

const participantGeneral: Module<GeneralInfo, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default participantGeneral;
