




































































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import FormModal from "@/components/general/form-modal.vue";
// import ParticipantSupportSuccessModal from "@/components/participant/participant-support-success-modal.vue";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";
import PercentageBar from "./percentage-bar.vue";
import TabSelector from "@/components/general/tab-selector.vue";
import SolidButton from "@/components/general/solid-button.vue";
import VoucherModal from "./voucher-modal.vue";
import ExternalModal from "@/components/general/external-modal.vue";

interface CreditInfo {
  currentCredits: number;
  spentCredits: number;
  email: string;
  available: {
    id?: number;
    name: string;
    value: string;
    total: number;
    isAvailable?: boolean;
  }[];
  spent: {
    name: string;
    value: string;
    total: number;
    date: string;
    status: string;
  }[];
}

export default mixins(GeneralMixin).extend({
  name: "CreditCard",
  components: {
    // ParticipantSupportSuccessModal,
    ParticipantNoContentInfoCard,
    FormModal,
    PercentageBar,
    TabSelector,
    SolidButton,
    VoucherModal,
    ExternalModal,
  },

  props: {
    credits: {
      required: true,
      type: Object as () => CreditInfo,
    },
  },
  data() {
    return {
      curTab: 0,
      successModal: "voucher-success-",
    };
  },
  //   created() {
  //   },
  methods: {
    statusText(val: string) {
      switch (val) {
        case "Completed":
          return "Enviado";
        case "Pending":
          return "Pendente";
        case "Canceled":
          return "Cancelado";
        default:
          return "Pendente";
      }
    },
    changeTab(tab: number) {
      this.curTab = tab;
    },
    exchangeVoucher(id: number) {
      this.openModal(`voucherModal${id}`);
    },
    voucherSuccessText(email: string, name: string, credits: number): string {
      return `<h5 class="header-4 mb-5">Levantar Voucher</h5>
        <p class="text-center"> O ${name} foi enviado para o email 
        <span class="strong-text light-blue-text">${email}</span> 
        </p>         
        <p class="text-center"> Tem 
        <span class="strong-text light-blue-text">${credits} créditos </span>
        disponíveis para gastar.
        </p>`;
    },
    async requestExchange(id: number) {
      // TODO put a request here and update total only on success
      const index = this.credits.available.findIndex((x) => x.id === id);
      if (index >= 0) {
        const curVoucher = this.credits.available[index];

        const ve = await this.$store.dispatch(
          "activityData/requestVoucherExchange",
          { voucher_item_id: id }
        );
        if (ve === this.credits.currentCredits - curVoucher.total) {
          this.credits.currentCredits = ve;
          this.openModal(`${this.successModal}${id}`);
          this.$emit("updateHistory");
        }
      }
    },
  },
});
