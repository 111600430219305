
















































































































import mixins from "vue-typed-mixins";
import { ActivityMixin } from "@/mixins/activity-mixin";
import taskCard from "./task-card.vue";
export default mixins(ActivityMixin).extend({
  components: { taskCard },
  name: "ActivityHistoryInfo",
  props: ["task", "index"],
});
