





























































import config from "@/config";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import FormModal from "@/components/general/form-modal.vue";
import FormBody from "@/components/form/form-body.vue";
import TabButtonSelector from "@/components/general/tab-button-selector.vue";
import CollapserCmp from "@/components/general/collapser-cmp.vue";
import SlackCard from "@/components/general/slack-card.vue";
import WhatsappCard from "@/components/general/whatsapp-card.vue";
import ExternalModal from "@/components/general/external-modal.vue";

interface Faq {
  text: string;
  title: string;
}

interface TabInfo {
  name: string;
  id: string;
  icon: string;
}

type FaqObjects = Record<string, Faq[]>;

export default mixins(GeneralMixin).extend({
  name: "SupportPage",
  components: {
    ExternalModal,
    FormModal,
    FormBody,
    TabButtonSelector,
    CollapserCmp,
    SlackCard,
    WhatsappCard,
  },
  computed: {
    faqData: {
      get(): FaqObjects {
        const data: FaqObjects = this.$store.getters["supportData/supportInfo"];

        if (this.isEv4eu && data) {
          const faqs: Record<string, Faq[]> = Object.fromEntries(
            Object.entries(data).map(
              ([key, value]: [string, Faq[]]): [string, Faq[]] => [
                key,
                value.filter(
                  ({ title }: { title: string }) =>
                    !this.hasToHideOnEv4eu.includes(title)
                ),
              ]
            )
          );
          return faqs;
        }

        return data;
      },
    },
  },
  data() {
    return {
      close: 0 as number,
      counter: 0 as number,
      curTab: 0,
      tabInfo: [] as TabInfo[],
      successModal: false,
      supportSuccessImage: "icon-sent-message.svg",
      supportSuccessTitle: config.supportSuccessTitle,
      supportSuccessText: config.supportSuccessText,
    };
  },
  created() {
    this.$store.commit("supportData/updateOpenedSupportId", -1);
    this.retrieveFAQData();
    this.getSupportTabs();
  },
  beforeRouteEnter(to, from, next) {
    const scrollView = document.getElementById("main-participant-view");
    if (scrollView) {
      scrollView.scrollTop = 0;
    }
    next();
  },
  methods: {
    async retrieveFAQData() {
      this.$store.commit("updateState", { loading_overlay: true });
      await this.$store.dispatch("supportData/getSupportInfo");
      this.$store.commit("updateState", { loading_overlay: false });
    },
    changeState(state: number) {
      this.$store.commit("supportData/updateOpenedSupportId", -1);
      this.curTab = state;
    },
    changeSupportSuccessDetails(page_info: any) {
      const val = page_info.sections[0].items[7].val;
      if (!val) {
        this.supportSuccessImage = "icon-sent-message.svg";
        this.supportSuccessTitle = config.supportSuccessTitle;
        this.supportSuccessText = config.supportSuccessText;
      } else if (val == "Sim, gostaria de continuar") {
        this.supportSuccessImage = "icon-thank-you.svg";
        this.supportSuccessTitle = "Obrigado pela tua confiança!";
        this.supportSuccessText =
          "Ficamos contentes por continuares a acreditar no projeto! \
          Vamos entrar em contacto contigo muito em breve para conversarmos no sentido de melhorar \
          a tua experiência no Living Energy.";
      } else if (val == "Não, já não tenho interesse") {
        this.supportSuccessImage = "icon-exit.svg";
        this.supportSuccessTitle = "Pedido de Desistência Registado";
        this.supportSuccessText =
          "A nossa equipa vai verificar o teu pedido de desistência e \
          um dos nossos colaboradores entrará brevemente em contacto contigo para dar seguimento \
          ao processo.";
      }
      this.openModal("support-success");
    },
    getSupportTabs(): void {
      const { supportTabs } = config ?? {};

      if (!supportTabs) return;

      /** If the user is in the 'EV4EU' project, filter out the tab for
       * 'Equipment' from the support tabs, otherwise assign the entire
       * support tabs array to tabInfo */
      this.tabInfo = this.isEv4eu
        ? supportTabs.filter(({ id }) => id !== "Equipment")
        : supportTabs;
    },
  },
});
