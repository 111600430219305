











































































































































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import solidButton from "./solid-button.vue";
import router from "@/router";

export default mixins(GeneralMixin).extend({
  components: { solidButton },
  name: "ProjectDetailsModal",
  props: ["project", "isOnCourse", "isGeneral", "modalName"],
  data() {
    return {
      checked: false,
      hasScrolledToBottom: false,
      showRedirectLoader: false,
    };
  },
  methods: {
    checkCorrect() {
      this.checked = !this.checked;
    },
    handleSubmit() {
      const projectDetailsId = +this.modalName?.split("-")?.slice(-1);

      if (projectDetailsId === 13) {
        this.showRedirectLoader = true;
        setTimeout(() => {
          this.onSetProjectActive();
          router.push("/participant/monitores");
        }, 3000);
        return;
      }

      this.onSetProjectActive();
    },
    handleScroll: function (el: any) {
      if (
        el.srcElement.offsetHeight + el.srcElement.scrollTop >=
        el.srcElement.scrollHeight - 1
      ) {
        this.hasScrolledToBottom = true;
      }
    },
    onSetProjectActive(): void {
      this.$store.dispatch("activityData2/setActiveProj", this.project.id);
      this.closeModal(this.modalName);
    },
  },
  computed: {
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        this.closeModal(this.modalName);
      },
    },
  },
});
