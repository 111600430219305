var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isFieldVisible)?_c('v-col',{class:_vm.formItem.show_title &&
    (_vm.isFieldLabelVisible || (_vm.formItem.title && _vm.formItem.title != ''))
      ? 'my-0 py-0'
      : 'my-1 py-1'},[(
      _vm.formItem.show_title &&
      !_vm.formItem.show_title_as_float &&
      (_vm.isFieldLabelVisible || (_vm.formItem.title && _vm.formItem.title != ''))
    )?_c('div',{staticClass:"texto-martelo semi-strong-text mt-1",domProps:{"innerHTML":_vm._s(_vm.isFieldLabelVisible ? _vm.formItem.title : null)}}):_vm._e(),(_vm.formItem.help_text && _vm.formItem.help_text != '')?_c('div',{staticClass:"help-text mt-1t"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formItem.help_text)}}),(_vm.formItem.tool_tip && _vm.formItem.tool_tip != '')?_c('tool-tip',{attrs:{"helpText":_vm.formItem.tool_tip}}):_vm._e()],1):_vm._e(),_c('v-textarea',{staticClass:"text-equips",class:[_vm.hasValue],attrs:{"placeholder":_vm.formItem.placeholder,"outlined":"","dense":"","rules":[_vm.validateField],"readonly":_vm.formItem.is_readonly || _vm.isReadOnly,"filled":_vm.formItem.is_readonly,"flat":true,"height":_vm.formItem.field_height ? _vm.formItem.field_height : 250},on:{"keyup":_vm.setVal,"change":_vm.setVal},scopedSlots:_vm._u([{key:"label",fn:function(){return [(
          _vm.formItem.show_title &&
          _vm.formItem.show_title_as_float &&
          (_vm.isFieldLabelVisible || (_vm.formItem.title && _vm.formItem.title != ''))
        )?_c('span',{staticClass:"input-label"},[_vm._v(" "+_vm._s(_vm.isFieldLabelVisible ? _vm.isFieldLabelVisible : _vm.formItem.title)+" ")]):_vm._e()]},proxy:true}],null,false,2011446895),model:{value:(_vm.fieldValue),callback:function ($$v) {_vm.fieldValue=$$v},expression:"fieldValue"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }