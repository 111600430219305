var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex py-0 px-0 align-start",attrs:{"fluid":""}},[_c('v-row',{staticClass:"participant-content-area"},[_c('v-col',{attrs:{"cols":"12"}},[_c('chart-card',{attrs:{"check":true,"cardTitle":'Dia Anterior',"displayArea":'production',"totalConsumption":this.consumptionData
        ? this.consumptionData.day_before.energy_consumption_24_hours
          .total
        : null,"totalProduction":this.productionData
  ? this.productionData.day_before.energy_consumption_24_hours.total
  : null,"totalInjection":this.injectionData
  ? this.injectionData.day_before.energy_consumption_24_hours.total
  : 0,"consumptionTimes":_vm.consumptionTimes1,"charts":[_vm.consumptionProductionDailyChart]}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('chart-card',{attrs:{"check":true,"cardTitle":'Produção Semanal',"totalProduction":this.productionData
        ? this.productionData.week_before.mains.total
        : 0,"totalInjection":this.productionData ? this.injectionData.week_before.mains.total : 0,"totalConsumption":this.consumptionData
  ? this.consumptionData.week_before.mains.total
  : 0,"displayArea":'production',"weekdaySelector":false,"isSelectorActive":_vm.isWeekdayActive,"weekSelector":true,"consumptionTimes":_vm.consumptionTimes2,"charts":[
          _vm.consumptionProductionWeeklyChart1,
          _vm.consumptionProductionWeeklyChart2 ]},on:{"cardSelection":_vm.updateSelectedWeek,"chartSelection":_vm.updateActiveWeekDays}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('chart-card',{attrs:{"cardTitle":'Produção Anual',"totalProduction":this.productionData ? this.productionData.annual.mains.total : 0,"totalInjection":this.injectionData ? this.injectionData.annual.mains.total : 0,"totalConsumption":this.consumptionData ? this.consumptionData.annual.mains.total : 0,"displayArea":'production',"check":true,"weekdaySelector":false,"yearSelector":true,"isSelectorActive":_vm.isWeekdayActive,"consumptionTimes":_vm.consumptionTimes3,"charts":[
          _vm.consumptionProductionAnnualChart1,
          _vm.consumptionProductionAnnualChart2 ]},on:{"cardSelection":_vm.updateSelectedYear,"chartSelection":_vm.updateActiveWeekDays}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }