





import Chart from "chart.js/auto";

// This import is required to use the timer adaptor for the chart
import "chartjs-adapter-luxon";

import mixins from "vue-typed-mixins";
import { ChartMixin } from "@/mixins/chart-mixin";

export default mixins(ChartMixin).extend({
  name: "LineChart",
  props: {
    chartID: {
      required: true,
      type: String,
    },
    displayLegend: {
      required: false,
      default: false,
      type: Boolean,
    },
    chartTitle: {
      required: false,
      default: "",
      type: String,
    },
    timeInfo: {
      required: true,
      type: Object as () => {
        unitType: string;
        displayFormats?: any;
        tooltipFormat?: string;
      },
    },
    rangeValues: {
      required: true,
      type: Object as () => {
        max: number;
        min: number;
      },
    },
    tooltipInfo: {
      required: false,
      type: String,
    },
    chartInfo: {
      required: true,
      type: Object as () => {
        data: [];
        labels?: [];
        // these can be either an object with 2 values (for gradient) or a string
        colors: [];
      },
    },
  },
  data() {
    return {
      chartRef: undefined as any,
    };
  },
  mounted() {
    const curCanvas: HTMLCanvasElement = document.getElementById(
      this.chartID
    ) as HTMLCanvasElement;

    const options = this.getLineChartOption({
      chartTitle: this.chartTitle,
      chartId: this.chartID,

      min: this.rangeValues.min,
      max: this.rangeValues.max,

      unitType: this.timeInfo.unitType,
      displayFormats: this.timeInfo.displayFormats,
      tooltipFormat: this.timeInfo.tooltipFormat,
      tooltipUnit: this.tooltipInfo,
      hasLegend: this.displayLegend,
    });

    const chartData = this.formatChartData();

    this.chartRef = new Chart(curCanvas, {
      type: "line",
      data: {
        datasets: chartData as any,
      },
      options: options,
      plugins: [this.externalLegendHandler()],
    });
  },
  methods: {
    formatChartData() {
      const gradientMethod = this.getGradient;
      const chartData = [];

      for (let i = 0; i < this.chartInfo.data.length; i++) {
        const curColor: any = this.chartInfo.colors[i];
        const curLabels = this.chartInfo.labels;
        const tempData = {
          label: curLabels && curLabels.length ? curLabels[i] : "",
          data: this.chartInfo.data[i],
          backgroundColor: (
            curColor != undefined ? curColor.background : null
          )
            ? function (context: any) {
              const chart = context.chart;
              const { ctx, chartArea } = chart;

              if (!chartArea) {
                // This case happens on initial chart load
                return;
              }
              return gradientMethod(ctx, chartArea, curColor.background, i);
            }
            : curColor != undefined ? curColor.border : null,
          borderColor: curColor != undefined ? curColor.border : null,
          fill: curColor != undefined ? !!curColor.background : null,
          tension: 0.4,
          borderWidth: 3,
        };

        chartData.push(tempData);
      }

      return chartData;
    },
  },
  // chart graphics don't update when most of it's properties change so it is required to
  // force rerendering whenever these changes happen. For this case, it is required to do so
  // when there are data changes
  watch: {
    "chartInfo.data"(newValue) {
      this.chartRef.data.datasets = this.formatChartData();
      this.chartRef.options = this.getLineChartOption({
        chartTitle: this.chartTitle,
        chartId: this.chartID,

        min: this.rangeValues.min,
        max: this.rangeValues.max,

        unitType: this.timeInfo.unitType,
        displayFormats: this.timeInfo.displayFormats,
        tooltipFormat: this.timeInfo.tooltipFormat,
        tooltipUnit: this.tooltipInfo,
        hasLegend: this.displayLegend,
      });
      this.chartRef.update();
    },
  },
});
