


































































































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import FormModal from "@/components/general/form-modal.vue";
import ParticipantSupportSuccessModal from "@/components/participant/participant-support-success-modal.vue";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";
import PercentageBar from "./percentage-bar.vue";
import TaskCard from "./task-card.vue";
import SolidButton from "@/components/general/solid-button.vue";
import ProjectInfoModal from "./project-info-modal.vue";

interface NewTaskInfo {
  id?: number;
  name: string;
  description: string;
  date: string;
  credits: number;
  time: string;
  contract?: any;
  state: string;
  longDescription?: string;
  activities: string[];
}

export default mixins(GeneralMixin).extend({
  name: "NewActivityCard",
  components: {
    ParticipantSupportSuccessModal,
    ParticipantNoContentInfoCard,
    FormModal,
    PercentageBar,
    TaskCard,
    SolidButton,
    ProjectInfoModal,
  },

  props: {
    task: {
      required: false,
      type: Object as () => NewTaskInfo,
      default: {},
    },
  },

  computed: {
    contractSumbitedModal() {
      return this.$store.getters["modalState"]("new-contract-" + this.task.id);
    },
  },

  data() {
    return {
      successModal: "new-contract-",
      contractSuccessText: `<h5 class="header-4 mb-5">Contrato Recebido!</h5>
        <p class="text-center"> Recebemos o seu contrato e agora vamos proceder à verificação
        do mesmo assim que possível. Caso o seu contrato esteja em concordância com os requisitos,
        o seu novo projeto e respetivas tarefas ficarão imediatamente disponíveis.
        </p>`,
    };
  },
  //   created() {
  //   },
  methods: {
    uploadContract() {
      this.openModal(`contract-up-${this.task.id}`);
    },
    getContractFile(link: string) {
      // Download file from URL
      if (link) {
        let tmpEl = document.createElement("a");
        tmpEl.setAttribute("href", link);
        tmpEl.click();
      }
    },
  },

  watch: {
    contractSumbitedModal(newVal) {
      if (newVal) {
        this.$emit("contractSubmited");
      }
    },
  },
});
