import { Module, GetterTree, MutationTree, ActionTree, Mutation } from "vuex";
import store, { RootState, Nullable } from "../../store/index";
import customAxios from "@/axios-service";

export interface TaskInfo {
  id: number;
  title: string;
  short_description: string;
  credit: number;
  status: string;
  form_link: string;
  activity_project_id: number;
  isNewTask: boolean;
}

export interface GeneralTasks {
  tasks_pending: TaskInfo[];
  tasks_completed: TaskInfo[];
}

export interface ActiveProject {
  id: number;
  title: string;
  short_description: string;
  long_description: string;
  newProject: boolean | false;
  total_credit: number;
  duration: string;
  start_date: string;
  end_date: string;
  validation_state: string;
  contract_upload_form_link_id: string;
  contract_file_uploaded: string;
  contract_file_link: string;
  activity_tasks: GeneralTasks;
}

export interface VoucherInfo {
  id: number;
  name: string;
  value: string;
  exchange_point: number;
  stock_level: number;
  restrict_by_stock_level: boolean;
}

export interface TransactionInfo {
  id: number;
  date_added: string;
  exchanged_point: number;
  voucher_item: VoucherInfo;
  status: string;
}

export interface ProjectInfo {
  active_or_valid_project: ActiveProject;
  completed_projects: ActiveProject[]; // TODO this prolly will be different or like TaskInfo
  pending_or_invalid_or_canceled_projects: ActiveProject[]; // this name though lmao
}

export interface NotificationInfo {
  id: number;
  date_added: string;
  last_updated: string;
  // will always be 3 for this
  notification_class: number;
  // will always be 4 for this
  notification_type: number;
  // ???
  parameters?: {
    project_id?: number;
    project_title: string;
    project_total_credit: number;
  };
  is_acknowledged: boolean;
}

export interface ActivityInfo {
  general_tasks: Nullable<GeneralTasks>;
  active_project: Nullable<ActiveProject>;
  voucher_info: Nullable<VoucherInfo[]>;
  voucher_history: Nullable<TransactionInfo[]>;
  notification: Nullable<NotificationInfo[]>;
  activity_selector: number;
  size_activity: number;
  size_project: number;
};

export const state: ActivityInfo = {
  general_tasks: JSON.parse(sessionStorage.getItem("general-tasks") || "null"),
  active_project: JSON.parse(
    sessionStorage.getItem("active-project") || "null"
  ),
  voucher_history: JSON.parse(
    sessionStorage.getItem("voucher-history") || "null"
  ),
  voucher_info: JSON.parse(sessionStorage.getItem("voucher-info") || "null"),
  notification: JSON.parse(sessionStorage.getItem("notification") || "null"),
  activity_selector: -1,
  // for testing purposes
  size_activity: 0,
  size_project: 0,
};

const getters: GetterTree<ActivityInfo, RootState> = {
  generalTasks: (state) => {
    return state.general_tasks;
  },
  activeProject: (state) => {
    return state.active_project;
  },
  voucherHistory: (state) => {
    return state.voucher_history;
  },
  voucherInfo: (state) => {
    return state.voucher_info;
  },
  notification: (state) => {
    return state.notification;
  },
  //testing purposes
  activitySelector: (state) => {
    return state.activity_selector;
  },

  sizeGeneralTasks: (state) => {
    return state.size_activity;
  },

  sizeProject: (state) => {
    return state.size_project;
  },


};

const mutations: MutationTree<ActivityInfo> = {
  resetActivityData: (state) => {
    state.general_tasks = null;
    sessionStorage.setItem(
      "general-tasks",
      JSON.stringify(state.general_tasks)
    );
    state.active_project = null;
    sessionStorage.setItem(
      "active-project",
      JSON.stringify(state.active_project)
    );
    state.voucher_history = null;
    sessionStorage.setItem(
      "voucher-history",
      JSON.stringify(state.voucher_history)
    );
    state.voucher_info = null;
    sessionStorage.setItem("voucher-info", JSON.stringify(state.voucher_info));
    state.notification = null;
    sessionStorage.setItem("notification", JSON.stringify(state.notification));
  },
  //testing purposes
  updateRemoveTask: (state, value: any) => {
    if (state.size_activity > 0) {
      state.size_activity = state.size_activity - value
    }

  },
  updateRemoveProject: (state, value: any) => {
    if (state.size_project > 0) {
      state.size_project = state.size_project - value
    }

  },
  updateProjectSize: (state, value: any) => {
    state.size_project = value
  },
  updateTaskSize: (state, value: any) => {
    state.size_activity = value;
  },
  //testing purposes
  updateGeneralTasks: (state, value: any) => {
    state.general_tasks = value;
    sessionStorage.setItem(
      "general-tasks",
      JSON.stringify(state.general_tasks)
    );
  },

  updateActiveProject: (state, value: any) => {
    state.active_project = value;
    sessionStorage.setItem(
      "active-project",
      JSON.stringify(state.active_project)
    );
  },
  updateVoucherHistory: (state, value: any) => {
    state.voucher_history = value;
    sessionStorage.setItem(
      "voucher-history",
      JSON.stringify(state.voucher_history)
    );
  },

  updateVoucherInfo: (state, value: any) => {
    state.voucher_info = value;
    sessionStorage.setItem("voucher-info", JSON.stringify(state.voucher_info));
  },
  updateNotification: (state, value: any) => {
    state.notification = value;
    sessionStorage.setItem("notification", JSON.stringify(state.notification));
  },
  updateActivitySelector: (state, value: any) => {
    state.activity_selector = value;
  },

};

const actions: ActionTree<ActivityInfo, RootState> = {
  // async getGeneralTasks({ commit }) {
  //   return customAxios
  //     .get(`/api/general_task`)
  //     .then((response) => {
  //       return commit("updateGeneralTasks", response.data);
  //     })
  //     .catch((e) => {
  //       return store.dispatch("formResponseError", e);
  //     });
  // },
  async getActiveProject({ commit }) {
    let proj = 0
    return customAxios
      .get(`/api/project`)
      .then((response) => {
        response.data.completed_projects = [{
          id: 1,
          title: "Test Project1 Completed",
          short_description: "This is the short description",
          long_description: "This is the long description",
          total_credit: 5,
          duration: 10,
          start_date: "2022-2-25",
          end_date: "2022-12-2",
          validation_state: true,
          contract_upload_form_link_id: 2,
          contract_file_link: "",
          activity_tasks: {
            tasks_pending: [
              {
                id: 0,
                title: "string",
                short_description: "string",
                credit: 2,
                status: true,
                form_link: "string",
                activity_project_id: "string"
              }
            ],
            tasks_completed: [
              {
                id: 0,
                title: "string",
                short_description: "string",
                credit: 8,
                status: true,
                form_link: "string",
                activity_project_id: "string"
              }
            ],
          }
        },
        {
          id: 2,
          title: "Test Project2 completed project",
          short_description: "This is the short description",
          long_description: "This is the long description",
          total_credit: 5,
          duration: 10,
          start_date: "2022-2-25",
          end_date: "2022-12-2",
          validation_state: true,
          contract_upload_form_link_id: 2,
          contract_file_link: "",
          activity_tasks: {
            tasks_pending: [
              {
                id: 0,
                title: "string",
                short_description: "string",
                credit: 2,
                status: true,
                form_link: "string",
                activity_project_id: "string"
              }
            ],
            tasks_completed: [
              {
                id: 0,
                title: "string",
                short_description: "string",
                credit: 8,
                status: true,
                form_link: "string",
                activity_project_id: "string"
              }
            ],
          }
        },
        {
          id: 3,
          title: "Test Project3 completed project",
          short_description: "This is the short description",
          long_description: "This is the long description",
          total_credit: 5,
          duration: 10,
          start_date: "2022-2-25",
          end_date: "2022-12-2",
          validation_state: true,
          contract_upload_form_link_id: 2,
          contract_file_link: "",
          activity_tasks: {
            tasks_pending: [
              {
                id: 0,
                title: "string",
                short_description: "string",
                credit: 2,
                status: true,
                form_link: "string",
                activity_project_id: "string"
              }
            ],
            tasks_completed: [
              {
                id: 0,
                title: "string",
                short_description: "string",
                credit: 8,
                status: true,
                form_link: "string",
                activity_project_id: "string"
              }
            ],
          }
        },
        {
          id: 4,
          title: "Test Project4 completed project",
          short_description: "This is the short description",
          long_description: "This is the long description",
          total_credit: 5,
          duration: 10,
          start_date: "2022-2-25",
          end_date: "2022-12-2",
          validation_state: true,
          contract_upload_form_link_id: 2,
          contract_file_link: "",
          activity_tasks: {
            tasks_pending: [
              {
                id: 0,
                title: "string",
                short_description: "string",
                credit: 2,
                status: true,
                form_link: "string",
                activity_project_id: "string"
              }
            ],
            tasks_completed: [
              {
                id: 0,
                title: "string",
                short_description: "string",
                credit: 8,
                status: true,
                form_link: "string",
                activity_project_id: "string"
              }
            ],
          }
        }]
        response.data.pending_or_invalid_or_canceled_projects = [
          {
            id: 1,
            title: "Test Project1 invalid or canceled",
            newProject: true,
            short_description: "This is the short description",
            long_description: "This is the long description",
            total_credit: 5,
            duration: 10,
            start_date: "2022-2-25",
            end_date: "2022-12-2",
            validation_state: true,
            contract_upload_form_link_id: 2,
            contract_file_link: "",
            activity_tasks: {
              tasks_pending: [
                {
                  id: 0,
                  title: "string",
                  short_description: "string",
                  credit: 2,
                  status: true,
                  form_link: "string",
                  activity_project_id: "string"
                }
              ],
              tasks_completed: [
                {
                  id: 0,
                  title: "string",
                  short_description: "string",
                  credit: 8,
                  status: true,
                  form_link: "string",
                  activity_project_id: "string"
                }
              ],
            }
          },
          {
            id: 2,
            title: "Test Project2 invalid or canceled",
            short_description: "This is the short description",
            newProject: false,

            long_description: "This is the long description",
            total_credit: 5,
            duration: 10,
            start_date: "2022-2-25",
            end_date: "2022-12-2",
            validation_state: true,
            contract_upload_form_link_id: 2,
            contract_file_link: "",
            activity_tasks: {
              tasks_pending: [
                {
                  id: 0,
                  title: "string",
                  short_description: "string",
                  credit: 2,
                  status: true,
                  form_link: "string",
                  activity_project_id: "string"
                }
              ],
              tasks_completed: [
                {
                  id: 0,
                  title: "string",
                  short_description: "string",
                  credit: 8,
                  status: true,
                  form_link: "string",
                  activity_project_id: "string"
                }
              ],
            }
          },
          {
            id: 3,
            title: "Test Project3 invalid or canceled",
            short_description: "This is the short description",
            long_description: "This is the long description",
            newProject: false,
            total_credit: 5,
            duration: 10,
            start_date: "2022-2-25",
            end_date: "2022-12-2",
            validation_state: true,
            contract_upload_form_link_id: 2,
            contract_file_link: "",
            activity_tasks: {
              tasks_pending: [
                {
                  id: 0,
                  title: "string",
                  short_description: "string",
                  credit: 2,
                  status: true,
                  form_link: "string",
                  activity_project_id: "string"
                }
              ],
              tasks_completed: [
                {
                  id: 0,
                  title: "string",
                  short_description: "string",
                  credit: 8,
                  status: true,
                  form_link: "string",
                  activity_project_id: "string"
                }
              ],
            }
          }
        ]
        response.data.pending_or_invalid_or_canceled_projects.forEach(function (item: any) {
          if (item.newProject == true && item.id) {
            proj++;
            return commit("updateProjectSize", proj);

          }
        });

        response.data.active_or_valid_project = {
          id: 1,
          title: "Test Project",
          short_description: "This is the short description",
          long_description: "This is the long description",
          total_credit: 5,
          duration: 10,
          start_date: "2022-03-25",
          end_date: "2022-12-2",
          validation_state: true,
          contract_upload_form_link_id: 2,
          contract_file_link: "",
          activity_tasks: {
            tasks_pending: [
              {
                id: 0,
                title: "string",
                short_description: "string",
                credit: 2,
                status: true,
                form_link: "string",
                activity_project_id: "string"
              }
            ],
            tasks_completed: [
              {
                id: 0,
                title: "string",
                short_description: "string",
                credit: 8,
                status: true,
                form_link: "string",
                activity_project_id: "string"
              }
            ],
          }
        }

        return commit("updateActiveProject", response.data);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async getVoucherHistory({ commit }) {
    return customAxios
      .get(`/api/transaction_history`)
      .then((response) => {
        return commit("updateVoucherHistory", response.data);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async getVoucherList({ commit }) {
    return customAxios
      .get(`/api/voucher_item`)
      .then((response) => {
        return commit("updateVoucherInfo", response.data);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async requestVoucherExchange({ commit }, params) {
    return customAxios
      .post(`/api/transaction_history`, params)
      .then((response) => {
        if (response.data && response.data.transaction_history) {
          commit("updateVoucherHistory", response.data.transaction_history);
        }
        return response?.data?.credit_balance;
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async getNotification({ commit }) {
    return customAxios
      .get(`/api/notification/3/4`)
      .then((response) => {
        return commit("updateNotification", response.data);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async updateNotification({ commit }, id) {
    return customAxios
      .patch(`/api/notification_acknowledge/${id}`, { is_acknowledged: true })
      .then((response) => {
        if (!response.data?.success) {
          return store.dispatch(
            "formResponseError",
            "Error ackowledging end project"
          );
        }
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
};

const namespaced = true;

const activityData: Module<ActivityInfo, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default activityData;
