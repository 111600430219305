























































































































































































































































import mixins from "vue-typed-mixins";
import { ActivityMixin } from "@/mixins/activity-mixin";
import ParticipantNoContentInfoCard from "../participant-no-content-info-card.vue";
import ActivityHistoryInfo from "@/components/participant/activities/activity-history-info.vue";
import ActivityHistoryProject from "@/components/participant/activities/activity-history-project.vue";
import ActivityHistoryGeneral from "@/components/participant/activities/activity-history-general.vue";
import TaskCard from "./task-card.vue";
export default mixins(ActivityMixin).extend({
  name: "ActivityHistory",
  components: {
    ActivityHistoryInfo,
    ActivityHistoryProject,
    ParticipantNoContentInfoCard,
    TaskCard,
    ActivityHistoryGeneral,
  },
  props: [
    "roughEdges",
    "secondaryStyle",
    "page_size",
    "cardTitle",
    "check_btn",
    "changeProj",
    "changeTask",
    "changeExp",
  ],
  mounted() {
    this.checkButton();
  },

  computed: {
    pageHistory(): any {
      return this.sliceHistory();
    },

    select(): any {
      return this.selected;
    },
  },
  methods: {
    reset(): any {
      this.counter = 0;
      this.start = 0;
      this.selected = 0;
      this.checkButton();
    },
  },
});
