







































import Vue from "vue";

export default Vue.extend({
  name: "SolidButton",
  props: [
    "m_width",
    "caption",
    "buttonSize",
    "vImg",
    "iconPosition",
    "buttonClass",
    "buttonTextClass",
    "name",
    "buttonTextOveride",
    "outlined",
    "buttonH",
    "color",
    "font",
    "fromHistory",
  ],
  computed: {
    loading: function (): any {
      return this.$store.getters["buttonState"](this.name);
    },
    generalClass: function (): string {
      return (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
        ? "button-full"
        : "button-3") + this.vImg
        ? "px-3"
        : "";
    },
  },
  methods: {
    getButtonColor() {
      switch (this.caption) {
        case "Completada":
          // eslint-disable-next-line no-undef
          return "rgba(20, 208, 85, 0.13)";
        case "Em validação":
          return "rgba(230,162,70, 0.13)";
        default:
          return "#41c5d3";
      }
    },
    clickFunction() {
      this.$emit("buttonClick", this.name);
    },
  },
});
