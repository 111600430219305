import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";

const ProfileMixin = mixins(GeneralMixin).extend({
    computed: {
        profile_info: {
            get(): any {
                return this.$store.getters["participantGeneral/profileInfo"];
            },
        },
        dashboardData: {
            get(): any {
                return this.$store.getters["dashboardData/dashboardInfo"];
            },
        },
    },
    methods: {
        async runParentAction(): Promise<any> {
            this.$store.commit("formRecord/updateActiveForm", {
                already_submitted: false,
            });
            await this.$store.dispatch("participantGeneral/getProfileInfo");
            return true;
        },
    },
})

export { ProfileMixin }
export default {}
