var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.currentPage.title &&
      _vm.currentPage.title != '' &&
      !_vm.currentPage.show_success
    )?_c('v-row',{staticClass:"page-title-row",attrs:{"align":"center"}},[(_vm.showPageCounter)?_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"step-number step-number-active rounded-circle"},[_vm._v(" "+_vm._s(_vm.pageCounter + 1)+" ")])]):_vm._e(),_c('v-col',{staticClass:"step-title step-title-active text-left py-0"},[_vm._v(" "+_vm._s(_vm.currentPage.title)+" ")])],1):_vm._e(),(
      _vm.currentPage.show_success &&
      _vm.currentPage.success_text &&
      _vm.currentPage.success_text != ''
    )?_c('v-row',{attrs:{"align":"center"}},[(_vm.currentPage.show_success)?_c('v-col',{attrs:{"cols":"auto"}},[_c('img',{staticClass:"success-img",attrs:{"src":require("@/assets/general/success.svg")}})]):_vm._e(),(_vm.currentPage.success_text && _vm.currentPage.success_text != '')?_c('v-col',{staticClass:"success-text text-left",domProps:{"innerHTML":_vm._s(_vm.currentPage.success_text)}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }