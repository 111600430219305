

























































import Vue from "vue";
import HomeactivityCard from "./activities/homeactivity-card.vue";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";

export default Vue.extend({
  name: "ActivityDasboard",
  components: {
    HomeactivityCard,
    ParticipantNoContentInfoCard,
  },

  created() {
    this.retrieveActivityData();
  },

  methods: {
    async retrieveActivityData() {
      this.getGeneralActivities();
    },
    async getGeneralActivities() {
      await this.$store.dispatch("activityData2/getGeneralTasksNew");
    },
  },

  computed: {
    activitiesProjects: {
      get(): any {
        return this.$store.getters["activityData2/generalTasksNew"];
      },
    },
  },

  props: [
    "icon",
    "size",
    "cardTitle",
    "messageTitle",
    "messageText",
    "roughEdges",
    "dashboard",
  ],
});
