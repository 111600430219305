












































import mixins from "vue-typed-mixins";
import { FormMixin } from "@/mixins/form-mixin";
import toolTip from "../general/tool-tip.vue";

export default mixins(FormMixin).extend({
  components: { toolTip },
  name: "FormRadio",
  props: {
    isReadOnly: { type: Boolean, default: false },
  },
  created() {
    this.parseFieldOptions();
  },
  computed: {
    rowToggle(): boolean {
      return this.formItem.display_stack
        ? this.formItem.display_stack == "Vertical"
          ? false
          : true
        : this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
        ? false
        : true;
    },
  },
});
