
























import mixins from "vue-typed-mixins";
import { ActivityMixin } from "@/mixins/activity-mixin";
import ActivityHistoryInfo from "@/components/participant/activities/activity-history-info.vue";
import TaskCard from "./task-card.vue";
export default mixins(ActivityMixin).extend({
  name: "ActivityHistoryProject",
  components: {
    ActivityHistoryInfo,
    TaskCard,
  },
});
