



















































































































































































































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import solidButton from "@/components/general/solid-button.vue";
import FormModal from "@/components/general/form-modal.vue";
import ParticipantSupportSuccessModal from "../participant-support-success-modal.vue";
import config from "@/config";

interface SubTaskInfo {
  id: number;
  activity_form: {
    id: number;
    link_id: string;
  };
  is_new: boolean;
  title: string;
  short_description: string;
  long_description: string;
  term_and_condition_description: string;
  credit: number;
  duration: number;
  tasks: [];
  submission_end_date: string;
  conclusion_date: string;
  event_launch_start_date: string;
  event_launch_end_date: string;
  display_order: number;
  is_general_project: boolean;
  commencement_date: string;
  state: string;
}

export default mixins(GeneralMixin).extend({
  components: { solidButton, FormModal, ParticipantSupportSuccessModal },
  name: "TaskCard",
  data() {
    return {
      test: true,
      taskCompleted: "modal-completed-task-",
      successModal: "c-task-",
      modalName: "",
    };
  },
  props: {
    validation: {
      required: false,
    },
    secondaryStyle: {
      required: false,
    },
    fromHistory: {
      required: false,
      type: Boolean,
      default: false,
    },
    isGeneral: {
      required: false,
      type: Boolean,
      default: false,
    },
    notReady: {
      required: false,
      type: Boolean,
      default: false,
    },
    subTaskBtn: Function,
    info: {
      required: false,
      type: Object as () => any,
      default: {},
    },
  },
  computed: {
    taskCompletedContent: {
      get(): any {
        return this.isGeneral
          ? config.activityTaskCompleted.general
          : config.activityTaskCompleted.specific;
      },
    },
    size_activity: {
      get(): any {
        return this.$store.getters["activityData2/generalUpdate"](1);
      },
    },
  },
  methods: {
    getButtonState(): string {
      switch (this.validation) {
        case "Completed":
          return "Completada";
        case "Valid":
          return "Completada";
        case "For Review":
          return "Em Validação";
        case "Normal":
          return "Completar Tarefa";
        default:
          return "Completar Tarefa";
      }
    },
    emitEvent() {
      this.$emit(
        "subTaskBtn",
        this.isGeneral == true ? this.info.tasks[0].id : this.info.id
      );

      this.$store.commit("activityData2/updateRemoveTask", 1);
      this.$store.dispatch(
        "activityData2/setSeenGeneralTask",
        this.isGeneral == true ? this.info.tasks[0].id : this.info.id
      );
      this.modalName = `${this.successModal}${this.info.id}`;
      this.openModal(this.modalName);
    },
  },
});
