















































import mixins from "vue-typed-mixins";
import config from "@/config";
import chartConfig from "@/config-chart";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";
import FormDateSelector from "@/components/form/form-date-selector.vue";
import ChartCard from "@/components/charts/chart-card.vue";
import { ChartDataStructure, GeneralMixin } from "@/mixins/general-mixin";
import { DateTime } from "luxon";
export default mixins(GeneralMixin).extend({
  name: "ProducaoSolar",
  components: {
    ParticipantNoContentInfoCard,
    FormDateSelector,
    ChartCard,
  },
  computed: {
    consumptionData: {
      get(): any {
        return JSON.parse(
          JSON.stringify(
            this.$store.getters["consumptionData/productionInfo"]("consumption")
          )
        );
      },
    },
    productionData: {
      get(): any {
        return JSON.parse(
          JSON.stringify(
            this.$store.getters["consumptionData/productionInfo"]("production")
          )
        );
      },
    },
    injectionData: {
      get(): any {
        return JSON.parse(
          JSON.stringify(
            this.$store.getters["consumptionData/productionInfo"]("injection")
          )
        );
      },
    },
    hasProductionMeter: {
      get(): boolean {
        return this.$store.getters["consumptionData/productionInfo"](
          "has_production_meter"
        );
      },
    },
  },
  data() {
    return {
      availableTimeSlots: [] as string[],
      availableTimeSlots1: [] as string[],
      activeSelection: true,
      currentSelectedYear: 0,
      isMonthActive: true,
      activeMonths: [] as number[],
      consumptionTimes1: [
        {
          title: "Período de Tempo",
          dates: [new Date()] as Date[],
        },
      ],
      consumptionProductionDailyChart: {} as ChartDataStructure,
      consumptionProductionWeeklyChart1: {} as ChartDataStructure,
      consumptionProductionWeeklyChart2: {} as ChartDataStructure,
      consumptionProductionAnnualChart1: {} as ChartDataStructure,
      consumptionProductionAnnualChart2: {} as ChartDataStructure,
      consumptionTimes2: [
        {
          title: "Período de Tempo",
          subtitle: "Inicio",
          dates: [new Date()],
        },
        {
          subtitle: "Fim",
          dates: [new Date()],
        },
      ],
      consumptionTimes3: [
        {
          title: "Período de Tempo",
          subtitle: "Inicio",
          dates: [new Date()],
        },
        {
          subtitle: "Fim",
          dates: [new Date()],
        },
      ],
      isWeekdayActive: true,
      currentSelectedWeek: "",
      activeDays: [] as number[],
    };
  },
  created() {
    this.availableTimeSlots = JSON.parse(
      JSON.stringify(config.dayHourIntervals)
    );
    this.availableTimeSlots1 = JSON.parse(JSON.stringify(config.daysWeekPT));
    this.initializeChartData();
    this.InitialDataProd();
  },
  methods: {
    async InitialDataProd() {
      this.$store.commit("updateState", { loading_overlay: true });
      await this.$store.dispatch("consumptionData/getConsumptionInfo", {
        uri: "production_energy",
      });
      if (this.hasProductionMeter) {
        if (!this.currentSelectedWeek) {
          this.retrieveLineDayData();
          this.retrieveWeeklyData();
          this.retrieveAnnualData();
          this.retrieveTotalWeeklyData();
          this.retrieveTotalAnnualData();
          this.retrieveData();
          this.retrieveDataAnnual();
        }
      }
      this.$store.commit("updateState", { loading_overlay: false });
    },
    async updateSelectedYear(year: number) {
      if (!year || year === this.currentSelectedYear) {
        return;
      }
      this.$store.commit("updateState", { loading_overlay: true });

      this.currentSelectedYear = year;
      this.isMonthActive = false;
      await this.$store.dispatch("consumptionData/getConsumptionInfo", {
        uri: "production_energy",
        year: year,
      });
      this.activeMonths = [];
      this.consumptionProductionAnnualChart1 = JSON.parse(
        JSON.stringify(
          chartConfig.AnnualProductionConsumption(this.availableTimeSlots)
        )
      );
      this.retrieveAnnualData();
      this.retrieveTotalAnnualData();
      this.retrieveDataAnnual();
      this.isMonthActive = true;
      this.$store.commit("updateState", { loading_overlay: false });
    },
    retrieveDataAnnual() {
      const productionDataWeek =
        this.productionData.week_before.mains.grouped_profile;
      const productionDataAnnual =
        this.productionData.annual.mains.grouped_profile;
      if (productionDataWeek || productionDataAnnual) {
        this.consumptionProductionAnnualChart2.chartInfo.data[0].forEach(
          (item: any) => {
            if (item.y === null) {
              item.y = 0;
            }
          }
        );
        this.consumptionProductionAnnualChart2.chartInfo.data[1].forEach(
          (item: any) => {
            if (item.y === null) {
              item.y = 0;
            }
          }
        );
      }
    },
    retrieveData() {
      if (this.consumptionProductionWeeklyChart2.chartInfo.data) {
        if (this.consumptionProductionWeeklyChart2.chartInfo.data[0]) {
          this.consumptionProductionWeeklyChart2.chartInfo.data[0].forEach(
            (item: any) => {
              if (item.y === null) {
                item.y = 0;
              }
            }
          );
        }
        if (this.consumptionProductionWeeklyChart2.chartInfo.data[1]) {
          this.consumptionProductionWeeklyChart2.chartInfo.data[1].forEach(
            (item: any) => {
              if (item.y === null) {
                item.y = 0;
              }
            }
          );
        }
      }
    },
    updateActiveWeekDays(date: number[]) {
      const chartInfo = this.consumptionProductionWeeklyChart2.chartInfo;
      const data = this.consumptionData.week_before.mains;
      if (chartInfo && chartInfo.labels) {
        date.forEach((day) => {
          if (!this.activeDays.includes(day) && chartInfo.labels) {
            if (!data.profile[day]) {
              this.$store.dispatch("toggleSnackbar", {
                show: true,
                message: "Seleção sem dados disponíveis",
                color: "blue",
              });
              this.activeDays.push(day);
              return;
            }
            chartInfo.labels.push(config.daysWeekPT[day]);
            chartInfo.colors.push({ border: config.colorsDaysWeekPT[day] });
            chartInfo.data.push(data.grouped_profile[day]);
            this.activeDays.push(day);
          }
        });
        this.activeDays.forEach((day, index) => {
          if (!date.includes(day) && chartInfo.labels) {
            let pos = chartInfo.labels.indexOf(config.daysWeekPT[day]);
            if (pos !== -1) {
              chartInfo.labels.splice(pos, 1);
              chartInfo.colors.splice(pos, 1);
              chartInfo.data.splice(pos, 1);
            }
            this.activeDays.splice(index, 1);
          }
        });
      }
    },
    async updateSelectedWeek(week: string) {
      this.$store.commit("updateState", { loading_overlay: true });
      if (!week || week === this.currentSelectedWeek) {
        return;
      }
      this.currentSelectedWeek = week;
      this.isWeekdayActive = false;
      await this.$store.dispatch("consumptionData/getConsumptionInfo", {
        uri: "production_energy",
        start_date: week,
      });
      this.activeDays = [];
      this.consumptionProductionWeeklyChart1 = JSON.parse(
        JSON.stringify(
          chartConfig.weeklyProductionConsumption(this.availableTimeSlots)
        )
      );
      this.consumptionProductionWeeklyChart2 = JSON.parse(
        JSON.stringify(chartConfig.weeklyTotalProductionConsumption())
      );
      this.retrieveWeeklyData();
      this.retrieveTotalWeeklyData();
      this.retrieveData();
      this.isWeekdayActive = true;
      this.$store.commit("updateState", { loading_overlay: false });
    },
    initializeChartData() {
      this.consumptionProductionDailyChart = JSON.parse(
        JSON.stringify(chartConfig.dailyProductionConsumption())
      );
      this.consumptionProductionWeeklyChart1 = JSON.parse(
        JSON.stringify(
          chartConfig.weeklyProductionConsumption(this.availableTimeSlots)
        )
      );
      this.consumptionProductionWeeklyChart2 = JSON.parse(
        JSON.stringify(chartConfig.weeklyTotalProductionConsumption())
      );
      this.consumptionProductionAnnualChart1 = JSON.parse(
        JSON.stringify(
          chartConfig.AnnualProductionConsumption(this.availableTimeSlots)
        )
      );
      this.consumptionProductionAnnualChart2 = JSON.parse(
        JSON.stringify(chartConfig.annualTotalProductionConsumption())
      );
    },
    retrieveAnnualData() {
      const dataConsumption = this.consumptionData
        ? this.consumptionData.annual.mains.profile[0]
        : null;
      const dataProduction = this.productionData.annual
        ? this.productionData.annual.mains.profile[0]
        : null;
      const dataInjection = this.injectionData
        ? this.injectionData.annual.mains.profile[0]
        : null;
      if (dataProduction) {
        this.consumptionTimes3[0].dates = [
          new Date(this.productionData.annual.mains.start),
        ];
        this.consumptionTimes3[1].dates = [
          new Date(this.productionData.annual.mains.end),
        ];
        const startMilliseconds = DateTime.now().startOf("day").toMillis();
        this.consumptionProductionAnnualChart1.chartInfo.rangeValues.min =
          startMilliseconds;
        // max is the 24h (in milliseconds) range since the starting time
        this.consumptionProductionAnnualChart1.chartInfo.rangeValues.max =
          startMilliseconds + 82800000;
        this.consumptionProductionAnnualChart1.chartInfo.data = [
          dataProduction,
          dataConsumption,
        ];
        this.consumptionProductionAnnualChart1.chartInfo.displayLegend =
          !!this.productionData.annual.mains.total;
      }
    },
    retrieveTotalAnnualData(): void {
      const dataConsumption = this.consumptionData
        ? this.consumptionData.annual.mains.grouped_profile
        : null;
      const dataProduction = this.productionData
        ? this.productionData.annual.mains.grouped_profile
        : null;
      const dataInjection = this.injectionData
        ? this.injectionData.annual.mains.grouped_profile
        : null;
      this.consumptionProductionAnnualChart2.chartInfo.labels = [];
      if (
        this.productionData.annual.mains.start &&
        this.productionData.annual.mains.end
      ) {
        this.consumptionProductionAnnualChart2.chartInfo.rangeValues.min =
          new Date(this.productionData.annual.mains.start).getTime();
        this.consumptionProductionAnnualChart2.chartInfo.rangeValues.max =
          new Date(this.productionData.annual.mains.end).getTime();
      }
      // it's literaly defined at the top ...
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.consumptionProductionAnnualChart2.chartInfo.labels = [
        "Produção",
        "Injeção",
      ];
      this.consumptionProductionAnnualChart2.chartInfo.colors[0].border =
        "#ebd048";
      this.consumptionProductionAnnualChart2.chartInfo.colors[1].border =
        "#d26c4d";
      // this.consumptionProductionAnnualChart2.chartInfo.colors[2].border =
      //   "#3bbbc8";
      this.consumptionProductionAnnualChart2.chartInfo.data = [
        dataProduction,
        dataInjection,

      ];
      this.consumptionProductionAnnualChart2.chartInfo.displayLegend =
        !!this.productionData.annual.mains.total;
      this.consumptionProductionAnnualChart2.isVisible;
    },
    retrieveWeeklyData() {
      const dataConsumption = this.consumptionData
        ? this.consumptionData.week_before.mains.profile[-1]
        : null;
      const dataProduction = this.productionData.week_before
        ? this.productionData.week_before.mains.profile[-1]
        : null;
      // const dataInjection = this.injectionData
      //   ? this.injectionData.week_before.mains.profile[-1]
      //   : null;
      this.consumptionTimes2[0].dates = [
        new Date(this.productionData.week_before.mains.start),
      ];
      this.consumptionTimes2[1].dates = [
        new Date(this.productionData.week_before.mains.end),
      ];
      const startMilliseconds = DateTime.now().startOf("day").toMillis();
      this.consumptionProductionWeeklyChart1.chartInfo.rangeValues.min =
        startMilliseconds;
      // max is the 24h (in milliseconds) range since the starting time
      this.consumptionProductionWeeklyChart1.chartInfo.rangeValues.max =
        startMilliseconds + 82800000;
      this.consumptionProductionWeeklyChart1.chartInfo.data = [
        dataProduction,
        dataConsumption,
      ];
      this.consumptionProductionWeeklyChart1.chartInfo.displayLegend =
        !!this.productionData.week_before.mains.total;
    },
    retrieveTotalWeeklyData(): void {
      const dataConsumption = this.consumptionData
        ? this.consumptionData.week_before.mains.grouped_profile
        : null;
      const dataProduction = this.productionData
        ? this.productionData.week_before.mains.grouped_profile
        : null;
      const dataInjection = this.injectionData
        ? this.injectionData.week_before.mains.grouped_profile
        : null;
      this.consumptionProductionWeeklyChart2.chartInfo.labels = [];
      if (
        this.productionData.week_before.mains.start &&
        this.productionData.week_before.mains.end
      ) {
        this.consumptionProductionWeeklyChart2.chartInfo.rangeValues.min =
          new Date(this.productionData.week_before.mains.start).getTime();
        this.consumptionProductionWeeklyChart2.chartInfo.rangeValues.max =
          new Date(this.productionData.week_before.mains.end).getTime();
      }
      // it's literaly defined at the top ...
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.consumptionProductionWeeklyChart2.chartInfo.labels = [
        "Produção",
        "Consumo",
        // "Injeção na Rede",
      ];
      this.consumptionProductionWeeklyChart2.chartInfo.colors[0].border =
        "#ebd048";
      this.consumptionProductionWeeklyChart2.chartInfo.colors[1].border =
        "#3bbbc8";
      // this.consumptionProductionWeeklyChart2.chartInfo.colors[2].border =
      //   "#3bbbc8";
      this.consumptionProductionWeeklyChart2.chartInfo.data = [
        dataProduction,
        dataConsumption,
      ];
      this.consumptionProductionWeeklyChart2.chartInfo.displayLegend =
        !!this.productionData.week_before.mains.total;
      this.consumptionProductionWeeklyChart2.isVisible;
    },
    retrieveLineDayData() {
      const dataConsumption = this.consumptionData
        ? this.consumptionData.day_before.energy_consumption_24_hours.profile
        : null;
      const dataProduction = this.productionData.day_before
        ? this.productionData.day_before.energy_consumption_24_hours.profile
        : null;
      const dataInjection = this.injectionData
        ? this.injectionData.day_before.energy_consumption_24_hours.profile
        : 0;

      this.consumptionTimes1[0].dates = [
        new Date(this.productionData.day_before.date),
      ];
      const startMilliseconds = DateTime.now().startOf("day").toMillis();
      this.consumptionProductionDailyChart.chartInfo.rangeValues.min =
        startMilliseconds;
      // max is the 24h (in milliseconds) range since the starting time
      this.consumptionProductionDailyChart.chartInfo.rangeValues.max =
        startMilliseconds + 82800000;
      this.consumptionProductionDailyChart.chartInfo.data = [
        dataProduction,
        dataConsumption,
      ];
      this.consumptionProductionDailyChart.chartInfo.displayLegend =
        !!this.productionData.day_before.energy_consumption_24_hours.total;
      this.consumptionProductionDailyChart.isVisible;
    },
  },
});
