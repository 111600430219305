















































































































































































































































import mixins from "vue-typed-mixins";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";

import config from "@/config";
import formSelect from "../form/form-select.vue";
import SolidButton from "@/components/general/solid-button.vue";
import EquipmentsList from "./equipments-list.vue";
import { GeneralMixin } from "@/mixins/general-mixin";
import DateModal from "../date-modal.vue";

export default mixins(GeneralMixin).extend({
  components: {
    SolidButton,
    EquipmentsList,
    formSelect,
    DateModal,
    ParticipantNoContentInfoCard,
  },
  name: "ParticipantMonitorNewCard",
  props: {
    monitors: {
      type: Array as () => any,
      required: true,
    },
    monitorInfo: {
      type: Object as () => any,
      required: true,
    },
  },
  data() {
    return {
      item: {
        item_type: "select",
        title: "Visualização",
        show_title: true,
        show_title_as_float: true,
        val: "0",
        item_option: "0|Todos\r\n1|Energia\r\n2|Temperatura",
      },
      deviceInfo: config.monitorDeviceInfo,
    };
  },

  computed: {
    selectItem: {
      get(): any {
        return this.item;
      },
      set(val: any) {
        this.item.val = val.toString();
      },
    },
  },
  methods: {
    getIconUrl(iconPath: string) {
      return require(`@/assets/icons/monitor/${iconPath}`);
    },
    updateSelectItemVal(updatedItem: any): void {
      this.selectItem = updatedItem.itemVal;
    },
    //methods can be included in mixins
    stateInfo_2(total: number, online: number): string {
      if (online === 0) {
        return "offline";
      }
      if (total === online) {
        return "operational";
      }
      return "semi-operational";
    },
  },
});
