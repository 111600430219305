var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tab-button-area flex-wrap d-flex flex-wrap d-sm-none"},[_c('v-sheet',{staticClass:"sheet-selector",attrs:{"max-width":this.width - 65}},[_c('v-slide-group',{attrs:{"show-arrows":true}},_vm._l((_vm.tabInfo),function(tab,i){return _c('v-slide-item',{key:i},[_c('span',{staticClass:"tab-button strong-text d-flex align-center",class:{
              active: !_vm.curStateTmp
                ? _vm.curState === i
                : _vm.$route.params.curTab === i,
            },staticStyle:{"font-size":"18px"},on:{"click":function($event){return _vm.updateState(i)}}},[_vm._v(" "+_vm._s(tab.name)+" ")])])}),1)],1)],1),_c('div',{staticClass:"tab-button-area flex-wrap d-none d-sm-flex"},_vm._l((_vm.tabInfo),function(tab,i){return _c('div',{key:i,staticClass:"tab-button d-flex align-center",class:{
        active: !_vm.curStateTmp ? _vm.curState === i : _vm.$route.params.curTab === i,
      },on:{"click":function($event){return _vm.updateState(i)}}},[_c('img',{staticClass:"tab-icon mr-2",attrs:{"src":require(("@/assets/icons/" + (tab.icon)))}}),_c('span',{staticClass:"tab-name strong-text"},[_vm._v(" "+_vm._s(tab.name))]),(_vm.newProjectLength != 0 && i == 0 && _vm.activities == true)?_c('span',{staticClass:"ml-2 d-flex justify-center align-center strong-text",class:_vm.curState == 0 || _vm.curTab == 0
            ? 'notification on'
            : 'notification off'},[_vm._v(_vm._s(_vm.newProjectLength))]):_vm._e(),(_vm.newTaskLenght != 0 && i == 1 && _vm.activities == true)?_c('span',{staticClass:"ml-2 d-flex justify-center align-center strong-text",class:_vm.curState == 1 || _vm.curTab == 1
            ? 'notification on'
            : 'notification off'},[_vm._v(_vm._s(_vm.newTaskLenght))]):_vm._e(),(_vm.newHistoryTaskLength > 0 && i == 2)?_c('div',{staticClass:"ml-2 strong-text justify-center align-center",class:_vm.curState == 2 || _vm.curTab == 2
            ? 'notification on'
            : 'notification off'},[_vm._v(" "+_vm._s(_vm.newHistoryTaskLength)+" ")]):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }