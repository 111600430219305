


























































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import Snackbar from "@/components/general/snackbar.vue";
import FooterGeneralNoBg from "@/components/general/footer-general-no-bg.vue";

export default mixins(GeneralMixin).extend({
  name: "Recover",
  components: {
    Snackbar,
    FooterGeneralNoBg,
  },

  data() {
    return {
      email: "",
      loading: false,
      rules: {
        required: (value: any) => !!value || "Obrigatório!",
        email: (value: any) =>
          !this.isEmailValid(value) ? this.error_message.invalid_email : true,
      },
    };
  },
  methods: {
    async postRecoveryForm(): Promise<any> {
      await this.$store.dispatch("toggleSnackbar", null);
      if (this.form.validate()) {
        this.loading = true;
        const data = { email: this.email };
        const resp = await this.$store.dispatch("resetLoginPassword", data);
        this.loading = false;
        if (resp.success === true) {
          return this.$store.dispatch("toggleSnackbar", {
            show: true,
            message: this.success_message.password_reset,
            color: "green",
          });
        } else {
          return this.$store.dispatch("toggleSnackbar", {
            show: true,
            message: resp.message,
          });
        }
      } else {
        return this.$store.dispatch("toggleSnackbar", {
          show: true,
          message: this.error_message.form_has_error,
        });
      }
    },
  },
});
