













































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import FormBody from "@/components/form/form-body.vue";

export default mixins(GeneralMixin).extend({
  name: "FormModal",
  components: {
    FormBody,
  },
  props: [
    "modalName",
    "formClass",
    "secondaryButtons",
    "formLink",
    "hasParentAction",
    "excludeItemTypes",
    "excludeItemIds",
    "excludeSectionIds",
    "prevButtonTextOveride",
    "nextButtonTextOveride",
    "prevButtonActionOveride",
    "nextButtonActionOveride",
    "onCompletedActionOveride",
    "modalTitle",
  ],
  computed: {
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        this.closeModal(this.modalName);
      },
    },
  },
  methods: {
    routeParentAction(currentPage: any) {
      this.$emit("callParentAction", currentPage);
    },
  },
});
