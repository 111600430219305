


















































































import config from "@/config";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import Snackbar from "@/components/general/snackbar.vue";
import ParticipantMenuBar from "@/components/participant/participant-menu-bar.vue";
import ParticipantFooter from "@/components/participant/participant-footer.vue";
import ParticipantProfileBar from "@/components/participant/participant-profile-bar.vue";
import SuccessModal from "@/components/success-modal.vue";
import GasConsumptionModal from "@/components/general/gas-consumption-modal.vue";
//import ParticipantSupportSuccessModal from "@/components/participant/participant-support-success-modal.vue";

export default mixins(GeneralMixin).extend({
  name: "ParticipantView",
  components: {
    Snackbar,
    ParticipantMenuBar,
    ParticipantFooter,
    ParticipantProfileBar,
    SuccessModal,
    GasConsumptionModal,
    // ParticipantSupportSuccessModal,
  },
  computed: {
    getCurrentPageTitle(): string {
      const titles: Record<string, string> = {
        ParticipantDashboard: "Casa",
        ParticipantMonitores: "Equipamentos",
        ParticipantEstatiscas: "Consumos de Energia",
        ParticipantIntervalos: "Horários de Consumo",
        ProducaoSolar: "Produção Solar",
        ParticipantLeituras: "Qualidade Ambiente",
        ParticipantProfile: "O Meu Perfil",
        "Terms&Conditions": "Termos e Condições",
        SupportPage: "Suporte",
        ActivityPage: "Atividades",
      };

      if (this?.$route?.name === "ParticipantDashboard" && this?.isEv4eu) {
        return `${titles[this?.$route?.name]} - Projeto EV4EU`;
      }

      return this?.$route?.name
        ? titles[this?.$route?.name]
        : "Participant Section";
    },
    isCollapsed(): boolean {
      return this.$store.getters["sidemenuCollapse"];
    },
  },
  data() {
    return {
      newTaskTitle: config.newTaskTitle,
      newTaskText: config.newTaskText,
      reviewTitle: config.mustReviewTitle,
      reviewText: config.mustReviewText,
      textGas: config.textGasConsumption,
    };
  },
  methods: {
    // // default scroll behaviour on router does not work since we are using a custom one instead
    // scrollBehavior() {
    //   const scrollView = document.getElementById("main-participant-view");
    //   if (scrollView) {
    //     scrollView.scrollTop = 0;
    //   }
    // },
    collapseSidebarMenu(): void {
      this.$store.commit("toggleSidemenuCollapse", !this.isCollapsed);
    },
  },
  // watch: {
  //   $route() {
  //     // setTimeout(this.scrollBehavior, 0);
  //     this.scrollBehavior();
  //   },
  // },
});
