



























import { DateTime } from "luxon";
import Vue from "vue";

export default Vue.extend({
  name: "TimeCard",
  components: {},
  props: {
    check: {
      required: false,
      type: Boolean,
    },
    timeMili: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {};
  },
  methods: {
    getWeekDay(): string {
      return DateTime.fromMillis(this.timeMili).setLocale("pt").weekdayLong;
    },

    getShortWeekDay(): string {
      return DateTime.fromMillis(this.timeMili).setLocale("pt").weekdayShort;
    },
    getDay(): string {
      return new Date(this.timeMili).getDate().toLocaleString();
    },
    getShortDate(): string {
      return DateTime.fromMillis(this.timeMili).toFormat("dd/MM/yyyy");
    },
    getLongDate(): string {
      return `${
        DateTime.fromMillis(this.timeMili).setLocale("pt").monthLong
      }, ${new Date(this.timeMili).getFullYear()}`;
    },
  },
});
