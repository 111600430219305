


















import Vue from "vue";
import noticeTooltip from "./notice-tooltip.vue";

export default Vue.extend({
  components: { noticeTooltip },
  name: "NoticeBar",
  computed: {
    isMessageVisible(): boolean {
      return this.$store.getters["newActivityMessage"];
    },
  },
  methods: {
    closeMessage() {
      this.$store.commit("toggleNewActivityMessage", false);
    },
  },
});
