








































import Vue from "vue";
import { FormItem } from "@/store/form-record";
import FormScale from "@/components/form/form-scale.vue";

export default Vue.extend({
  name: "FormScaleTable",
  components: { FormScale },
  props: ["items", "sectionId", "pageCounter", "updateVal"],
  computed: {
    getTableTitlesFromItems: function (): any {
      return (items: FormItem[]) => {
        const itemOptions = items?.[0]?.item_option;

        return itemOptions
          ?.split("\r\n")
          ?.map((option) => option.replace(/[0-9|]/g, ""));
      };
    },
  },
});
