import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import store, { Nullable, RootState } from "../../store/index";
import customAxios from "@/axios-service";

export interface RoomInfo {
  device_type_id: number;
  device_type_name: string;
  friendly_name: string;
  humidity: number;
  humidity_profile: {
    x: string;
    y: string;
  }[];
  humidity_stats: {
    avg: number;
    max: number;
    max_hour: string;
    max_date: string;
    max_day: string;
    min: number;
    min_hour: string;
    min_date: string;
    min_day: string;
  };
  id: number;
  temperature: number;
  temperature_profile: {
    x: string;
    y: string;
  }[];
  temperature_stats: {
    avg: number;
    max: number;
    max_hour: string;
    max_date: string;
    max_day: string;
    min: number;
    min_hour: string;
    min_date: string;
    min_day: string;
  };
}

export interface QualityData {
  day: {
    start_time: string;
    end_time: string;
    profile: RoomInfo[];
    real_time_ht: RoomInfo[];
    stats: RoomInfo[];
  };
  month_before: {
    start_date: string;
    end_date: string;
    profile: {
      avg: RoomInfo[];
      max: RoomInfo[];
      min: RoomInfo[];
    };
    stats: RoomInfo[];
  };
  week_before: {
    start_date: string;
    end_date: string;
    profile: RoomInfo[][];
    stats: RoomInfo[];
  };
}

export interface QualityInfo {
  quality_info: Nullable<QualityData>;
}

export const state: QualityInfo = {
  quality_info: JSON.parse(sessionStorage.getItem("quality-info") || "null"),
};

const getters: GetterTree<QualityInfo, RootState> = {
  qualityInfo: (state) => {
    return state.quality_info;
  },
};

const mutations: MutationTree<QualityInfo> = {
  updateQualityInfo: (state, value: QualityData) => {
    state.quality_info = value;
    sessionStorage.setItem("quality-info", JSON.stringify(state.quality_info));
  },
};

const actions: ActionTree<QualityInfo, RootState> = {
  async getQualityInfo({ commit }, params) {
    return customAxios
      .get(`/api/ambient_quality_data`, { params })
      .then((response) => {
        return commit("updateQualityInfo", response.data);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
};

const namespaced = true;

const qualityData: Module<QualityInfo, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default qualityData;
