import { Module, GetterTree, MutationTree, ActionTree, Mutation } from "vuex";
import store, { RootState, Nullable } from "../../store/index";
import customAxios from "@/axios-service";

export interface SupportData {
  [key: string]: {
    title: string;
    text: string;
  }[];
}

export interface SupportInfo {
  support_info: Nullable<SupportData>;
  opened_support_id: number
}

export const state: SupportInfo = {
  support_info: JSON.parse(sessionStorage.getItem("support-info") || "null"),
  opened_support_id: -1
};

const getters: GetterTree<SupportInfo, RootState> = {
  supportInfo: (state) => {
    return state.support_info;
  },
  openedSupportId: (state) => {
    return state.opened_support_id;
  },
};

const mutations: MutationTree<SupportInfo> = {
  updateSupportInfo: (state, value: any) => {
    state.support_info = value;
    sessionStorage.setItem("support-info", JSON.stringify(state.support_info));
  },
  updateOpenedSupportId: (state, value: any) => {
    state.opened_support_id = value;
  },
};

const actions: ActionTree<SupportInfo, RootState> = {
  async getSupportInfo({ commit }) {
    return customAxios
      .get(`/api/faq_list`)
      .then((response) => {
        return commit("updateSupportInfo", response.data);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
};

const namespaced = true;

const supportData: Module<SupportInfo, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default supportData;
