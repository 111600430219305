import Vue from "vue";

// @Interface LineChartOptions
// this contains all variables used for the parameter to obtain the options for the line chart options
// these are the current input necessary for what we are using/displaying as options for the chart
export interface LineChartOptions {
  chartId: string;
  // deprecated, now title is displayed from the template instead of in the canvas
  chartTitle?: string;
  // this represents the X axis range values to be displayed, even if no values are that big
  // since default behaviour would just show values from biggest to smallest.
  // this is mandatory when using time units, since it would not know how to properly display information.
  min: number;
  max: number;
  // this is used in case you want to use time format and represents which time unit to use (for example: hour)
  unitType: string;
  // time format to be displayed on the X axis when unit type value is used
  // this uses Luxon's format info. So key would represent the unit type of time to display, for example
  // hour, day, week, etc. and the attribute is the luxon format. please check https://github.com/moment/luxon/blob/master/docs/formatting.md 
  // for more info
  displayFormats?: {
    [key: string]: string;
  };
  // luxon time format to be displayed on the tooltip. Same advise as previous point. For examples of usage of both of this
  // please check the config-chart.js file for how they are being used (eg: weeklyBarChartData method)
  tooltipFormat?: string;
  // unit type of value to be presented. Values used so far are kwH, ºc and %
  tooltipUnit?: string;
  // proprety to display legend
  hasLegend: boolean;
}

// @Interface BarChartOptions
// same thing as previous interface, only with addition of certain properties regarding bar charts.
// both could be merged into one but I guess originally were designed differently. (for example, only bar charts originaly had legends)
export interface BarChartOptions {
  chartId: string;
  chartTitle?: string;
  //
  isStacked: boolean;
  // min and max currently are products of the getTime() method aka milliseconds since January 1970
  // this represents the X axis range values
  min: number;
  max: number;
  unitType: string;
  displayFormats?: {
    [key: string]: string;
  };
  tooltipFormat?: string;
  hasLegend: boolean;
}

// @class ChartMixin
// This mixin is responsible for including all information (variables/methods) for the main graph classes
// this includes either methods regarding the creation of external content (tooltip & legend) to be injected,
// as well as general properties for the chart and generate said options to change it's visualisation properties
const ChartMixin = Vue.extend({
  data() {
    return {
      // data used for creating the gradient in line charts. This should only be used once per graph
      // not only is this a design requirement but also technical difficulty since if i remember correctly
      // this is storing the chart gradients to be displayed and id represents the index of which its stored
      chartGradient: [] as any[],
      chartWidth: 0,
      chartHeight: 0,
    };
  },
  methods: {
    // Since we are using a custom made tooltip, it's required for us to basically create it programmatically
    // basic structure of the tooltip is initialized here, following children containing the info is on the
    // external tooltip handler method
    generateTooltip(chart: any): HTMLElement {
      let tooltipElement: HTMLElement = chart.canvas.parentNode.querySelector(
        "#line-chart-tooltip"
      );

      if (!tooltipElement) {
        tooltipElement = document.createElement("div");
        tooltipElement.setAttribute("id", "line-chart-tooltip");
        tooltipElement.style.display = "flex";
        tooltipElement.style.flexDirection = "column";
        tooltipElement.style.alignItems = "center";
        tooltipElement.style.padding = "0";
        tooltipElement.style.color = "white";
        tooltipElement.style.pointerEvents = "none";
        tooltipElement.style.position = "absolute";
        tooltipElement.style.transform = "translate(-50%, -100%)";
        tooltipElement.style.transition = "all .5 ease";

        const table = document.createElement("table");
        table.style.margin = "0px";
        table.style.marginTop = "-1px";
        table.style.background = "#454545";
        table.style.borderRadius = "5px";
        table.style.padding = "6px";

        // Dialog triangle is what's used for the tooltip to look like a comic chat box
        const dialogTriangle = document.createElement("div");
        dialogTriangle.style.width = "0";
        dialogTriangle.style.margin = "0";
        dialogTriangle.style.borderRight = "10px solid transparent";
        dialogTriangle.style.borderLeft = "10px solid transparent";
        dialogTriangle.style.borderTop = "10px solid #454545";

        tooltipElement.appendChild(table);
        tooltipElement.appendChild(dialogTriangle);
        chart.canvas.parentNode.appendChild(tooltipElement);
      }

      return tooltipElement;
    },

    // Read previous description.
    // Might be necessary to update this handler or use new ones for other types of graph
    // Current one was designed for Line charts
    externalTooltipHandler(context: any) {
      // Tooltip Element
      const { chart, tooltip } = context;
      const tooltipEl = this.generateTooltip(chart);

      // Hide if no tooltip
      // necessary to clean the tooltip when clicking away
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = "0";
        return;
      }

      // Set Text
      if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b: any) => b.lines);

        const tableHead = document.createElement("thead");

        titleLines.forEach((title: string) => {
          const tr = document.createElement("tr");
          tr.style.borderWidth = "0";

          const th = document.createElement("th");
          th.style.borderWidth = "0";
          th.style.margin = "0px";
          th.style.textAlign = "center";

          const text = document.createTextNode(title);

          th.appendChild(text);
          tr.appendChild(th);
          tableHead.appendChild(tr);
        });

        const tableBody = document.createElement("tbody");
        bodyLines.forEach((body: string, i: number) => {
          const colors = tooltip.labelColors[i];

          const tr = document.createElement("tr");
          tr.style.backgroundColor = "inherit";
          tr.style.borderWidth = "0";
          tr.style.margin = "0px";

          const td = document.createElement("td");
          td.style.borderWidth = "0";
          td.style.margin = "0px";
          td.style.color = colors.borderColor;
          td.style.fontWeight = "bold";
          td.style.textAlign = "center";

          const span = document.createElement("span");
          span.style.color = "#afafaf";
          // this is just a very ugly hack to display the custom unit type that is in the chart options
          // since this is injected without having the ability to introduce any external parameters, I decided to use one
          // internal chart property which is not being used to store the text there and use it here if defined
          const tooltipUnit = chart?.config?.options.plugins.title.text ? chart.config.options.plugins.title.text : "kWh";
          span.textContent = ` ${tooltipUnit}`;

          // table body text is the power value
          // it is required to remove the label text here (comes before the ':') for linecharts
          // for body we keep it
          const isBar = chart?.config?.type === "bar";
          const text = document.createTextNode(body[0].split(": ")[1]);

          const span2 = document.createElement("span");
          span2.style.color = "#ffffff";
          span2.textContent = isBar ? `${body[0].split(":")[0]}: ` : "";

          if (isBar) {
            td.appendChild(span2);
          }
          td.appendChild(text);
          td.appendChild(span);
          tr.appendChild(td);
          tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector("table");

        if (tableRoot) {
          // Remove old children
          while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
          }

          // Add new children
          tableRoot.appendChild(tableHead);
          tableRoot.appendChild(tableBody);
        }
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = "1";
      tooltipEl.style.left = positionX + tooltip.caretX + "px";
      tooltipEl.style.top = positionY + tooltip.caretY + "px";
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
    },

    // legend generated element/basic structure to be displayed
    generateCustomLegend(chart: any, id: string) {
      let legendContainer = document.getElementById(id);
      if (!legendContainer) {
        legendContainer = document.createElement("div");
        legendContainer.setAttribute("id", "line-chart-tooltip");
      }
      let listContainer = legendContainer?.querySelector("ul");

      if (!listContainer && legendContainer) {
        listContainer = document.createElement("ul");
        listContainer.style.display = "flex";
        listContainer.style.flexDirection = "row-reverse";
        listContainer.style.flexWrap = "wrap-reverse";
        listContainer.style.justifyContent = "center";
        listContainer.style.margin = "0";
        listContainer.style.padding = "0";

        legendContainer.appendChild(listContainer);
      }

      return listContainer;
    },

    // external handler to generate the styling & handling behaviour or legend
    // for more information of it's properties, please check https://www.chartjs.org/docs/3.7.0/samples/legend/html.html
    externalLegendHandler() {
      const generateLegendContainer = this.generateCustomLegend;
      return {
        id: "htmlLegend",
        afterUpdate(chart: any, args: any, options: any) {
          const ul = generateLegendContainer(chart, options.containerID);

          if (ul) {
            while (ul.firstChild) {
              ul.firstChild.remove();
            }
          }

          // Reuse the built-in legendItems generator
          const items =
            chart.options.plugins.legend.labels.generateLabels(chart);

          items.reverse().forEach((item: any) => {
            const li = document.createElement("li");
            li.style.alignItems = "center";
            li.style.cursor = "pointer";
            li.style.display = "flex";
            li.style.flexDirection = "row";
            li.style.marginRight = "8px";
            li.style.marginLeft = "8px";
            li.style.marginBottom = "4px";

            li.onclick = () => {
              const { type } = chart.config;
              if (type === "pie" || type === "doughnut") {
                // Pie and doughnut charts only have a single dataset and visibility is per item
                chart.toggleDataVisibility(item.index);
              } else {
                chart.setDatasetVisibility(
                  item.datasetIndex,
                  !chart.isDatasetVisible(item.datasetIndex)
                );
              }
              chart.update();
            };

            // Color box
            const boxSpan = document.createElement("span");
            boxSpan.style.background = item.strokeStyle;
            boxSpan.style.borderColor = item.strokeStyle;
            boxSpan.style.borderWidth = item.lineWidth + "px";
            boxSpan.style.borderRadius = "15px";
            boxSpan.style.display = "inline-block";
            boxSpan.style.height = "5px";
            boxSpan.style.marginRight = "7px";
            boxSpan.style.width = "15px";

            // Text
            const textContainer = document.createElement("p");
            textContainer.style.color = "#898989";
            textContainer.style.fontSize = "12px";
            textContainer.style.margin = "0";
            textContainer.style.padding = "0";
            textContainer.style.textDecoration = item.hidden
              ? "line-through"
              : "";

            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);

            li.appendChild(boxSpan);
            li.appendChild(textContainer);
            if (ul) {
              ul.appendChild(li);
            }
          });
        },
      };
    },

    // Gradient method for background color in line charts.
    // colors determine the gradient top and bottom colors as currently these are the only stops
    getGradient(
      ctx: any,
      chartArea: any,
      colors: { top: string; bottom: string },
      index: number
    ): any {
      const width = chartArea.right - chartArea.left;
      const height = chartArea.bottom - chartArea.top;
      // this offset exists because for some reason without it the 0 gradient value would be too high on the scale
      const offset = 75;
      if (
        !this.chartGradient[index] ||
        width !== this.chartWidth ||
        height !== this.chartHeight
      ) {
        this.chartWidth = width;
        this.chartHeight = height;
        this.chartGradient[index] = ctx.createLinearGradient(
          0,
          chartArea.bottom + offset,
          0,
          chartArea.top
        );
        // Following comments are here since there were some typing issues complaining chartGradient could be null
        // Even putting this in a if statement to check if the object is defined was giving errors
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.chartGradient[index].addColorStop(0, colors.bottom);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.chartGradient[index].addColorStop(1, colors.top);
      }

      return this.chartGradient[index];
    },

    // TODO update this with the x ticks maybe ??
    // This are the default/common properties of all available charts
    // if other chart types have different structure, change this to the correspondent class
    // this is basically the default structure of the properties/option chart.js charts use.
    // You can see in the methods below that this is the skeleton used to change the values
    getCommonOptionsObject(): any {
      return {
        responsive: true,
        maintainAspectRatio: false,
        // used to show the percentage of column to use to display the bar
        maxBarThickness: undefined,
        barPercentage: undefined,
        // percentage of size of column for the x axis to display bars
        categoryPercentage: undefined,
        // x axis properties
        scales: {
          x: {
            // don't remember what this option was for ahah oops
            parsing: false,
            // this is used to not display the axis
            grid: {
              display: false,
              drawBorder: false,
            },
            // time related options. keep this just to have the object defined
            time: {},
            // range X axis values when time component is used
            min: undefined,
            max: undefined,
          },
          y: {
            grid: {
              drawTicks: false,
              drawBorder: false,
              // these properties are to draw our horizontal dash lines in the graphs
              borderDash: [5, 5],
              borderDashOffset: 5,
            },
            // this is to change the properties of the Y axis labels.
            // originally this had the unit type here but now it's just a hack to give a bit more space from the
            // y axis :)
            ticks: {
              color: "#adadad",
              callback: function (val: any, index: any): any {
                // following comment is required since otherwise this wouldn't recognize the getLabel method
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // return this.getLabelForValue(val) + " kWh";
                return this.getLabelForValue(val) + "   ";
              },
            },
          },
        },
        interaction: {
          intersect: false,
        },
        // external options are added as plugins for example title information.
        plugins: {
          // titel information is no longer available as it is used in the template rather than the canvas
          // but keep this up as it is used in a "hack" to display the unit type
          title: {
            align: "start",
            display: false,
            text: "kWh",
            color: "#273043",
            padding: {
              bottom: 15,
              top: 2,
            },
            font: {
              family: "Source Sans Pro",
              size: 16,
              weight: "700",
            },
          },
          // this is probably deprecated as we are using a external legend now
          legend: {
            display: false,
            position: undefined,
            labels: {
              usePointStyle: true,
              pointStyle: "rectRounded",
              boxHeight: 5,
            },
          },
          // id of the external legend container. this should contain the chart ID to properly differentiate it from other charts
          // on the same page
          htmlLegend: {
            containerID: undefined,
          },
          tooltip: {
            enabled: false,
            position: "nearest",
            external: this.externalTooltipHandler,
          },
        },
      };
    },

    // method called in the LineChart class. Useful to create the necesary chart options based on the
    // diferent properties available in the chartExtraOptions parameter and match the correct format used
    getLineChartOption(chartExtraOptions: LineChartOptions): any {
      const options = this.getCommonOptionsObject();
      options.scales.x.type = "time";
      options.scales.x.time = {
        unit: chartExtraOptions.unitType,
        displayFormats: chartExtraOptions.displayFormats,
        tooltipFormat: chartExtraOptions.tooltipFormat,
      };
      if (chartExtraOptions.chartTitle) {
        options.plugins.title.display = true;
        options.plugins.title.text = chartExtraOptions.chartTitle;
      } else if (chartExtraOptions.tooltipUnit) {
        // This is just a hack to parse into the chart config the info relative to which unit to use in the tooltip
        // since there isn't really a open field there, I decided to use this one since the current chart title is coming
        // from an external source and not using it's internal one
        options.plugins.title.text = chartExtraOptions.tooltipUnit;
      }
      options.scales.x.min = chartExtraOptions.min;
      options.scales.x.max = chartExtraOptions.max;

      if (chartExtraOptions.hasLegend) {
        options.plugins.legend.display = false;

        options.plugins.htmlLegend.containerID = `legend-container-${chartExtraOptions.chartId}`;
      }

      // this method i believe is exclusive to line charts as it's the only type of chart to display
      // hourly information. THis is a hack to change the default hour time format luxon has to a more clean one
      // color property should/could be on the defautl common options object
      options.scales.x.ticks = {
        color: "#adadad",
        callback: function (val: any, index: any): any {
          if (chartExtraOptions.unitType === "hour") {
            // following comment is required since otherwise this wouldn't recognize the getLabel method
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const numberConv: string =
              val === "00" && index !== 0
                ? "24"
                : val[0] === "0"
                ? val[1]
                : val;

            return numberConv + "h";
          }

          return val;
        },
      };

      return options;
    },

    // method called in the BarChart class. Useful to create the necesary chart options based on the
    // diferent properties available in the chartExtraOptions parameter and match the correct format used
    getBarChartOption(chartExtraOptions: BarChartOptions): any {
      const options = this.getCommonOptionsObject();

      if (chartExtraOptions.chartTitle) {
        options.plugins.title.display = true;
        options.plugins.title.text = chartExtraOptions.chartTitle;
      }

      if (chartExtraOptions.isStacked) {
        options.scales.x.stacked = true;
        options.scales.y.stacked = true;
      } else {
        options.barPercentage = 0.75;
        options.categoryPercentage = 0.5;
      }

      if (chartExtraOptions.unitType && chartExtraOptions.min) {
        options.scales.x.type = "time";
        options.scales.x.time = {
          unit: chartExtraOptions.unitType,
          displayFormats: chartExtraOptions.displayFormats,
          tooltipFormat: chartExtraOptions.tooltipFormat,
        };
        options.scales.x.min = chartExtraOptions.min;
        options.scales.x.max = chartExtraOptions.max;
      }

      options.maxBarThickness = 25;
      // color property should/could be on the defautl common options object
      options.scales.x.ticks = {
        color: "#adadad",
      };

      if (chartExtraOptions.hasLegend) {
        options.plugins.legend.display = false;

        options.plugins.htmlLegend.containerID = `legend-container-${chartExtraOptions.chartId}`;
      }

      return options;
    },
  },
});

export { ChartMixin };
export default {};
