var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"participant-footer-area text-2 mx-0",class:{
    'participant-footer-area-sm':
      _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm,
    'participant-footer-area-md':
      _vm.$vuetify.breakpoint.md ||
      _vm.$vuetify.breakpoint.lg ||
      _vm.$vuetify.breakpoint.xl,
  }},[_c('div',{staticClass:"title-row px-0 mt-12"}),_c('v-col',{staticClass:"px-8 pt-1 pb-0"},[_c('footer-general')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }