





import Chart from "chart.js/auto";
// import { DateTime } from "luxon";
// This import is required to use the timer adaptor for the chart
import "chartjs-adapter-luxon";

import mixins from "vue-typed-mixins";
import { ChartMixin } from "@/mixins/chart-mixin";

export interface ChartData {
  label: string;
  data: {
    x: string;
    y: number;
  }[];
  backgroundColor: string;
  borderColor: string;
  borderRadius: number;
}

export default mixins(ChartMixin).extend({
  name: "BarChart",
  props: {
    chartID: {
      required: true,
      type: String,
    },
    chartTitle: {
      required: false,
      default: "",
      type: String,
    },
    stacked: {
      default: false,
      required: false,
      type: Boolean,
    },
    displayLegend: {
      required: false,
      default: false,
      type: Boolean,
    },
    timeInfo: {
      required: false,
      type: Object as () => {
        unitType?: string;
        displayFormats?: any;
        tooltipFormat?: string;
      },
      default: () => ({} as any),
    },
    rangeValues: {
      required: false,
      type: Object as () => {
        max: number;
        min: number;
      },
      default: () => ({
        max: 0,
        min: 0,
      }),
    },
    chartLabels: {
      required: false,
      type: Array as () => string[],
      default: () => [],
    },
    chartInfo: {
      required: true,
      type: Object as () => {
        data: [];
        labels?: [];
        // these can be either an object with 2 values (for gradient) or a string
        colors: [];
      },
    },
  },
  data() {
    return {
      chartRef: undefined as any,
    };
  },
  mounted() {
    const curCanvas: HTMLCanvasElement = document.getElementById(
      this.chartID
    ) as HTMLCanvasElement;

    const options = this.getBarChartOption({
      chartId: this.chartID,
      min: this.rangeValues.min,
      max: this.rangeValues.max,
      isStacked: this.stacked,
      unitType: this.timeInfo.unitType,
      displayFormats: this.timeInfo.displayFormats,
      tooltipFormat: this.timeInfo.tooltipFormat,
      chartTitle: this.chartTitle,
      hasLegend: this.displayLegend,
    });

    const sortedChartData = this.getSortedByEquipmentConsumption();

    this.chartRef = new Chart(curCanvas, {
      type: "bar",
      data: {
        labels: this.chartLabels,
        datasets: sortedChartData,
      },
      options: options,
      plugins: [this.externalLegendHandler()],
    });
  },
  methods: {
    formatChartData() {
      const chartData = [];
      for (let i = 0; i < this.chartInfo.data.length; i++) {
        const curColor: any = this.chartInfo.colors[i];
        const curLabels = this.chartInfo.labels;
        if (!curColor || !curColor.border) {
          continue;
        }
        const tempData = {
          label: curLabels && curLabels.length ? curLabels[i] : "",
          data: this.chartInfo.data[i],
          backgroundColor: curColor.border,
          borderColor: curColor.border,
          borderRadius: 5,
        };

        chartData.push(tempData);
      }

      return chartData;
    },
    forceUpdateChart() {
      this.chartRef.data.datasets = this.getSortedByEquipmentConsumption();
      this.chartRef.data.labels = this.chartLabels;
      this.chartRef.options = this.getBarChartOption({
        chartId: this.chartID,
        min: this.rangeValues.min,
        max: this.rangeValues.max,
        isStacked: this.stacked,
        unitType: this.timeInfo.unitType,
        displayFormats: this.timeInfo.displayFormats,
        tooltipFormat: this.timeInfo.tooltipFormat,
        chartTitle: this.chartTitle,
        hasLegend: this.displayLegend,
      });
      this.chartRef.update();
    },
    getSortedByEquipmentConsumption() {
      const chartData: ChartData[] = this.formatChartData() ?? [];

      return chartData.sort((current, next) => {
        const sumCurrentConsumption = current.data.reduce((a, c) => {
          return a + c.y;
        }, 0);
        const sumNextConsumption = next.data.reduce((a, c) => {
          return a + c.y;
        }, 0);

        return sumCurrentConsumption - sumNextConsumption;
      });
    },
  },
  // chart graphics don't update when most of it's properties change so it is required to
  // force rerendering whenever these changes happen. For this case, it is required to do so
  // when there are data changes or label information
  watch: {
    "chartInfo.data"(newValue) {
      this.forceUpdateChart();
    },
    chartLabels(newValue) {
      this.forceUpdateChart();
    },
  },
});
