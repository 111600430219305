




















import Vue from "vue";
export default Vue.extend({
  name: "ToolTip",
  props: ["helpText"],
  data() {
    return {
      trigger: undefined,
    };
  },
});
