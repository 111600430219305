

























































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import SolidButton from "@/components/general/solid-button.vue";

export default mixins(GeneralMixin).extend({
  name: "CookieModal",
  components: {
    SolidButton,
  },
  props: ["modalName", "modalTitle", "modalText"],
  computed: {
    modalShow: {
      get(): string {
        return (
          this.$store.getters["modalState"](this.modalName) &&
          this.$store.getters["privacyCookie"]
        );
      },
      set() {
        this.closeModal(this.modalName);
      },
    },
  },
  methods: {
    triggerToggleCookie(state: boolean): void {
      this.closeModal(this.modalName);
      this.togglePrivacyCookie(state);
    },
  },
});
