
















































































































import config from "@/config";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";

export default mixins(GeneralMixin).extend({
  name: "DashboardTempCard",
  props: ["cardTitle", "roomData"],
  data() {
    return {
      roomInfo: config.roomInfo,
    };
  },
});
