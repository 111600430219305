import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID },
  enabled: store.getters.googleCookie && store.getters.privacyCookie === false && process.env.NODE_ENV === "production" ? true : false,
  bootstrap: store.getters.googleCookie && store.getters.privacyCookie === false && process.env.NODE_ENV === "production" ? true : false,
  // pageTrackerScreenviewEnabled: true,
  appName: "LivingEnergy",
}, router);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
