

































import Vue from "vue";

export default Vue.extend({
  name: "ParticipantSmallInfoCard",
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: String,
    icon: String,
    iconColor: String,
    consumption: [String, Number],
    unit: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getIconUrl() {
      return this.icon
        ? require(`@/assets/icons/${this.icon}`)
        : require(`@/assets/icons/icon-appliance-default.svg`);
    },
  },
});
