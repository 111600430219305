








































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
export default mixins(GeneralMixin).extend({
  name: "ExternalModal",
  props: [
    "modalName",
    "modalTitle",
    "modalText",
    "link",
    "modalOkButton",
    "modalCancButton",
    "modalImage",
    "isExternal",
  ],
  methods: {
    routeParentAction() {
      this.$emit("callParentAction");
    },
  },
  computed: {
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        this.closeModal(this.modalName);
      },
    },
  },
});
