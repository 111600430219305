















import mixins from "vue-typed-mixins";
import { ActivityMixin } from "@/mixins/activity-mixin";
import TaskCard from "./task-card.vue";
export default mixins(ActivityMixin).extend({
  name: "ActivityHistoryGeneral",
  components: {
    TaskCard,
  },
});
