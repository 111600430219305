



























































import Vue from "vue";
import config from "@/config";

export interface ApplianceConsumption {
  device_type_id: number;
  device_type_name: string;
  color: string;
  energy: number;
  id: number;
  percentageConsumption?: number;
  isSelected?: boolean;
  profile?: any[];
}

export default Vue.extend({
  name: "ApplianceChart",

  props: {
    appliances: {
      required: true,
      type: Array as () => ApplianceConsumption[],
    },
    title: {
      required: true,
      type: String,
    },
    pagination: {
      required: false,
      type: Number,
      default: 0,
    },
    perTabPagination: {
      required: false,
      type: Number,
      default: 5,
    },
  },

  data() {
    return {
      applianceConsumption: [] as ApplianceConsumption[],
      applianceDisplayInfo: config.applianceInfo as any,
    };
  },

  mounted() {
    this.updateChart();
  },

  methods: {
    updateChart() {
      // this is to avoid changing the original object
      this.applianceConsumption = [...this.appliances];
      this.updateApplianceDisplayInfo();
    },
    getApplianceIcon(id: number): string {
      if (
        id &&
        this.applianceDisplayInfo[id] &&
        this.applianceDisplayInfo[id].icon
      ) {
        return require(`@/assets/icons/appliances/${this.applianceDisplayInfo[id].icon}`);
      }
      return require("@/assets/icons/icon-appliance-default.svg");
    },
    updateApplianceDisplayInfo(): void {
      if (!this.applianceConsumption || !this.applianceConsumption.length) {
        return;
      }
      const maxSize = this.applianceConsumption[0].energy;
      this.applianceConsumption.map((a: ApplianceConsumption) => {
        a.percentageConsumption = (a.energy / maxSize) * 90;
        return a;
      });
    },
    validateMaximum(id: number): any {
      const getSelected = this.applianceConsumption.filter((x) => x.isSelected);
      return (
        getSelected.length >= this.perTabPagination &&
        !getSelected.find((x) => x.id === id)
      );
    },
    applianceSelection(appliance: ApplianceConsumption, isSelected: boolean) {
      this.$emit("applianceSelection", appliance, isSelected);
    },
  },
  watch: {
    appliances(newVal) {
      this.updateChart();
    },
  },
});
