





















import { GeneralMixin } from "@/mixins/general-mixin";
import mixins from "vue-typed-mixins";
export default mixins(GeneralMixin).extend({
  name: "FooterGeneral",
});
