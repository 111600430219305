var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"d-none d-sm-block",class:_vm.roughEdges
        ? 'no-radius'
        : !_vm.history || _vm.history.length == 0
        ? 'radius-stuff'
        : 'custom-card-radius',attrs:{"elevation":"2"}},[(_vm.cardTitle)?_c('v-card-title',{staticClass:"pl-8 participant-card-title-area py-3 my-0",staticStyle:{"font-size":"24px"}},[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")]):_vm._e(),[_c('v-row',{staticClass:"\n          pt-6\n          pb-3\n          pl-8\n          pr-11\n          d-flex\n          justify-start\n          align-center\n          divider-line\n        ",staticStyle:{"height":"92.5px"}},[_c('v-col',{staticClass:"d-flex justify-start buttons semi-strong-text py-0",attrs:{"cols":"8"}},[_c('button',{staticClass:"btns text-equips",class:{ modeon: _vm.check_btn == 0, modeoffEnergy: _vm.check_btn != 0 },on:{"click":function($event){_vm.changeProj(), _vm.reset()}}},[_vm._v(" Projetos ")]),_c('button',{staticClass:"btns text-equips",class:{
              modeOffTemp: _vm.check_btn != 1,
              modeonTemp: _vm.check_btn == 1,
            },on:{"click":function($event){_vm.changeTask(), _vm.reset()}}},[_vm._v(" Tarefas ")]),_c('button',{staticClass:"btns text-equips",class:{ modeoff: _vm.check_btn != 2, modeonExp: _vm.check_btn == 2 },on:{"click":function($event){_vm.changeExp(), _vm.reset()}}},[_vm._v(" Expirados ")])]),(
            _vm.history
              ? _vm.history.length != 0 && _vm.history.length > _vm.page_size
              : false
          )?_c('v-col',{staticClass:"d-flex justify-end pr-0 ml-auto",attrs:{"cols":"3"}},[_c('button',{staticClass:"prev-page",attrs:{"disabled":_vm.prev_disabled},on:{"click":_vm.prev}},[_c('v-icon',{staticClass:"arrow",attrs:{"size":"30","color":"#ffffff"}},[_vm._v("mdi-chevron-left")])],1),_c('div',{staticClass:"pl-2 align-counter d-flex align-center pb-1"},[_c('span',{staticClass:"strong-text pr-1"},[_vm._v(_vm._s(_vm.select + 1))]),_vm._v(" de "+_vm._s(Math.ceil(_vm.history.length / _vm.page_size))+" ")]),_c('button',{staticClass:"next-page ml-2",attrs:{"disabled":_vm.next_disabled},on:{"click":_vm.next}},[_c('v-icon',{staticClass:"arrow",attrs:{"size":"30","color":"#ffffff"}},[_vm._v("mdi-chevron-right")])],1)]):_vm._e()],1)],_c('div'),(_vm.history && _vm.history.length > 0)?_c('div',[(_vm.check_btn == 0)?_c('div',{staticClass:"px-0 pt-1",class:_vm.history.length > _vm.page_size ? 'divider-line' : ''},[_c('activity-history-project',{attrs:{"history":_vm.pageHistory}})],1):_vm._e(),(_vm.check_btn == 1)?_c('div',{staticClass:"px-2 activity-history",class:_vm.history.length > _vm.page_size ? 'divider-line' : ''},[_c('activity-history-general',{attrs:{"history":_vm.pageHistory}})],1):_vm._e(),(_vm.check_btn == 2)?_c('div'):_vm._e(),(_vm.history && _vm.history.length > 0 && _vm.history.length > _vm.page_size)?_c('div',{staticClass:"pagination d-flex align-center pt-7 pb-7"},_vm._l((_vm.history
            ? Math.ceil(_vm.history.length / _vm.page_size)
            : null),function(item,i){return _c('div',{key:i,class:[_vm.select == i ? 'ball-sel' : 'ball']})}),0):_vm._e()]):_vm._e()],2),_c('v-card',{staticClass:"d-block d-sm-none",class:_vm.roughEdges
        ? 'no-radius'
        : !_vm.history || _vm.history.length == 0
        ? 'radius-stuff'
        : 'custom-card-radius',attrs:{"elevation":"2"}},[(_vm.cardTitle)?_c('v-card-title',{staticClass:"pl-8 participant-card-title-area py-3 my-0",staticStyle:{"font-size":"24px"}},[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")]):_vm._e(),_c('v-row',{staticClass:"pt-5 pb-2 divider-line"},[_c('v-col',{staticClass:"buttons semi-strong-text d-flex px-11",attrs:{"cols":"12"}},[_c('button',{staticClass:"btns-small text-equips",class:{ modeon: _vm.check_btn == 0, modeoffEnergy: _vm.check_btn != 0 },on:{"click":function($event){_vm.changeProj(), _vm.reset()}}},[_vm._v(" Projetos ")]),_c('button',{staticClass:"btns-small text-equips",class:{
            modeOffTemp: _vm.check_btn != 1,
            modeonTemp: _vm.check_btn == 1,
          },on:{"click":function($event){_vm.changeTask(), _vm.reset()}}},[_vm._v(" Tarefas ")]),_c('button',{staticClass:"btns-small text-equips",class:{ modeoff: _vm.check_btn != 2, modeonExp: _vm.check_btn == 2 },on:{"click":function($event){_vm.changeExp(), _vm.reset()}}},[_vm._v(" Expirados ")])])],1),(_vm.history ? _vm.history && _vm.history.length > 0 : false)?_c('div',[(_vm.check_btn == 0)?_c('div',{staticClass:"px-0 pt-1"},[_c('activity-history-project',{attrs:{"history":_vm.pageHistory}})],1):_vm._e(),(_vm.check_btn == 1)?_c('div',{staticClass:"px-2 activity-history",class:_vm.history.length > _vm.page_size ? 'divider-line' : ''},[_c('activity-history-general',{attrs:{"history":_vm.pageHistory}})],1):_vm._e(),(_vm.check_btn == 2)?_c('div'):_vm._e()]):_vm._e(),(
        _vm.history ? _vm.history.length != 0 && _vm.history.length > _vm.page_size : false
      )?_c('v-row',{staticClass:"pt-5 pb-2"},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('button',{staticClass:"prev-page",attrs:{"disabled":_vm.prev_disabled},on:{"click":_vm.prev}},[_c('v-icon',{staticClass:"arrow",attrs:{"size":"30","color":"#ffffff"}},[_vm._v("mdi-chevron-left")])],1),_c('div',{staticClass:"pl-2 align-counter d-flex align-center pb-1"},[_c('span',{staticClass:"strong-text pr-1"},[_vm._v(_vm._s(_vm.select + 1))]),_vm._v(" de "+_vm._s(Math.ceil(_vm.history.length / _vm.page_size))+" ")]),_c('button',{staticClass:"next-page ml-2",attrs:{"disabled":_vm.next_disabled},on:{"click":_vm.next}},[_c('v-icon',{staticClass:"arrow",attrs:{"size":"30","color":"#ffffff"}},[_vm._v("mdi-chevron-right")])],1)])],1):_vm._e()],1),(!_vm.history || _vm.history.length == 0)?_c('participant-no-content-info-card',{staticClass:"mt-3",attrs:{"roughEdges":1,"icon":'activity/icon-no-history.svg',"size":6,"messageTitle":_vm.check_btn == 0
        ? 'Nenhum Projeto no Histórico'
        : _vm.check_btn == 1
        ? 'Nenhuma Tarefa no Histórico'
        : 'Nenhuma Tarefa Expirada',"messageText":_vm.check_btn == 0
        ? 'Não tens projetos terminados ainda. Assim que termines, o teu primeiro projeto irá aparecer nesta secção.'
        : _vm.check_btn == 1
        ? 'Não tens tarefas terminadas ainda. Assim que termines, o tua primeira tarefa terminada irá aparecer nesta secção.'
        : 'Sem registo de projetos expirados. Se algum dia um dos teus projetos expirar, ele irá aparecer nesta secção.'}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }