























































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import PercentageBar from "./percentage-bar.vue";
import moment from "moment";
import solidButton from "@/components/general/solid-button.vue";

interface SubTaskInfo {
  id: number;
  index: number;
  name: string;
  description: string;
  state: string;
  credits?: number;
}

export default mixins(GeneralMixin).extend({
  components: { solidButton, PercentageBar },
  data() {
    return {
      date: new Date(),
    };
  },
  methods: {
    checkDates(date: any): boolean {
      let dateNew = new Date();
      if (moment(date).diff(moment(dateNew), "days") <= 5) return true;
      return false;
    },
  },
  name: "HomeactivityCard",
  props: {
    subTaskBtn: Function,
    info: {
      required: false,
      type: Object as () => SubTaskInfo,
      default: {},
    },
  },
});
