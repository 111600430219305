











































































































































































































import mixins from "vue-typed-mixins";
import { FormMixin } from "@/mixins/form-mixin";
import SolidButton from "../general/solid-button.vue";
import toolTip from "../general/tool-tip.vue";
import config from "@/config";

export default mixins(FormMixin).extend({
  components: { SolidButton, toolTip },
  name: "FormPhotoUpload",
  data() {
    return {
      imgURL: config.imgUrl,
    };
  },
  created() {
    this.setFiles();
  },
});
