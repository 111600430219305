import { Module, GetterTree, MutationTree, ActionTree, Mutation } from "vuex";
import store, { RootState, Nullable } from "../../store/index";
import customAxios from "@/axios-service";

export interface MonitorInfo {
  monitor_info: Nullable<[]>;
}

export const state: MonitorInfo = {
  monitor_info: JSON.parse(sessionStorage.getItem("monitor-info") || "null"),
};

const getters: GetterTree<MonitorInfo, RootState> = {
  monitorInfo: (state) => {
    return state.monitor_info;
  },
};

const mutations: MutationTree<MonitorInfo> = {
  updateMonitorInfo: (state, value: any) => {
    state.monitor_info = value;
    sessionStorage.setItem("monitor-info", JSON.stringify(state.monitor_info));
  },
};

const actions: ActionTree<MonitorInfo, RootState> = {
  async getMonitorInfo({ commit }) {
    return customAxios
      .get(`/api/sensor_list`)
      .then((response) => {
        return commit("updateMonitorInfo", response.data);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async sendEquipInfo({ commit }, data: any) {
    return customAxios
      .post(`api/download_devices_data`, data)
      .then(() => {
        return true;
      })
      .catch((e) => {
        return false;
      });
  },
};

const namespaced = true;

const monitorData: Module<MonitorInfo, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default monitorData;
