





















import Vue from "vue";

export default Vue.extend({
  name: "ChartSimpleCheckbox",
  props: {
    chartData: {
      required: true,
      type: Object as () => {
        list: string[];
        color: string;
        defaultCheck?: boolean;
      },
    },
  },
  data() {
    return {
      selection: [] as boolean[],
    };
  },
  created() {
    if (this.chartData && this.chartData.defaultCheck) {
      this.chartData.list.forEach((x, index) => {
        this.selection[index] = true;
      });
    }
  },
  methods: {
    updateSelection() {
      this.$emit("checkboxSelection", this.selection);
    },
  },
  watch: {
    chartData(newValue) {
      this.selection = [];
    },
  },
});
