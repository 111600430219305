









import Vue from "vue";

export default Vue.extend({
  name: "PercentageBar",
  props: {
    percentage: {
      type: Number,
      required: false,
      default: 0,
    },
    color: {
      type: String,
      required: false,
      default: "green-1",
    },
  },
});
