






























import Vue from "vue";
import ParticipantFooter from "@/components/participant/participant-footer.vue";
import SolidButton from "@/components/general/solid-button.vue";

export default Vue.extend({
  name: "GeneralNotFound",
  components: {
    ParticipantFooter,
    SolidButton,
  },
  methods: {
    returnHome() {
      this.$router.push("/login");
    },
  },
});
