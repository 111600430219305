













































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import solidButton from "@/components/general/solid-button.vue";

interface ProjectInfo {
  id?: number;
  name: string;
  date: string;
  credits: number;
  time: string;
  longDescription: string;
  activities: string[];
}

export default mixins(GeneralMixin).extend({
  components: { solidButton },
  name: "GeneralModal",
  props: {
    modalTitle: {
      type: String,
      required: false,
      default: "Novo Projeto",
    },
    modalName: {
      type: String,
      required: false,
      default: "ProjectInfoModal",
    },
    task: {
      type: Object as () => ProjectInfo,
      required: true,
    },
  },
  computed: {
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        this.closeModal(this.modalName);
      },
    },
  },
});
