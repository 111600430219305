



























































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import solidButton from "@/components/general/solid-button.vue";

interface VoucherInfo {
  id: number;
  name: string;
  value: string,
  credits: number;
  updateTotal: number;
  email: string;
}

export default mixins(GeneralMixin).extend({
  components: { solidButton },
  name: "GeneralModal",
  props: {
    modalTitle: {
      type: String,
      required: false,
      default: "Levantar Voucher",
    },
    modalName: {
      type: String,
      required: false,
      default: "VoucherModal",
    },
    voucher: {
      type: Object as () => VoucherInfo,
      required: true,
    },
  },
  computed: {
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        this.closeModal(this.modalName);
      },
    },
  },
  methods: {
    emitPurchase() {
      this.closeModal(this.modalName);
      this.$emit("voucherExchange", this.voucher.id);
    },
  },
});
