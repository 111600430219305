



















import Vue from "vue";
import footerGeneral from "../general/footer-general.vue";
export default Vue.extend({
  components: { footerGeneral },
  name: "ParticipantFooter",
  props: ["formTitle"],
});
