


























































import Vue from "vue";
export default Vue.extend({
  name: "FormProgress",
  props: ["pages", "currentPage", "pageCounter"],

  data() {
    return {
      isOverflown: false,
      scrollWidth: 0,
    };
  },

  mounted() {
    const carouselDiv: Element = this.$refs.carousel as Element;
    this.scrollWidth = carouselDiv.scrollWidth;

    this.stepContainerOverflow();
    window.addEventListener("resize", this.stepContainerOverflow);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.stepContainerOverflow);
  },

  methods: {
    stepContainerOverflow(): void {
      const carouselDiv: Element = this.$refs.carousel as Element;

      // number of pixels to add as an extra spacing to detect when to break div into more responsive version
      const clientWidthThreshold = 20;
      this.isOverflown =
        this.scrollWidth > carouselDiv.clientWidth + clientWidthThreshold;
    },
  },
});
