import { render, staticRenderFns } from "./participant-profile-bar.vue?vue&type=template&id=53a93c7c&scoped=true&"
import script from "./participant-profile-bar.vue?vue&type=script&lang=ts&"
export * from "./participant-profile-bar.vue?vue&type=script&lang=ts&"
import style0 from "./participant-profile-bar.vue?vue&type=style&index=0&id=53a93c7c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53a93c7c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VCol,VContainer,VRow})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
