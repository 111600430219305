






































import mixins from "vue-typed-mixins";
import SolidButton from "@/components/general/solid-button.vue";
import { ProfileMixin } from "@/mixins/participant-general-mixin";

export default mixins(ProfileMixin).extend({
  name: "ParticipantDashboardModal",
  components: {
    SolidButton,
  },
  props: ["modalName", "modalTitle", "modalContent", "modalButtonLabel"],
  computed: {
    modalShow: {
      get(): boolean {
        return this.$store.getters["modalState"](this.modalName, true) === false
          ? false
          : !this.dashboardData
          ? true
          : this.dashboardData.has_energy_meter === true ||
            this.dashboardData.has_production_meter === true
          ? false
          : true;
      },
      set() {
        this.closeModal(this.modalName);
      },
    },
  },
  methods: {
    routeParentAction() {
      this.$emit("callParentAction");
    },
  },
});
