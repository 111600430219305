import Vue from "vue";
import Vuex, { StoreOptions, GetterTree, MutationTree, ActionTree } from "vuex";
import createMultiTabState from "vuex-multi-tab-state";
import customAxios from "@/axios-service";
import formRecord, { state } from "./form-record";
import participantGeneral from "./participant/general";
import monitorData from "./participant/monitor";
import formsData from "./participant/forms";
import activityData from "./participant/activity";
import activityData2 from "./participant/activity2";
import dashboardData from "./participant/dashboard";
import consumptionData from "./participant/consumption";
import horariosData from "./participant/horarios";
import qualityData from "./participant/quality";
import applianceGroupData from "./participant/applianceGroups";
import supportData from "./participant/support";

import router from "@/router";
import config from "@/config";

Vue.use(Vuex);

export type Nullable<T> = T | null;

export interface Token {
  access: string;
  refresh: string;
}

export interface Snackbar {
  show: boolean | false;
  message: string | "";
  timeout: number | 7000;
  color: string | "#263238";
}
export interface NameBooleanState {
  name: string;
  status: boolean;
}

export interface RootState {
  token: Token;
  snackbar: Snackbar;
  sidemenu_collapse: boolean;
  new_activity_message: boolean;
  button_state: NameBooleanState[];
  page_validate: boolean | false;
  privacy_cookie: NameBooleanState;
  google_cookie: NameBooleanState;
  modal_state: NameBooleanState[];
  loading_overlay: boolean;
}

const getters: GetterTree<RootState, any> = {
  fullToken: (state) => state.token,
  isAuthenticated: (state) => state.token != null,
  snackbar: (state) => state.snackbar,
  sidemenuCollapse: (state) => state.sidemenu_collapse,
  newActivityMessage: (state) => state.new_activity_message,
  buttonState: (state) => (param: string) =>
    state.button_state &&
    state.button_state.length > 0 &&
    state.button_state.find((el) => el.name == param) !== undefined
      ? state.button_state.find((el) => el.name === param)?.status
      : false,
  pageValidate: (state) => state.page_validate,
  privacyCookie: (state) =>
    state.privacy_cookie &&
    state.privacy_cookie.name &&
    state.privacy_cookie.name == "15-02-2022"
      ? state.privacy_cookie.status
      : true,
  googleCookie: (state) =>
    state.google_cookie &&
    state.google_cookie.name &&
    state.google_cookie.name == "15-02-2022"
      ? state.google_cookie.status
      : true,
  modalState:
    (state) =>
    (param = "", defaultStatus = false) =>
      state.modal_state &&
      state.modal_state.length > 0 &&
      state.modal_state.find((el) => el.name == param) !== undefined
        ? state.modal_state.find((el) => el.name === param)?.status
        : defaultStatus,
};

const mutations: MutationTree<RootState> = {
  updateFullToken: (state, token: Token) => {
    state.token = token;
    sessionStorage.setItem("full-token", JSON.stringify(state.token));
  },
  updateAccessToken: (state, access: string) => {
    state.token.access = access;
    sessionStorage.setItem("full-token", JSON.stringify(state.token));
  },
  updateSnackbar: (state, snackbar: Snackbar) => {
    state.snackbar = snackbar;
  },
  toggleSidemenuCollapse: (state, collapsed: boolean) => {
    state.sidemenu_collapse = collapsed;
  },
  toggleNewActivityMessage: (state, open: boolean) => {
    state.new_activity_message = open;
  },
  toggleButtonState: (state, value: any) => {
    if (!state.button_state || !value) {
      state.button_state = [];
    }
    if (value) {
      const index = state.button_state.length
        ? state.button_state.findIndex((el) => el.name == value.name)
        : -1;
      if (index !== -1) {
        state.button_state[index].status = value.status;
      } else {
        state.button_state.push(value);
      }
    }
    sessionStorage.setItem("button-state", JSON.stringify(state.button_state));
  },
  togglePageValidate: (state, value: boolean) => {
    state.page_validate = value;
  },
  togglePrivacyCookie: (state, value: boolean) => {
    state.privacy_cookie = { name: "15-02-2022", status: value };
    sessionStorage.setItem(
      "privacy-cookie",
      JSON.stringify(state.privacy_cookie)
    );
  },
  toggleGoogleCookie: (state, value: boolean) => {
    state.google_cookie = { name: "15-02-2022", status: value };
    sessionStorage.setItem(
      "google-cookie",
      JSON.stringify(state.google_cookie)
    );
  },
  toggleModalState: (state, value: any) => {
    if (!state.modal_state || !value) {
      state.modal_state = [];
    }
    if (value) {
      const index =
        state.modal_state.length > 0
          ? state.modal_state.findIndex((el) => el.name == value.name)
          : -1;
      if (index !== -1) {
        state.modal_state[index].status = value.status;
      } else {
        state.modal_state.push(value);
      }
    }
    sessionStorage.setItem("modal-state", JSON.stringify(state.modal_state));
  },
  updateState: (state, val: any) => {
    Object.assign(state, val);
  },
};

const actions: ActionTree<RootState, any> = {
  async refreshToken({ commit, getters }) {
    const refresh_token = (await getters.fullToken)
      ? getters.fullToken.refresh
      : null;
    if (refresh_token) {
      return customAxios
        .post("/api/token/refresh/", { refresh: refresh_token })
        .then((response) => {
          commit("updateAccessToken", response.data.access);
          return true;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            commit("updateFullToken", null);
          }
          return false;
        });
    } else {
      return false;
    }
  },
  async toggleSnackbar({ commit }, data) {
    return commit("updateSnackbar", {
      show: data && data.show ? data.show : false,
      message: data && data.message ? data.message : "",
      timeout: data && data.timeout ? data.timeout : 8000,
      color: data && data.color ? data.color : "#BF360C",
    });
  },
  async logout({ commit, dispatch }) {
    commit("updateFullToken", null);
    commit("toggleModalState", null);
    commit("toggleButtonState", null);
    commit("participantGeneral/updateProfileInfo", null);
    commit("dashboardData/updateDashboardInfo", null);
    commit("applianceGroupData/updateApplianceGroups", null);
    commit("consumptionData/updateConsumptionInfo", null);
    commit("consumptionData/updateProductionInfo", {
      consumption: null,
      production: null,
      injection: null,
    });
    commit("horariosData/updateHorariosInfo", null);
    commit("qualityData/updateQualityInfo", null);
    commit("monitorData/updateMonitorInfo", null);
    commit("activityData/resetActivityData");
    commit("activityData/resetActivityData2");

    // commit("participantGeneral/toggleNoContentModal", true);
    if (router.currentRoute.matched.some((r) => r.meta.requireAuth)) {
      router.replace({
        name: "Login",
        params: {
          wantedRoute: router.currentRoute.fullPath,
        },
      });
    }
  },
  async login({ commit, dispatch }, data) {
    return customAxios
      .post(`/api/token/`, data)
      .then(async (response) => {
        commit("updateFullToken", response.data);
        return router.push({ path: "/participant/dashboard" });
      })
      .catch(async (e) => {
        return dispatch("toggleSnackbar", {
          show: true,
          message:
            e.response && e.response.status == 401
              ? config.errorMessages.invalid_login
              : config.errorMessages.unknown_error,
        });
      });
  },
  async resetLoginPassword({ commit, dispatch }, data) {
    return customAxios
      .post(`/record/reset_password`, data)
      .then(async (response) => {
        return response.data;
      })
      .catch(async (e) => {
        return dispatch("toggleSnackbar", {
          show: true,
          message: config.errorMessages.unknown_error,
        });
      });
  },
  async formResponseError({ commit, getters, dispatch }, e: any) {
    let message = "";
    if (e.response && e.response.status !== undefined) {
      const isAuthenticated = await getters.isAuthenticated;
      if (
        e.response.status == 400 &&
        (e.response.data.success === false || e.response.data.message == "2") &&
        isAuthenticated
      ) {
        message = config.errorMessages.form_already_submitted;
      } else if (e.response.status == 500 && isAuthenticated) {
        message = config.errorMessages.unknown_error;
      }
    } else if (e.response === undefined) {
      const form_id = await getters["formRecord/id"];
      if (
        (form_id && router.currentRoute.path.includes("/standalone-form")) ||
        !router.currentRoute.path.includes("/standalone-form")
      ) {
        message = config.errorMessages.no_network;
      }
    }
    if (message != "") {
      return await dispatch("toggleSnackbar", {
        show: true,
        message: message,
      });
    } else {
      return await router.push({ path: "/*" });
    }
  },
};

const store: StoreOptions<RootState> = {
  strict: process.env.NODE_ENV !== "production",
  modules: {
    formRecord,
    participantGeneral,
    supportData,
    qualityData,
    monitorData,
    formsData,
    activityData,
    horariosData,
    activityData2,
    dashboardData,
    consumptionData,
    applianceGroupData,
  },
  state: {
    token: JSON.parse(sessionStorage.getItem("full-token") || "null") as Token,
    snackbar: {} as Snackbar,
    sidemenu_collapse: false,
    loading_overlay: false,
    button_state: JSON.parse(
      sessionStorage.getItem("button-state") || "[]"
    ) as NameBooleanState[],
    new_activity_message: true,
    page_validate: false,
    privacy_cookie: JSON.parse(
      sessionStorage.getItem("privacy-cookie") || "null"
    ) as NameBooleanState,
    google_cookie: JSON.parse(
      sessionStorage.getItem("google-cookie") || "null"
    ) as NameBooleanState,
    modal_state: JSON.parse(
      sessionStorage.getItem("modal-state") || "[]"
    ) as NameBooleanState[],
  },
  getters: getters,
  mutations: mutations,
  actions: actions,
  plugins: [
    createMultiTabState({
      key: "app.smartenergylab.pt",
      statesPaths: [
        "token",
        "privacy_cookie",
        "google_cookie",
        "modal_state",
        "button_state",
      ],
    }),
  ],
};

export default new Vuex.Store<RootState>(store);
