





















































































































import { ActivityMixin } from "@/mixins/activity-mixin";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";

import mixins from "vue-typed-mixins";
export default mixins(ActivityMixin).extend({
  name: "NavButtons",
  components: { ParticipantNoContentInfoCard },
  props: [
    "changeState_e",
    "changeState_ht",
    "check_btn",
    "page_size",
    "history",
    "getDeviceSubtitle",
    "getDeviceTitle",
    "getIconUrl",
    "deviceInfo",
  ],
  mounted() {
    this.checkButton();
  },
  computed: {
    pageHistory(): any {
      return this.sliceHistory();
    },
    select(): any {
      return this.selected;
    },
  },
  methods: {
    reset(): any {
      this.counter = 0;
      this.start = 0;
      this.selected = 0;
      this.checkButton();
    },
  },
});
