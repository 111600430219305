




























































































































import mixins from "vue-typed-mixins";
import { ChartDataStructure, GeneralMixin } from "@/mixins/general-mixin";

import config from "@/config";
import chartConfig from "@/config-chart";

import ChartCard from "@/components/charts/chart-card.vue";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";
import SolidButton from "@/components/general/solid-button.vue";
import ApplianceModal from "@/components/general/appliance-modal.vue";
import TabSelector from "@/components/general/tab-selector.vue";
import { DateTime } from "luxon";
import { ConsumptionData } from "@/store/participant/consumption";
import { ApplianceConsumption } from "@/components/charts/appliance-chart.vue";
import { ApplianceGroupData } from "@/store/participant/applianceGroups";

export default mixins(GeneralMixin).extend({
  name: "ParticipantEstatisticas",

  components: {
    ApplianceModal,
    ChartCard,
    ParticipantNoContentInfoCard,
    SolidButton,
    TabSelector,
  },

  computed: {
    consumptionData: {
      get(): ConsumptionData {
        return this.$store.getters["consumptionData/consumptionInfo"];
      },
    },
    applianceGroupData: {
      get(): ApplianceGroupData {
        return this.$store.getters["applianceGroupData/applianceGroups"];
      },
    },
  },

  data() {
    return {
      tabInfo: [
        {
          name: "Total de Participantes",
        },
        {
          name: "Os Meus Participantes",
        },
      ],
      isCitizenScientist: false,
      applianceChart: {} as any,
      // all available appliances
      applianceList: [] as ApplianceConsumption[],
      // IDs of active appliances
      activeApplianceList: [] as number[],
      currentSelectedYear: 0,
      currentSelectedWeek: "",
      activeDays: [] as number[],
      activeMonths: [] as number[],
      isWeekdayActive: true,
      isMonthActive: true,
      consumptionChart1: {} as ChartDataStructure,
      consumptionTimes1: [
        {
          title: "Período de Tempo",
          subtitle: "Dia anterior",
          dates: [] as Date[],
        },
      ],
      consumptionChart2: {} as ChartDataStructure,
      consumptionChart3: {} as ChartDataStructure,
      consumptionTimes2: [
        {
          title: "Período de Tempo",
          subtitle: "Inicio",
          dates: [new Date()],
        },
        {
          subtitle: "Fim",
          dates: [new Date()],
        },
      ],
      consumptionChart4: {} as ChartDataStructure,
      consumptionChart5: {} as ChartDataStructure,
      consumptionTimes3: [
        {
          title: "Período de Tempo",
          subtitle: "Inicio",
          dates: [new Date()],
        },
        {
          subtitle: "Fim",
          dates: [new Date()],
        },
      ],
    };
  },

  // initialize the charts with the computed method to add the static info such as titles or chart properties.
  created() {
    this.initializeApplianceData();
    this.initializeChartData();
  },

  methods: {
    displayApplianceModal(): void {
      this.$store.commit("toggleModalState", {
        name: "appliance",
        status: true,
      });
    },
    initializeChartData() {
      this.consumptionChart1 = JSON.parse(
        JSON.stringify(chartConfig.dailyLineChartData())
      );
      this.consumptionChart2 = JSON.parse(
        JSON.stringify(chartConfig.weeklyLineChartData())
      );
      this.consumptionChart3 = JSON.parse(
        JSON.stringify(chartConfig.weeklyBarChartData())
      );
      this.consumptionChart4 = JSON.parse(
        JSON.stringify(chartConfig.anualLineChartData())
      );
      this.consumptionChart5 = JSON.parse(
        JSON.stringify(chartConfig.anualBarChartData())
      );
      this.retrieveConsumptionData();
    },
    initializeApplianceData() {
      this.applianceChart = chartConfig.applianceChartData();

      this.retrieveApplianceGroups();
    },
    // This method is called on the submit action in the appliance form
    // It's responsible to update the current active list & reset every chart to match the data
    updateApplianceList(appliances: number[]) {
      this.activeApplianceList = [];
      this.activeApplianceList.push(...appliances);
      this.applianceChart.chartAppliances = JSON.parse(
        JSON.stringify(
          this.applianceList.filter((x) => appliances.includes(x.id))
        )
      );
      this.applianceChart.chartPagination = 0;
      // TODO recall endpoint to update
      // this.initializeChartData();
      this.consumptionChart1 = JSON.parse(
        JSON.stringify(chartConfig.dailyLineChartData())
      );
      this.retrieveDailyData();
    },

    async updateSelectedWeek(week: string) {
      if (!week || week === this.currentSelectedWeek) {
        return;
      }
      this.$store.commit("updateState", { loading_overlay: true });
      this.currentSelectedWeek = week;
      this.isWeekdayActive = false;
      await this.$store.dispatch("consumptionData/getConsumptionInfo", {
        start_date: week,
        uri: "consumption_energy",
      });
      this.activeDays = [];
      this.consumptionChart2 = JSON.parse(
        JSON.stringify(chartConfig.weeklyLineChartData())
      );
      this.consumptionChart3 = JSON.parse(
        JSON.stringify(chartConfig.weeklyBarChartData())
      );
      this.retrieveWeeklyData();
      this.retrieveWeeklyBarData();
      this.isWeekdayActive = true;
      this.$store.commit("updateState", { loading_overlay: false });
    },

    async updateSelectedYear(year: number) {
      if (!year || year === this.currentSelectedYear) {
        return;
      }
      this.$store.commit("updateState", { loading_overlay: true });
      this.currentSelectedYear = year;
      this.isMonthActive = false;
      await this.$store.dispatch("consumptionData/getConsumptionInfo", {
        uri: "consumption_energy",
        year: year,
      });
      this.activeMonths = [];
      this.consumptionChart4 = JSON.parse(
        JSON.stringify(chartConfig.anualLineChartData())
      );
      this.consumptionChart5 = JSON.parse(
        JSON.stringify(chartConfig.anualBarChartData())
      );
      this.retrieveAnualData();
      this.retrieveAnualBarData();
      this.isMonthActive = true;
      this.$store.commit("updateState", { loading_overlay: false });
    },

    updateActiveWeekDays(date: number[]) {
      const chartInfo = this.consumptionChart2.chartInfo;
      const data = this.consumptionData.week_before.mains;

      if (chartInfo && chartInfo.labels) {
        date.forEach((day) => {
          if (!this.activeDays.includes(day) && chartInfo.labels) {
            if (!data.profile[day]) {
              this.$store.dispatch("toggleSnackbar", {
                show: true,
                message: "Seleção sem dados disponíveis",
                color: "blue",
              });
              this.activeDays.push(day);
              return;
            }
            chartInfo.labels.push(config.daysWeekPT[day]);
            chartInfo.colors.push({ border: config.colorsDaysWeekPT[day] });
            chartInfo.data.push(data.profile[day]);
            this.activeDays.push(day);
          }
        });
        this.activeDays.forEach((day, index) => {
          if (!date.includes(day) && chartInfo.labels) {
            let pos = chartInfo.labels.indexOf(config.daysWeekPT[day]);
            if (pos !== -1) {
              chartInfo.labels.splice(pos, 1);
              chartInfo.colors.splice(pos, 1);
              chartInfo.data.splice(pos, 1);
            }
            this.activeDays.splice(index, 1);
          }
        });
      }
    },

    updateSelectedMonth(date: number[]) {
      const chartInfo = this.consumptionChart4.chartInfo;
      const data = this.consumptionData.annual.mains;

      if (chartInfo && chartInfo.labels) {
        date.forEach((month) => {
          if (!this.activeMonths.includes(month) && chartInfo.labels) {
            if (!data.profile[month]) {
              this.$store.dispatch("toggleSnackbar", {
                show: true,
                message: "Seleção sem dados disponíveis",
                color: "blue",
              });
              this.activeMonths.push(month);
              return;
            }
            chartInfo.labels.push(config.monthsPT[month]);
            chartInfo.colors.push({ border: config.colorsMonthsPT[month] });
            chartInfo.data.push(data.profile[month]);
            this.activeMonths.push(month);
          }
        });
        this.activeMonths.forEach((month, index) => {
          if (!date.includes(month) && chartInfo.labels) {
            let pos = chartInfo.labels.indexOf(config.monthsPT[month]);
            if (pos !== -1) {
              chartInfo.labels.splice(pos, 1);
              chartInfo.colors.splice(pos, 1);
              chartInfo.data.splice(pos, 1);
            }
            this.activeMonths.splice(index, 1);
          }
        });
      }
    },

    // TODO update this when endpoint exists
    async retrieveConsumptionData() {
      this.$store.commit("updateState", { loading_overlay: true });
      await this.$store.dispatch("consumptionData/getConsumptionInfo", {
        uri: "consumption_energy",

        params: "",
      });
      if (this.consumptionData && this.consumptionData.day_before) {
        // This is to avoid having to needlessly update everything on demand
        if (!this.currentSelectedYear && !this.currentSelectedWeek) {
          this.retrieveDailyData();
          this.retrieveApplianceData();
          this.retrieveWeeklyData();
          this.retrieveWeeklyBarData();
          this.retrieveAnualData();
          this.retrieveAnualBarData();
        }
      }
      this.$store.commit("updateState", { loading_overlay: false });
    },
    async retrieveApplianceGroups() {
      await this.$store.dispatch("applianceGroupData/getApplianceGroups");
      if (this.applianceGroupData && this.applianceGroupData.sensors) {
        this.applianceList = JSON.parse(
          JSON.stringify(this.applianceGroupData.sensors)
        );
        if (this.applianceGroupData.active && this.applianceGroupData.groups) {
          this.activeApplianceList = JSON.parse(
            JSON.stringify(
              this.applianceGroupData.groups[this.applianceGroupData.active]
                .sensors
            )
          );
        }
      }
    },
    retrieveApplianceData(): void {
      const applianceData =
        this.consumptionData.day_before.appliance_consumption_24_hours;
      if (applianceData && applianceData.length) {
        this.applianceChart.chartAppliances = JSON.parse(
          JSON.stringify(applianceData)
        );
        this.applianceChart.chartAppliances.forEach((appliance: any) => {
          appliance.profile.map((obj: any) => {
            if (obj.y === null) {
              obj.y = 0;
            }
          });
        });
      } else {
        this.applianceChart.disabled = true;
      }
    },
    retrieveDailyData(): void {
      const data = this.consumptionData.day_before.energy_consumption_24_hours;
      if (data) {
        this.consumptionTimes1[0].dates = [
          new Date(DateTime.now().minus({ days: 1 }).toISODate()),
        ];

        const startMilliseconds = DateTime.now().startOf("day").toMillis();

        this.consumptionChart1.chartInfo.rangeValues.min = startMilliseconds;
        // max is the 24h (in milliseconds) range since the starting time
        this.consumptionChart1.chartInfo.rangeValues.max =
          startMilliseconds + 82800000;

        this.consumptionChart1.chartInfo.data = [data.profile];
        this.consumptionChart1.chartTotal = data.total;
        this.consumptionChart1.isVisible = true;
      }
    },
    retrieveWeeklyData(): void {
      const data = this.consumptionData.week_before.mains;
      if (data) {
        if (data.start && data.end) {
          this.consumptionTimes2[0].dates = [new Date(data.start)];
          this.consumptionTimes2[1].dates = [new Date(data.end)];
        }
        const startMilliseconds = DateTime.now().startOf("day").toMillis();

        this.consumptionChart2.chartInfo.rangeValues.min = startMilliseconds;
        // max is the 24h (in milliseconds) range since the starting time
        this.consumptionChart2.chartInfo.rangeValues.max =
          startMilliseconds + 82800000;
        this.consumptionChart2.chartInfo.data = [data.profile["-1"]];
        this.consumptionChart2.chartTotal = data.total;

        this.consumptionChart2.isVisible = true;
      }
    },
    retrieveWeeklyBarData(): void {
      const data = this.consumptionData.week_before.appliances;

      if (data) {
        this.consumptionChart3.chartInfo.labels = [];
        this.consumptionChart3.chartInfo.colors = [];
        this.consumptionChart3.chartInfo.data = [];

        if (
          this.consumptionData.week_before.mains.start &&
          this.consumptionData.week_before.mains.end
        ) {
          this.consumptionChart3.chartInfo.rangeValues.min = new Date(
            this.consumptionData.week_before.mains.start
          ).getTime();
          this.consumptionChart3.chartInfo.rangeValues.max = new Date(
            this.consumptionData.week_before.mains.end
          ).getTime();
        }

        data.forEach((app) => {
          if (app.profile && app.profile.length) {
            // it's literaly defined at the top ...
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.consumptionChart3.chartInfo.labels.push(app.device_type_name);
            let curColor = app.color;
            if (!curColor) {
              curColor = "#a0a0a0";
            }
            this.consumptionChart3.chartInfo.colors.push({
              border: curColor,
            });
            this.consumptionChart3.chartInfo.data.push(app.profile);
          }
        });

        this.consumptionChart3.isVisible = true;
      }
    },
    retrieveAnualData(): void {
      const data = this.consumptionData.annual.mains;
      if (data) {
        if (data.start && data.end) {
          this.consumptionTimes3[0].dates = [new Date(data.start)];
          this.consumptionTimes3[1].dates = [new Date(data.end)];
        }
        const startMilliseconds = DateTime.now().startOf("day").toMillis();

        this.consumptionChart4.chartInfo.rangeValues.min = startMilliseconds;
        // max is the 24h (in milliseconds) range since the starting time
        this.consumptionChart4.chartInfo.rangeValues.max =
          startMilliseconds + 82800000;
        this.consumptionChart4.chartInfo.data = [data.profile["0"]];
        this.consumptionChart4.chartTotal = data.total;

        this.consumptionChart4.isVisible = true;
      }
    },
    retrieveAnualBarData(): void {
      const data = this.consumptionData.annual.appliances;

      if (data) {
        this.consumptionChart5.chartInfo.labels = [];
        this.consumptionChart5.chartInfo.colors = [];
        this.consumptionChart5.chartInfo.data = [];

        if (
          this.consumptionData.annual.mains.start &&
          this.consumptionData.annual.mains.end
        ) {
          this.consumptionChart5.chartInfo.rangeValues.min = new Date(
            this.consumptionData.annual.mains.start
          ).getTime();
          this.consumptionChart5.chartInfo.rangeValues.max = new Date(
            this.consumptionData.annual.mains.end
          ).getTime();
        }

        data.forEach((app) => {
          if (app.profile && app.profile.length) {
            // it's literaly defined at the top ...
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.consumptionChart5.chartInfo.labels.push(app.device_type_name);
            let curColor = app.color;
            if (!curColor) {
              curColor = "#a0a0a0";
            }
            this.consumptionChart5.chartInfo.colors.push({
              border: curColor,
            });
            this.consumptionChart5.chartInfo.data.push(app.profile);
          }
        });

        this.consumptionChart5.isVisible = true;
      }
    },
  },
});
