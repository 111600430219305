









































































import Vue from "vue";
import FooterGeneral from "@/components/general/footer-general.vue";
import Snackbar from "@/components/general/snackbar.vue";
import FormTitle from "@/components/form/form-title.vue";
import FooterGeneralNoBg from "@/components/general/footer-general-no-bg.vue";
import FormBody from "@/components/form/form-body.vue";

export default Vue.extend({
  name: "StandAloneForm",
  components: {
    FormTitle,
    Snackbar,
    FooterGeneral,
    FooterGeneralNoBg,
    FormBody,
  },
  data() {
    return {
      pageCounter: 0,
      formData: null,
      formLink: "",
    };
  },
  beforeCreate() {
    window.scrollTo(0, 0);
  },
  computed: {
    title: {
      get(): string {
        return this.$store.getters["formRecord/title"];
      },
    },
    logoState: {
      get(): string {
        return this.$store.getters["formRecord/logoState"];
      },
    },
    logoImg: {
      get(): string {
        return this.$store.getters["formRecord/logoImg"];
      },
    },
    backgroundImage: {
      get(): string {
        return this.$store.getters["formRecord/backgroundImage"];
      },
    },
    backgroundStyle: {
      get(): string {
        return this.$store.getters["formRecord/backgroundStyle"];
      },
    },
  },
});
