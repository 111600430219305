var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-0 scroll-container full-scroll",class:{
    'form-back-sel-gradient-green': _vm.backgroundStyle == 'SEL Gradient Green',
    'form-back-sel-gradient': _vm.backgroundStyle == 'SEL Gradient',
    'form-back-white': _vm.backgroundStyle == 'White',
    'form-back-ash': _vm.backgroundStyle == 'Ash',
  },attrs:{"fluid":"","fill-height":""}},[(_vm.backgroundImage && _vm.backgroundImage != '')?_c('v-row',[_c('v-col',{staticClass:"px-0 py-0",attrs:{"cols":"12"}},[_c('img',{staticClass:"top-banner",attrs:{"src":_vm.backgroundImage}})]),_c('v-col',{staticClass:"px-0 py-0 liner custom-liner-top",attrs:{"cols":"12"}})],1):_vm._e(),_c('div',{staticClass:"col-12 form-page",class:{
      'form-page-with-bg': _vm.backgroundImage && _vm.backgroundImage != '',
      'form-page-no-bg': !_vm.backgroundImage,
    }},[(_vm.logoState && _vm.logoState.toLowerCase() === 'default')?_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('img',{staticClass:"logo-image",attrs:{"src":_vm.logoImg
              ? _vm.logoImg
              : require('@/assets/logos/livinglab-logo-dark-bg.svg')}})])],1):_vm._e(),(_vm.title && _vm.title != '')?_c('form-title',{attrs:{"formTitle":_vm.title}}):_vm._e(),_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"col-xs-10 col-sm-8 col-md-6 d-flex justify-center"},[_c('v-card',{staticClass:"col-12 rounded-xl"},[_c('form-body')],1)],1)],1)],1),(_vm.backgroundImage && _vm.backgroundImage != '')?_c('v-row',{staticClass:"footer"},[_c('v-col',{staticClass:"px-0 py-0 liner custom-liner-bottom",attrs:{"cols":"12"}}),_c('v-col',{staticClass:"footer-general",attrs:{"cols":"12"}},[_c('footer-general')],1)],1):_vm._e(),(!_vm.backgroundImage)?_c('v-row',{staticClass:"footer footer-no-bg"},[_c('footer-general-no-bg')],1):_vm._e(),_c('snackbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }