












































import Vue from "vue";
import ExternalModal from "@/components/general/external-modal.vue";

export default Vue.extend({
  name: "SlackCard",
  components: {
    ExternalModal,
  },

  methods: {
    displayExternalLinkModal(status: boolean): void {
      this.$store.commit("toggleModalState", {
        status: status,
        name: "external-modal-warning",
      });
    },
  },
});
