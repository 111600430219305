






























































































































































import config from "@/config";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import SolidButton from "@/components/general/solid-button.vue";
import FormModal from "@/components/general/form-modal.vue";
import ParticipantSupportSuccessModal from "../participant-support-success-modal.vue";
import ProjectDetailsModal from "@/components/general/project-details-modal.vue";
import ParticipantNoContentInfoCard from "../participant-no-content-info-card.vue";
import { TaskInfo } from "./active-proj.vue";

export default mixins(GeneralMixin).extend({
  name: "AvailableProjects",
  components: {
    SolidButton,
    FormModal,
    ParticipantSupportSuccessModal,
    ProjectDetailsModal,
    ParticipantNoContentInfoCard,
  },
  props: {
    tasks: {
      required: false,
    },
    secondaryStyle: {
      required: false,
      type: Boolean,
      default: false,
    },
    isGeneral: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onLaunchTask(task: any): void {
      const { id, title, is_new, tasks } = task ?? {};
      const { link_id } = tasks?.[0]?.activity_form;

      if (!id || !title || !link_id) return;

      this.selectedModal = task;
      this.link_id = link_id;
      this.id = id;
      this.project = task;
      this.project_modal = `project-details-${id}`;
      this.openModal(this.project_modal);

      if (is_new) {
        this.newProjectLength = this.newProjectLength - 1;
        this.$store.dispatch("activityData2/setSeenProject", id);
      }
    },
    getTaskDuration(task: TaskInfo): string | number {
      if (task?.duration === 0 || !task?.duration) {
        return "N/A";
      }

      return this.getFormattedProjectDuration(task?.duration);
    },
  },
  computed: {
    taskCompletedContent: {
      get(): any {
        return this.isGeneral
          ? config.activityTaskCompleted.general
          : config.activityTaskCompleted.specific;
      },
    },
    newProjectLength: {
      get(): any {
        return this.$store.getters["activityData2/generalUpdate"](2);
      },

      set(): any {
        this.$store.commit("activityData2/updateRemoveProject", 1);
      },
    },
  },

  data() {
    return {
      link_id: "",
      id: -1,
      successModal: "c-task-",
      taskCompleted: "modal-completed-task-",
      selectedModal: null,
      project: null,
      project_modal: "",
    };
  },
});
