import { render, staticRenderFns } from "./participant-no-content-info-card.vue?vue&type=template&id=79be8056&scoped=true&"
import script from "./participant-no-content-info-card.vue?vue&type=script&lang=ts&"
export * from "./participant-no-content-info-card.vue?vue&type=script&lang=ts&"
import style0 from "./participant-no-content-info-card.vue?vue&type=style&index=0&id=79be8056&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79be8056",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VRow})
