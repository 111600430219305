





















































































































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import solidButton from "./solid-button.vue";

export default mixins(GeneralMixin).extend({
  components: { solidButton },
  name: "GasConsumptionModal",
  props: [
    "modalName",
    "modalTitle",
    "modalText",
    "textGas",
    "idProject",
    "isOnCourse",
    "isGeneral",
  ],
  data() {
    return {
      checked: false,
      hasScrolledToBottom: false,
    };
  },

  methods: {
    checkCorrect() {
      this.checked = !this.checked;
    },

    handleSubmit() {
      this.$store.dispatch("activityData2/setActiveProj", this.idProject);
      this.closeModal(this.modalName);
    },
    handleScroll: function (el: any) {
      if (
        el.srcElement.offsetHeight + el.srcElement.scrollTop >=
        el.srcElement.scrollHeight - 1
      ) {
        this.hasScrolledToBottom = true;
      }
    },
  },
  computed: {
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        this.closeModal(this.modalName);
      },
    },
  },
});
