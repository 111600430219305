





















































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
export default mixins(GeneralMixin).extend({
  name: "ProjectInterfaceModal",
  props: [
    "modalName",
    "modalTitle",
    "modalText",
    "image",
    "okText",
    "cancelText",
    "color",
    "alternate",
    "callOkAction",
    "callCancelAction",
  ],

  methods: {
    okCallback(): void {
      this.$emit("callOkAction");
    },
    cancelCallback(): void {
      this.$emit("callCancelAction");
    },
  },
  computed: {
    text(): any {
      return this.modalText.replace("Projetos", "<b>" + "Projetos" + "</b>");
    },
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        this.closeModal(this.modalName);
      },
    },
  },
});
