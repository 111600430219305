





































import Vue from "vue";

export default Vue.extend({
  name: "NoticeTooltip",
  data() {
    return {
      trigger: undefined,
    };
  },
});
