












import Vue from "vue";

export default Vue.extend({
  name: "FormSnackbar",
  computed: {
    snackbar: function (): any {
      return this.$store.getters.snackbar;
    },
  },
  watch: {
    snackbar: function (value: any) {
      if (value && value.show) {
        setTimeout(() => {
          this.$store.dispatch("toggleSnackbar", null);
        }, this.snackbar.timeout - 2000);
      }
    },
  },
});
