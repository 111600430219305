import Vue from "vue";

const ActivityMixin = Vue.extend({
    data() {
        return {
            next_disabled: false,
            prev_disabled: false,
            start: 0,
            counter: 0,
            selected: 0
        };
    },
    props: ["history", "page_size"],
    computed: {
        activitySelector: {
            get(): any {
                return this.$store.getters["activityData/activitySelector"];
            },
        },
    },
    methods: {
        stateInfo(state: number): string {
            if (state === 0) {
                return "offline";
            }
            if (state === 1) {
                return "operational";
            }
            return "semi-operational";
        },
        expandActivityProject(new_index: number, cur_index: number) {
            let index = -1
            if (new_index != cur_index) {
                index = new_index
            }
            this.$store.commit("activityData/updateActivitySelector", index)
        },
        next() {
            this.start =
                this.history.length > this.counter
                    ? this.start + this.page_size
                    : this.start;
            this.counter =
                this.start > this.history.length ? this.history.length : this.start;
            this.selected++
            this.sliceHistory();
        },
        prev() {
            if (this.start - this.page_size <= 0) {
                this.start = 0;
                this.counter = 0;
            } else {
                this.counter = this.start - this.page_size;
                this.start = this.start - this.page_size
            }
            this.selected--;
            this.sliceHistory();
        },
        sliceHistory(): void {
            this.checkButton();
            let fval: any = [];
            fval = Object.assign([], this.history);
            return fval.splice(this.start, this.page_size);
        },
        checkButton(): void {
            this.prev_disabled = this.counter == 0 ? true : false;
            this.next_disabled =
                this.history ? (this.counter + this.page_size >= this.history.length ? true : false) : false;
        },
    },
})

export { ActivityMixin }
export default {}
