









import Vue from "vue";
import CookieSnackbar from "./components/general/cookie-snackbar.vue";
import { Settings } from "luxon";

Settings.defaultLocale = "pt-PT";
Settings.defaultZone = "UTC";

export default Vue.extend({
  components: { CookieSnackbar },
  name: "App",

  data: () => ({
    // x
  }),
});
