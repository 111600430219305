












































































































import Vue from "vue";

export default Vue.extend({
  name: "ChartRoomAverage",
  props: {
    roomInfo: {
      required: true,
      type: Object as () => {
        name: string;
        icon: string;
        color: string;
        temperature: number;
        humidity: number;
        type: string;
        min: {
          date: string;
          value: number;
        };
        max: {
          date: string;
          value: number;
        };
      },
    },
  },
});
