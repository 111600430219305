import { render, staticRenderFns } from "./notice-bar.vue?vue&type=template&id=5bcc176f&scoped=true&"
import script from "./notice-bar.vue?vue&type=script&lang=ts&"
export * from "./notice-bar.vue?vue&type=script&lang=ts&"
import style0 from "./notice-bar.vue?vue&type=style&index=0&id=5bcc176f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bcc176f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
