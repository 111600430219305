import { Module, GetterTree, MutationTree, ActionTree, Mutation } from "vuex";
import store, { Nullable, RootState } from "../../store/index";
import customAxios from "@/axios-service";
import config from "@/config";

export interface HorariosData {
  grouped_day_before: {
    profile: {
      id: number;
      sensor: string;
      device_type_id: number;
      device_type_name: string;
      color?: string;
      energy: number;
      profile: number[];
    }[];
    date: string;
    total: number;
  };
  group_week_before: {
    start_date: string;
    end_date: string;
    profile: {
      full: number[];
      weekdays: number[];
      weekend: number[];
    };
    total: number;
  };
}

export interface HorariosInfo {
  horarios_info: Nullable<HorariosData>;
}

export const state: HorariosInfo = {
  horarios_info: JSON.parse(sessionStorage.getItem("horarios-info") || "null"),
};

function hexToRgb(hex: string, opacity = 1): string {
  //slice the # from hex string
  const bigint = parseInt(hex.slice(1, hex.length), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r},${g},${b},${opacity})`;
}

const getters: GetterTree<HorariosInfo, RootState> = {
  horariosInfo: (state) => {
    return state.horarios_info;
  },
};

const mutations: MutationTree<HorariosInfo> = {
  updateHorariosInfo: (state, value: HorariosData) => {
    state.horarios_info = value;
    sessionStorage.setItem(
      "horarios-info",
      JSON.stringify(state.horarios_info)
    );
  },
};

const actions: ActionTree<HorariosInfo, RootState> = {
  async getHorariosInfo({ commit }, params) {
    const hexRgb = hexToRgb;
    const applianceConfig = config.applianceInfo;

    return customAxios
      .get(`/api/grouped_hour_consumption`, { params })
      .then((response) => {
        if (response.data.grouped_day_before) {
          const applianceList = response.data.grouped_day_before.profile;
          const counter = {} as any;
          const updateNames = {} as any;
          applianceList.forEach((appliance: any) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const curColor = applianceConfig[appliance.device_type_id]?.color;
            // Maybe change the default color this turns into ?
            appliance.color = curColor ? curColor : "#a0a0a0";
            if (counter[appliance.device_type_id]) {
              counter[appliance.device_type_id] =
                counter[appliance.device_type_id] + 1;
              const baseName = appliance.device_type_name;
              const friendlyName = appliance.friendly_name;
              appliance.device_type_name = friendlyName
                ? `${baseName} - ${friendlyName}`
                : `${baseName} - ${counter[appliance.device_type_id]}`;
              appliance.color = hexRgb(
                curColor,
                1 - 0.3 * (counter[appliance.device_type_id] - 1)
              );
              updateNames[appliance.id] = {
                name: appliance.device_type_name,
                color: appliance.color,
              };
            } else {
              counter[appliance.device_type_id] = 1;
            }
          });
          // this is to fix first cases which were skipped due to counting on top
          // this also included the fix to always display type "Others" with the friendly name in case it has
          // last fix happens only when that type is singular as repeated events fix this with default behaviour
          applianceList.forEach((appliance: any) => {
            if (
              (counter[appliance.device_type_id] > 1 &&
                appliance.color[0] === "#") ||
              (counter[appliance.device_type_id] === 1 &&
                appliance.device_type_id === 12)
            ) {
              const baseName = appliance.device_type_name;
              const friendlyName = appliance.friendly_name;
              appliance.device_type_name = friendlyName
                ? `${baseName} - ${friendlyName}`
                : `${baseName} - 1`;
              updateNames[appliance.id] = {
                name: appliance.device_type_name,
                color: appliance.color,
              };
            }
          });
        }
        return commit("updateHorariosInfo", response.data);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
};

const namespaced = true;

const horariosData: Module<HorariosInfo, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default horariosData;
