































































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import CustomDatePicker from "./custom-date-picker.vue";
import SolidButton from "./general/solid-button.vue";

export default mixins(GeneralMixin).extend({
  name: "DateModal",
  props: ["modalName", "device", "selectedItemVal"],
  components: {
    CustomDatePicker,
    SolidButton,
  },
  computed: {
    selectGroup: {
      get(): number {
        return this.selectedItemVal;
      },
      set(val: number): void {
        this.$emit("selectUpdate", { itemVal: val.toString() });
      },
    },
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        this.closeModal(this.modalName);
      },
    },
  },
});
