








































































































































































































import moment from "moment";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import ParticipantSupportSuccessModal from "./participant/participant-support-success-modal.vue";

export default mixins(GeneralMixin).extend({
  components: { ParticipantSupportSuccessModal },
  name: "CustomDatePicker",
  props: ["modalName", "device", "type"],
  data() {
    return {
      primaryColor: "#3bbbc8",
      weekDay: ["dom", "seg", "ter", "qua", "qui", "sex", "sab"],

      billingPeriodStartDate: moment(),
      //   billingPeriodEndDate: moment().add(1, "M"),
      selectedStartDate: null,
      selectedEndDate: null,
      tempStartDate: null,
      tempEndDate: null,
      customSelectedDate: "",
      dataRange: {} as any,
    };
  },
  computed: {
    firstMonthDays(): any {
      return this.daysConstructor().filter((day: any) => {
        return day.firstMonth == true;
      });
    },
    secondMonthDays(): any {
      return this.daysConstructor().filter((day: any) => {
        return day.firstMonth == false;
      });
    },
  },
  methods: {
    async setSelectedPeriod(
      startdate: any,
      enddate: any,
      type: any,
      device_state: any
    ): Promise<void> {
      let device_class = type != null ? type.toString() : null;
      let device = device_state ? device_state.toString() : null;
      let start_date = startdate.format("YYYY-MM-DD");
      let end_date = enddate.format("YYYY-MM-DD");
      this.closeModal(this.modalName);
      this.$store.commit("updateState", { loading_overlay: true });
      return await this.$store
        .dispatch("monitorData/sendEquipInfo", {
          device_class: device_class,
          start_date: start_date,
          end_date: end_date,
          device: device,
        })
        .then(async (res) => {
          this.$store.commit("updateState", { loading_overlay: false });
          if (res) {
            this.openModal("send-data-equipments");
          } else {
            await this.$store.dispatch("toggleSnackbar", {
              show: true,
              message: this.error_message.unknown_error,
            });
          }
        });
    },
    daysConstructor(): any {
      let days = [];
      let day_month = this.billingPeriodStartDate.clone();
      day_month = day_month.startOf("month");
      for (let i = 0; i <= 62; i++) {
        const now_day_month = moment(day_month);
        const properties = {
          disabled: this.isDisabled(now_day_month),
          active: this.isActiveDay(now_day_month),
          firstDay: this.isCheckData(now_day_month, "first day"),
          firstMonth: this.isCheckData(now_day_month, "first month"),
          lastDay: this.isCheckData(now_day_month, "last day"),
          activeRangeStart_toggle: this.isActiveRangeDay(now_day_month, true),
          activeRangeStart:
            this.isActiveRangeDay(now_day_month, true) &&
            this.isWithinActiveRange(now_day_month),
          activeRangeEnd: this.isActiveRangeDay(now_day_month, false),
          withinActiveRange: this.isWithinActiveRange(now_day_month),
          isNotWithinActiveRange: this.isNotWithinActiveRange(now_day_month),
          today:
            now_day_month.format("MM-DD-YYYY") ===
            moment().format("MM-DD-YYYY"),
        };
        const dayObj = Object.assign(now_day_month, properties);
        days.push(dayObj);
        day_month.add(1, "days");
      }
      const effectiveDays = days.filter((day: any) => {
        return !day.disabled;
      });
      return effectiveDays;
    },
    isActiveDay(now_day_month: any): any {
      return (
        now_day_month.format("MM-DD-YYYY") === moment().format("MM-DD-YYYY")
      );
    },
    isActiveRangeDay(dayMoment: any, isStart: any) {
      const formattedDate = dayMoment.format("MM-DD-YYYY"),
        formattedDateStart = moment(this.selectedStartDate).format(
          "MM-DD-YYYY"
        ),
        formattedDateEnd = moment(this.selectedEndDate).format("MM-DD-YYYY");
      const eqSelectedDateStart = formattedDate === formattedDateStart,
        eqSelectedDateEnd = formattedDate === formattedDateEnd;
      return isStart ? eqSelectedDateStart : eqSelectedDateEnd;
    },

    isWithinActiveRange(dayMoment: any) {
      return (
        dayMoment.diff(this.selectedStartDate, "days") >= 0 &&
        dayMoment.diff(this.selectedEndDate, "days") <= 0
      );
    },
    isNotWithinActiveRange(dayMoment: any) {
      return (
        dayMoment.diff(this.selectedStartDate, "days") < 0 ||
        dayMoment.diff(this.selectedEndDate, "days") > 0
      );
    },
    isDisabled(dayMoment: any) {
      const notNextMonth =
        dayMoment.month() - 1 !== this.billingPeriodStartDate.month();
      const notNextMonth1 =
        dayMoment.month() + 11 !== this.billingPeriodStartDate.month();
      const notCurrentMonth =
        dayMoment.month() !== this.billingPeriodStartDate.month();
      return notCurrentMonth && notNextMonth && notNextMonth1;
    },
    isCheckData(moment_date: any, check_type = "first month") {
      switch (check_type) {
        case "first month":
          return moment_date.month() === this.billingPeriodStartDate.month();
        case "first day":
          return parseInt(moment_date.date()) === 1;
        case "last day":
          return (
            moment_date.format("DD") ===
            moment_date.clone().endOf("month").format("DD")
          );
      }
    },
    calcDayClass(day: any) {
      return {
        "last-seg": day.lastDay && this.weekDay[day.day()] === "seg",
        "last-ter": day.lastDay && this.weekDay[day.day()] === "ter",
        "last-qua": day.lastDay && this.weekDay[day.day()] === "qua",
        "last-qui": day.lastDay && this.weekDay[day.day()] === "qui",
        "last-sex": day.lastDay && this.weekDay[day.day()] === "sex",
        "last-sab": day.lastDay && this.weekDay[day.day()] === "sab",
        "last-dom": day.lastDay && this.weekDay[day.day()] === "dom",
        seg: day.firstDay && this.weekDay[day.day()] === "seg",
        ter: day.firstDay && this.weekDay[day.day()] === "ter",
        qua: day.firstDay && this.weekDay[day.day()] === "qua",
        qui: day.firstDay && this.weekDay[day.day()] === "qui",
        sex: day.firstDay && this.weekDay[day.day()] === "sex",
        sab: day.firstDay && this.weekDay[day.day()] === "sab",
        dom: day.firstDay && this.weekDay[day.day()] === "dom",
        "active-range": day.activeRangeStart_toggle,
        "active-range -start":
          day.activeRangeStart &&
          !moment(this.selectedStartDate).isSame(this.selectedEndDate),
        "active-range -end":
          day.activeRangeEnd &&
          !moment(this.selectedStartDate).isSame(this.selectedEndDate),
        "active-range -def": day.resetActive,
        "within-active-range": day.withinActiveRange,
        "not-within-active-range": day.isNotWithinActiveRange,
      };
    },
    changeBillingPeriod(change_type: string) {
      let num =
        change_type == "next"
          ? this.selected_index - 1
          : this.selected_index + 1;

      this.billingPeriodStartDate = moment(this.billingPeriodStartDate).add(
        num,
        "months"
      );
    },
    selectDateRange(day: any) {
      if (day.disabled) {
        return;
      }

      const dayDiff = day.diff(moment(this.selectedStartDate), "days");
      if (dayDiff > 186) {
        this.$store.dispatch("toggleSnackbar", {
          show: true,
          message: this.error_message.invalid_data_range,
        });
      }
      if (!this.selectedEndDate && dayDiff <= 186) {
        this.selectedEndDate = day;
      } else {
        this.selectedStartDate = day;
        this.selectedEndDate = null;
      }
      const start = this.selectedStartDate,
        end = this.selectedEndDate,
        range = { start: start, end: end };

      if (range.end != null) {
        this.dataRange = range;

        if (this.dataRange.end < this.dataRange.start) {
          this.selectedStartDate = this.dataRange.end;
          this.selectedEndDate = this.dataRange.start;
          range.start = this.selectedStartDate;
          range.end = this.selectedEndDate;
          const dayDiff = -this.dataRange.start.diff(moment(range.end), "days");
          if (dayDiff <= 186) {
            this.selectedStartDate = this.dataRange.start;
            this.selectedEndDate = this.dataRange.end;
          } else {
            this.selectedStartDate = this.dataRange.start;
            this.selectedEndDate = null;
            this.$store.dispatch("toggleSnackbar", {
              show: true,
              message: this.error_message.invalid_data_range,
            });
          }
        }
      } else {
        this.dataRange = {};
      }
      this.$emit("dateRangeSelected", range);
    },
    changeCustomDateRange(day: any) {
      this.selectDateRange(day);
    },
  },
});
