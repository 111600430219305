



































































































import config from "@/config";
import mixins from "vue-typed-mixins";
import { ProfileMixin } from "@/mixins/participant-general-mixin";
import FormModal from "@/components/general/form-modal.vue";
import FormBody from "@/components/form/form-body.vue";
import ParticipantSupportSuccessModal from "@/components/participant/participant-support-success-modal.vue";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";
import TabButtonSelector from "@/components/general/tab-button-selector.vue";
import CollapserCmp from "@/components/general/collapser-cmp.vue";
import SlackCard from "@/components/general/slack-card.vue";
import ActiveProj from "@/components/participant/activities/active-proj.vue";
import ActivityHistory from "@/components/participant/activities/activity-history.vue";
import CreditCard from "@/components/participant/activities/credit-card.vue";
import NewActivity from "@/components/participant/activities/new-activity.vue";
import NoticeBar from "@/components/participant/activities/notice-bar.vue";
import {
  NotificationInfo,
  TransactionInfo,
  VoucherInfo,
} from "@/store/participant/activity";
import {
  ActivityTask,
  ActivityProject,
  ActivityTaskHistory,
  ActivityProjectHistory,
} from "@/store/participant/activity2";
import { DateTime } from "luxon";
import AvailableProjects from "@/components/participant/activities/available-projects.vue";
import GasConsumptionModal from "@/components/general/gas-consumption-modal.vue";

export default mixins(ProfileMixin).extend({
  name: "ActivityPage",
  components: {
    ParticipantSupportSuccessModal,
    ParticipantNoContentInfoCard,
    FormModal,
    FormBody,
    TabButtonSelector,
    CollapserCmp,
    SlackCard,
    ActiveProj,
    ActivityHistory,
    CreditCard,
    NewActivity,
    NoticeBar,
    AvailableProjects,
    GasConsumptionModal,
  },
  computed: {
    projData: {
      get(): ActivityProject {
        // TODO change endpoint
        return this.$store.getters["activityData2/activityProjects"];
      },
    },
    lePastTasks: {
      get(): ActivityProjectHistory[] {
        return this.$store.getters["activityData2/activeProjectHistory"];
      },
    },
    leActiveTasks: {
      get(): ActivityTask {
        return this.$store.getters["activityData2/generalTasks"];
      },
    },

    curActiveTask: {
      get(): ActivityProject[] {
        return this.$store.getters["activityData2/getActivityOnGoingProject"];
      },
    },
    pastActivities: {
      get(): ActivityTaskHistory {
        return this.$store.getters["activityData2/activeTaskHistory"];
      },
    },

    voucherHistory: {
      get(): TransactionInfo[] {
        // TODO change endpoint
        return this.$store.getters["activityData/voucherHistory"];
      },
    },
    voucherList: {
      get(): VoucherInfo[] {
        // TODO change endpoint
        return this.$store.getters["activityData/voucherInfo"];
      },
    },
    notification: {
      get(): NotificationInfo[] {
        return this.$store.getters["activityData/notification"];
      },
    },
    successModalState() {
      return this.$store.getters["modalState"]("success-completing-modal");
    },
  },
  data() {
    return {
      check_btn: 0,
      curTab: 0,
      tabInfo: [
        {
          name: "Projetos",
          id: "Portal",
          icon: "activity/icon-tab-1.svg",
        },
        {
          name: "Tarefas LE",
          id: "Data",
          icon: "activity/icon-tab-2.svg",
        },
        {
          name: "Histórico",
          id: "Equipment",
          icon: "activity/icon-tab-3.svg",
          notificationNumber: 0,
        },
      ],

      creditInfo: {
        currentCredits: 0,
        available: [],
        spent: [],
        email: "",
      } as {
        currentCredits: number;
        spentCredits?: number;
        email: "";
        available: {
          id: number;
          name: string;
          value: string;
          total: number;
          isAvailable?: boolean;
        }[];
        spent: {
          id: number;
          name: string;
          value: string;
          total: number;
          date?: string;
          status: string;
        }[];
      },

      successNotification: {} as {
        id: number;
        name: string;
        credits: number;
      },
      successModal: "success-completing-modal",
      supportSuccessTitle: config.supportSuccessTitle,
      supportSuccessText: config.supportSuccessText,
    };
  },

  created() {
    this.retrieveActivityData();
    if (this.$route.params.curTab)
      this.curTab = Number(this.$route.params.curTab);
  },

  methods: {
    checkProj() {
      this.check_btn = 0;
    },
    checkTask() {
      this.check_btn = 1;
    },
    checkExp() {
      this.check_btn = 2;
    },

    // TODO change endpoint
    async retrieveActivityData() {
      this.$store.commit("updateState", { loading_overlay: true });
      await this.getProjects();
      await this.getGeneralActivities();
      await this.getVoucherInfo(true);
      await this.getNotification();
      this.$store.commit("updateState", { loading_overlay: false });
    },
    async getProjects() {
      await this.$store.dispatch("activityData2/getActivityProjects");
      await this.$store.dispatch("activityData2/getOnGoingProject");
      await this.$store.dispatch("activityData2/getProjectHistory");
    },

    async getGeneralActivities() {
      await this.$store.dispatch("activityData2/getTaskHistory");
      await this.$store.dispatch("activityData2/getGeneralTasks");
    },
    async getVoucherInfo(needsHistory: boolean) {
      await this.$store.dispatch("activityData/getVoucherList");
      if (needsHistory) {
        await this.$store.dispatch("activityData/getVoucherHistory");
      }
      // this is necessary to refresh the current credit balance in case a task gets validated
      await this.$store.dispatch("participantGeneral/getProfileInfo");
      this.creditInfo.available = [];
      this.creditInfo.spent = [];
      this.creditInfo.currentCredits = this.profile_info.credit_balance;
      this.creditInfo.email = this.profile_info.email;
      this.voucherList.forEach((voucher) => {
        this.creditInfo.available.push({
          id: voucher.id,
          name: voucher.name,
          value: voucher.value,
          total: voucher.exchange_point,
          isAvailable: (voucher.stock_level > 0 && voucher.restrict_by_stock_level) || !voucher.restrict_by_stock_level,
        });
      });
      this.updateVoucherHistory();
    },
    updateVoucherHistory() {
      this.voucherHistory.forEach((voucher) => {
        this.creditInfo.spent.push({
          id: voucher.voucher_item.id,
          name: voucher.voucher_item.name,
          value: voucher.voucher_item.value,
          total: voucher.voucher_item.exchange_point,
          status: voucher.status,
          date: DateTime.fromISO(voucher.date_added).toLocaleString(),
        });
      });
    },
    async getNotification() {
      await this.$store.dispatch("activityData/getNotification");
      if (this.notification && this.notification.length) {
        this.notification.forEach((notification) => {
          if (!notification.is_acknowledged) {
            this.successNotification = {
              id: notification.id,
              name: notification.parameters?.project_title || "",
              credits: notification.parameters?.project_total_credit || 0,
            };
            this.openModal(this.successModal);
            return;
          }
        });
      }
    },
    async updateNotificationStatus() {
      if (this.successNotification && this.successNotification.id) {
        this.$store.dispatch(
          "activityData/updateNotification",
          this.successNotification.id
        );
      }
    },
    getTaskState(state: string) {
      switch (state) {
        case "Pending":
          return "validation";
        case "Completed":
          return "completed";
        case "Valid":
          return "completed";
        default:
          return "available";
      }
    },
    changeState(state: number) {
      this.curTab = state;
      if (this.curTab == 2) {
        this.$store.commit("activityData2/updateHistoryNew", 0);
      }
    },
    concludedProjectText() {
      return `<h5 class="header-4 mb-5">Projeto Concluído!</h5>
        <p class="text-center mb-0"> Terminaste com sucesso todas as tarefas do projeto
        <span class="strong-text">"${this.successNotification.name}"</span> 
        </p>         
        <p class="text-center"> O projeto foi guardado no histórico e foram atribuídos
        <span class="strong-text light-blue-text">${this.successNotification.credits} créditos </span>
        á tua conta.
        </p>`;
    },
  },

  watch: {
    successModalState(newVal) {
      if (!newVal) {
        this.updateNotificationStatus();
      }
    },
    // getGeneralUpdate() {
    //   if (this.getGeneralUpdate) {
    //     this.openModal("projSuccess");
    //   }
    // },
  },
});
