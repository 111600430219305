



















































































































































































































































































































































































































































































































































































































import config from "@/config";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import TabButtonSelector from "@/components/general/tab-button-selector.vue";

export default mixins(GeneralMixin).extend({
  name: "TermsConditions",
  components: { TabButtonSelector },
  data() {
    return {
      tabInfo: [
        {
          name: "Termos de Uso",
          id: "Termos",
          icon: "icon-terms.svg",
        },
        {
          name: "Políticas de Privacidade e Cookies",
          id: "Politica",
          icon: "icon-policies.svg",
        },
      ],
      curTab: 0,
      successModal: false,
      supportSuccessTitle: config.supportSuccessTitle,
      supportSuccessText: config.supportSuccessText,
    };
  },
  methods: {
    changeState(state: number) {
      this.curTab = state;
    },
  },

  beforeRouteEnter(to, from, next) {
    const scrollView = document.getElementById("main-participant-view");
    if (scrollView) {
      scrollView.scrollTop = 0;
    }
    next();
  },
});
