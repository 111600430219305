





































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";

import config from "@/config";
import SolidButton from "@/components/general/solid-button.vue";

export default mixins(GeneralMixin).extend({
  name: "ApplianceModal",
  // applianceList is a list of IDs of the current available appliances
  props: [
    "modalName",
    "modalTitle",
    "applianceList",
    "activeList",
    "groupInfo",
  ],
  components: {
    SolidButton,
  },
  computed: {
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        this.closeModal(this.modalName);
      },
    },
  },
  data() {
    return {
      formValues: [] as number[],
      applianceInfo: config.applianceInfo as any,
      btnName: "submit-group",
    };
  },
  mounted() {
    this.formValues.push(...this.activeList);
  },
  methods: {
    updateActiveAppliances(id: number): void {
      const index = this.formValues.indexOf(id);
      if (index > -1) {
        this.formValues.splice(index, 1);
      } else {
        this.formValues.push(id);
      }
    },
    selectAll(): void {
      this.formValues = [];
      this.formValues.push(...this.applianceList.map((x: any) => x.id));
    },
    async submitForm(): Promise<any> {
      if (!this.formValues.length) {
        return false;
      }

      let data = {
        sensors: this.formValues,
      } as any;

      //TODO this needs to be updated once there are different types of groups
      if (this.groupInfo && this.groupInfo.active) {
        data.id = this.groupInfo.active;
      }

      this.$store.commit("toggleButtonState", {
        name: this.btnName,
        status: true,
      });

      const response = await this.$store.dispatch(
        "applianceGroupData/updateApplianceGroup",
        data
      );

      this.$store.commit("toggleButtonState", {
        name: this.btnName,
        status: false,
      });

      if (response && response.success) {
        this.$emit("formAction", this.formValues);
        this.closeModal(this.modalName);
        return true;
      } else {
        return this.$store.dispatch("toggleSnackbar", {
          show: true,
          message:
            "Erro ao submeter as preferências. Por favor tente mais tarde",
        });
      }
    },
    getApplianceIcon(id: number): string {
      if (id && this.applianceInfo[id] && this.applianceInfo[id].icon) {
        return require(`@/assets/icons/appliances/${this.applianceInfo[id].icon}`);
      }
      return require("@/assets/icons/icon-appliance-default.svg");
    },
  },
  watch: {
    // necessary to update the form Value to default, otherwise would keep the state when closing the modal
    // without saving
    modalShow(newValue) {
      if (newValue) {
        this.formValues = [];
        this.formValues.push(...this.activeList);
      }
    },
  },
});
