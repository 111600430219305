










































































































import mixins from "vue-typed-mixins";
import config from "@/config";
import chartConfig from "@/config-chart";

import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";
import ChartCard from "@/components/charts/chart-card.vue";
import TabSelector from "@/components/general/tab-selector.vue";
import { ChartDataStructure, GeneralMixin } from "@/mixins/general-mixin";
import { DateTime } from "luxon";
import { QualityData } from "@/store/participant/quality";

export default mixins(GeneralMixin).extend({
  name: "ParticipantLeituras",
  components: {
    ParticipantNoContentInfoCard,
    ChartCard,
    TabSelector,
  },
  beforeMount() {
    if (this.isEv4eu) {
      this.$router.push("/access/blocked")
    }
  },
  computed: {
    qualityData: {
      get(): QualityData {
        return this.$store.getters["qualityData/qualityInfo"];
      },
    },
  },
  data() {
    return {
      availableRooms: [] as any[],
      thChart1: {} as ChartDataStructure,
      thChart2: {} as ChartDataStructure,
      thChart3: {} as ChartDataStructure,
      classState: "blue-1",
      isWeekdayActive: true,
      currentSelectedWeek: "",
      currentSelectedMonth: "",
      activeDays: [] as number[],
      roomInfo: {
        index: 0,
        name: "",
        color: "",
        icon: "",
        temperature: 1,
        humidity: 0,
        time: "icon-sunny.svg",
      },
      roomDayAverage: {
        average: 0,
        type: "Atual",
        min: {
          date: "",
          value: 0,
        },
        max: {
          date: "",
          value: 0,
        },
      },
      roomMonthAverage: {
        average: 0,
        type: "Mensal",
        min: {
          date: "",
          value: 0,
        },
        max: {
          date: "",
          value: 0,
        },
      },
      roomWeekAverage: {
        average: 0,
        type: "Semanal",
        min: {
          date: "Domingo",
          value: 0,
        },
        max: {
          date: "Janeiro",
          value: 0,
        },
      },
      consumptionTimes1: [
        {
          title: "Dia Atual",
          dates: [new Date()],
        },
        {
          title: "Período Horário",
          dates: [new Date(), new Date()],
        },
      ],
    };
  },

  created() {
    this.initializeChartData(true);
  },

  methods: {
    async changeStateTab(selectedTab: number) {
      //TODO udpate data here and/or do another request ?
      this.classState = selectedTab === 1 ? "violet-1" : "blue-1";
      this.roomInfo.temperature = selectedTab ? 0 : 1;
      this.roomInfo.humidity = !selectedTab ? 0 : 1;
      this.isWeekdayActive = false;
      await setTimeout(() => {
        return;
      }, 100);
      this.activeDays = [];

      this.initializeChartData(false);
      this.isWeekdayActive = true;
    },
    async changeRoomTab(selectedTab: number) {
      this.roomInfo.index = selectedTab;
      this.isWeekdayActive = false;
      await setTimeout(() => {
        return;
      }, 100);
      this.activeDays = [];

      this.initializeChartData(false);
      this.isWeekdayActive = true;
    },
    updateDayRooms(checkboxes: boolean[]) {
      if (!checkboxes || !checkboxes.length) {
        return;
      }

      this.availableRooms.forEach((room, index) => {
        if (this.roomInfo.index !== index) {
          if (checkboxes[0] && this.thChart1.chartInfo.labels) {
            this.thChart1.chartInfo.labels.push(room.name);
            if (this.roomInfo.humidity) {
              this.thChart1.chartInfo.data.push(
                this.qualityData.day.profile[index].humidity_profile
              );
            } else {
              this.thChart1.chartInfo.data.push(
                this.qualityData.day.profile[index].temperature_profile
              );
            }
          }
          if (!checkboxes[0] && this.thChart1.chartInfo.labels) {
            this.thChart1.chartInfo.labels.pop();
            this.thChart1.chartInfo.data.pop();
          }
        }
      });
    },
    async updateSelectedWeek(week: string) {
      if (!week || week === this.currentSelectedWeek) {
        return;
      }

      this.currentSelectedWeek = week;
      this.isWeekdayActive = false;
      await this.$store.dispatch("qualityData/getQualityInfo", {
        start_date: week,
        month_date: `${this.currentSelectedMonth}-01`,
      });

      this.activeDays = [];
      this.thChart2 = JSON.parse(
        JSON.stringify(chartConfig.weeklyTHChartData(!!this.roomInfo.humidity))
      );
      this.retrieveTHWeekData();
      this.isWeekdayActive = true;
    },
    async updateSelectedMonth(month: string) {
      this.currentSelectedMonth = month;
      this.isWeekdayActive = false;
      await this.$store.dispatch("qualityData/getQualityInfo", {
        start_date: this.currentSelectedWeek,
        month_date: `${month}-01`,
      });

      this.thChart3 = JSON.parse(
        JSON.stringify(chartConfig.monthlyTHChartData(!!this.roomInfo.humidity))
      );
      this.retrieveTHMonthData();
      this.isWeekdayActive = true;
    },
    updateActiveWeekDays(date: number[]) {
      const chartInfo = this.thChart2.chartInfo;
      const data = this.qualityData.week_before;
      if (chartInfo && chartInfo.labels) {
        date.forEach((day) => {
          if (!this.activeDays.includes(day) && chartInfo.labels) {
            if (!data.profile[day][this.roomInfo.index]) {
              this.$store.dispatch("toggleSnackbar", {
                show: true,
                message: "Seleção sem dados disponíveis",
                color: "blue",
              });
              this.activeDays.push(day);
              return;
            }
            chartInfo.labels.push(config.daysWeekPT[day]);
            chartInfo.colors.push({ border: config.colorsDaysWeekPT[day] });
            chartInfo.data.push(
              this.roomInfo.humidity
                ? data.profile[day][this.roomInfo.index].humidity_profile
                : data.profile[day][this.roomInfo.index].temperature_profile
            );
            this.activeDays.push(day);
          }
        });
        this.activeDays.forEach((day, index) => {
          if (!date.includes(day) && chartInfo.labels) {
            let pos = chartInfo.labels.indexOf(config.daysWeekPT[day]);
            if (pos !== -1) {
              chartInfo.labels.splice(pos, 1);
              chartInfo.colors.splice(pos, 1);
              chartInfo.data.splice(pos, 1);
            }
            this.activeDays.splice(index, 1);
          }
        });
      }
    },
    initializeChartData(doRequest: boolean) {
      this.thChart1 = JSON.parse(
        JSON.stringify(chartConfig.dailyTHChartData(!!this.roomInfo.humidity))
      );

      this.thChart2 = JSON.parse(
        JSON.stringify(chartConfig.weeklyTHChartData(!!this.roomInfo.humidity))
      );

      this.thChart3 = JSON.parse(
        JSON.stringify(chartConfig.monthlyTHChartData(!!this.roomInfo.humidity))
      );

      this.retrieveQualityData(doRequest);
    },
    initializeRoomData(index: number, isHumidity: boolean) {
      const roomInfo = config.roomInfo;
      const statInfo = this.qualityData.day.real_time_ht;
      this.availableRooms = [];
      // TODO lmao
      statInfo.forEach((data) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const room = roomInfo[data.device_type_id];
        this.availableRooms.push({
          name: room.name,
          icon: `rooms/${room.icon}`,
          value: isHumidity ? data.humidity : data.temperature,
          unit: isHumidity ? "%" : "ºC",
        });
      });
      const curHour = DateTime.now().hour;
      if (this.availableRooms.length > 0) {
        this.roomInfo = {
          index,
          name: this.availableRooms[index].name,
          color: this.classState,
          icon: this.availableRooms[index].icon,
          temperature: this.roomInfo.temperature,
          humidity: this.roomInfo.humidity,
          time:
            curHour < 19 && curHour > 7 ? "icon-sunny.svg" : "icon-moon.svg",
        };
      }
    },
    async retrieveQualityData(doRequest: boolean) {
      this.$store.commit("updateState", { loading_overlay: true });
      if (doRequest) {
        await this.$store.dispatch("qualityData/getQualityInfo");
      }

      if (this.qualityData) {
        this.initializeRoomData(
          this.roomInfo.index,
          this.roomInfo.humidity === 1 ? true : false
        );
        if (this.qualityData.day.stats.length > 0) {
          this.retrieveTHDayData();
        }
        if (this.qualityData.week_before.stats.length > 0) {
          this.retrieveTHWeekData();
        }
        if (this.qualityData.month_before.stats.length > 0) {
          this.retrieveTHMonthData();
        }
      }
      this.$store.commit("updateState", { loading_overlay: false });
    },
    retrieveTHDayData() {
      this.thChart1.chartInfo.data = [[]];
      this.thChart1.chartInfo.labels = [this.roomInfo.name];

      const data = this.qualityData.day;
      const startMilliseconds = DateTime.now().startOf("day").toMillis();

      this.thChart1.chartInfo.rangeValues.min = startMilliseconds;
      // max is the 24h (in milliseconds) range since the starting time
      this.thChart1.chartInfo.rangeValues.max = startMilliseconds + 82800000;

      let statInfo = data.stats[this.roomInfo.index].temperature_stats;
      if (this.roomInfo.humidity) {
        this.thChart1.chartInfo.data = [
          data.profile[this.roomInfo.index].humidity_profile,
        ];
        statInfo = data.stats[this.roomInfo.index].humidity_stats;
      }
      if (this.roomInfo.temperature) {
        this.thChart1.chartInfo.data = [
          data.profile[this.roomInfo.index].temperature_profile,
        ];
      }

      this.roomDayAverage.average = statInfo.avg;
      this.roomDayAverage.min = {
        date: `${DateTime.fromSQL(statInfo.min_hour).hour}:00`,
        value: statInfo.min,
      };
      this.roomDayAverage.max = {
        date: `${DateTime.fromSQL(statInfo.max_hour).hour}:00`,
        value: statInfo.max,
      };

      this.roomDayAverage = { ...this.roomDayAverage, ...this.roomInfo };

      this.thChart1.chartExtraOptions = {
        roomAverage: this.roomDayAverage,
        subTitle: `${DateTime.fromSQL(data.start_time).hour}:00 - ${
          DateTime.fromSQL(data.end_time).hour
        }:00`,
        checkboxSelection: {
          list: ["Comparar Divisões"],
          color: this.classState,
          defaultCheck: false,
        },
      };

      this.thChart1.isVisible = true;
    },

    retrieveTHWeekData() {
      this.thChart2.chartInfo.data = [[]];
      this.thChart2.chartInfo.labels = [this.roomInfo.name];

      const data = this.qualityData.week_before;

      const startMilliseconds = DateTime.now().startOf("day").toMillis();

      this.thChart2.chartInfo.rangeValues.min = startMilliseconds;
      // max is the 24h (in milliseconds) range since the starting time
      this.thChart2.chartInfo.rangeValues.max = startMilliseconds + 82800000;

      let statInfo = data.stats[this.roomInfo.index].temperature_stats;
      if (this.roomInfo.humidity) {
        this.thChart2.chartInfo.data = [
          data.profile[-1][this.roomInfo.index].humidity_profile,
        ];
        statInfo = data.stats[this.roomInfo.index].humidity_stats;
      }
      if (this.roomInfo.temperature) {
        this.thChart2.chartInfo.data = [
          data.profile[-1][this.roomInfo.index].temperature_profile,
        ];
      }

      this.roomWeekAverage.average = statInfo.avg;
      this.roomWeekAverage.min = {
        date: DateTime.fromSQL(statInfo.min_date).weekdayShort,
        value: statInfo.min,
      };
      this.roomWeekAverage.max = {
        date: DateTime.fromSQL(statInfo.max_date).weekdayShort,
        value: statInfo.max,
      };

      this.roomWeekAverage = { ...this.roomWeekAverage, ...this.roomInfo };
      this.thChart2.chartExtraOptions = {
        subTitle: `de ${data.start_date} a ${data.end_date}`,
        roomAverage: this.roomWeekAverage,
      };

      this.thChart2.isVisible = true;
    },
    retrieveTHMonthData() {
      this.thChart3.chartInfo.data = [[]];

      const data = this.qualityData.month_before;

      this.thChart3.chartInfo.rangeValues.max = DateTime.fromSQL(
        data.start_date
      )
        .endOf("month")
        .toMillis();
      this.thChart3.chartInfo.rangeValues.min = DateTime.fromSQL(
        data.start_date
      )
        .startOf("month")
        .toMillis();

      let statInfo = data.stats[this.roomInfo.index].temperature_stats;
      if (this.roomInfo.humidity) {
        this.thChart3.chartInfo.data = [
          data.profile.avg[this.roomInfo.index].humidity_profile,
          data.profile.min[this.roomInfo.index].humidity_profile,
          data.profile.max[this.roomInfo.index].humidity_profile,
        ];
        statInfo = data.stats[this.roomInfo.index].humidity_stats;
      }
      if (this.roomInfo.temperature) {
        this.thChart3.chartInfo.data = [
          data.profile.avg[this.roomInfo.index].temperature_profile,
          data.profile.min[this.roomInfo.index].temperature_profile,
          data.profile.max[this.roomInfo.index].temperature_profile,
        ];
      }

      this.roomMonthAverage.average = statInfo.avg;
      this.roomMonthAverage.min = {
        date: `Dia ${statInfo.min_day}`,
        value: statInfo.min,
      };
      this.roomMonthAverage.max = {
        date: `Dia ${statInfo.max_day}`,
        value: statInfo.max,
      };

      this.roomMonthAverage = { ...this.roomMonthAverage, ...this.roomInfo };

      this.thChart3.chartExtraOptions = {
        subTitle: `${DateTime.fromSQL(data.start_date).monthLong}, ${
          DateTime.fromSQL(data.start_date).year
        }`,
        roomAverage: this.roomMonthAverage,
      };

      this.thChart3.isVisible = true;
    },
  },
});
