import { Module, GetterTree, MutationTree, ActionTree, Mutation } from "vuex";
import store, { RootState, Nullable } from "../../store/index";
import customAxios from "@/axios-service";

export interface ApplianceGroupData {
  groups?: {
    [key: string]: {
      sensors: number[];
    };
  };
  active?: string;
  sensors?: {
    id: number;
    device_type_id: number;
    device_type_name: string;
  }[];
}

export interface ApplianceGroupInfo {
  appliance_groups: Nullable<ApplianceGroupData>;
}

export const state: ApplianceGroupInfo = {
  appliance_groups: JSON.parse(
    sessionStorage.getItem("appliance-groups") || "null"
  ),
};

const getters: GetterTree<ApplianceGroupInfo, RootState> = {
  applianceGroups: (state) => {
    return state.appliance_groups;
  },
};

const mutations: MutationTree<ApplianceGroupInfo> = {
  updateApplianceGroups: (state, value: ApplianceGroupData) => {
    state.appliance_groups = value;
    sessionStorage.setItem(
      "appliance-groups",
      JSON.stringify(state.appliance_groups)
    );
  },
};

const actions: ActionTree<ApplianceGroupInfo, RootState> = {
  async getApplianceGroups({ commit }) {
    return customAxios
      .get(`/record/preference/1`)
      .then((response) => {
        return commit("updateApplianceGroups", response.data);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async updateApplianceGroup({ commit }, data: any) {
    return customAxios
      .post(`/record/preference/1`, data)
      .then((response: any) => {
        return response.data;
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
};

const namespaced = true;

const applianceGroupData: Module<ApplianceGroupInfo, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default applianceGroupData;
