





















































import Vue from "vue";
import { DateTime } from "luxon";
import config from "@/config";

export default Vue.extend({
  name: "FormDatePicker",
  props: {
    color: {
      type: String,
      required: false,
    },
    type: {
      type: Number,
      required: false,
    },
    labelPlaceholder: {
      type: String,
      default: "",
      required: false,
    },
    isActive: {
      type: Boolean,
      default: true,
      required: false,
    },
    weekStart: {
      type: Boolean,
      default: false,
      required: false,
    },
    fullWeekPicker: {
      type: Boolean,
      default: false,
      required: false,
    },
    monthPicker: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabledSelections: {
      type: Array as () => number[],
      default: () => [],
      required: false,
    },
  },
  computed: {
    computedRange: {
      get(): [] {
        return this.datePicker;
      },
      set(values: any): any {
        if (this.weekStart) {
          // luxon predefined weeks to start on monday.
          const day = values[0];
          const lastWeekDay = DateTime.fromSQL(day);

          const firstDay = lastWeekDay.startOf("week").toISODate();
          const lastDay = lastWeekDay.endOf("week").toISODate();
          this.datePicker = [firstDay, lastDay];
          this.labelDisplay = `${firstDay} - ${lastDay}`;
        } else if (this.fullWeekPicker) {
          if (this.datePicker.length === 2) {
            const oldFirst = DateTime.fromISO(this.datePicker[0]);
            const oldLast = DateTime.fromISO(this.datePicker[1]);

            // This case reset the picker to a new pick
            if (oldLast.startOf("week").toMillis() !== oldFirst.toMillis()) {
              const day = DateTime.fromSQL(values[0]);
              const firstDay = day.startOf("week").toISODate();
              const lastDay = day.endOf("week").toISODate();
              this.datePicker = [firstDay, lastDay];
              this.labelDisplay = `${firstDay} - ${lastDay}`;
            } else {
              const newDay = DateTime.fromSQL(values[0]);
              // if new picked day is before the first one, we agregate the new one as the first day with the old Last one
              if (newDay.toMillis() < oldFirst.toMillis()) {
                const firstDay = newDay.startOf("week").toISODate();
                this.datePicker = [firstDay, this.datePicker[1]];
                this.labelDisplay = `${firstDay} - ${this.datePicker[1]}`;
              }
              // if new picked day is after the last one, we agregate the old first one as the first day with the new Last one
              if (newDay.toMillis() > oldLast.toMillis()) {
                const lastDay = newDay.endOf("week").toISODate();
                this.datePicker = [this.datePicker[0], lastDay];
                this.labelDisplay = `${this.datePicker[0]} - ${lastDay}`;
              }
            }
          }
        } else {
          this.datePicker = values;
          this.labelDisplay = ` ${
            config.monthsPT[DateTime.fromSQL(`${values}-01`).month]
          } de ${DateTime.fromSQL(`${values}-01`).year}`;
        }
      },
    },
  },
  data() {
    return {
      datePicker: [] as any,
      labelDisplay: "",
      menuModel: false,
      selectedValue: [] as any,
    };
  },
  // Default value should always be the most recent values
  // Unless if there is a pick all option. Might reconsider that then
  mounted() {
    if (this.weekStart || this.fullWeekPicker) {
      const lastWeekDay = DateTime.now().minus({ days: 7 });
      this.datePicker.push(lastWeekDay.startOf("week").toISODate());
      this.datePicker.push(lastWeekDay.endOf("week").toISODate());
      this.labelDisplay = `${this.datePicker[0]} - ${this.datePicker[1]}`;
    }
    if (this.monthPicker) {
      this.datePicker = DateTime.now().minus({ months: 1 }).toISODate();
      this.labelDisplay = ` ${
        config.monthsPT[DateTime.fromSQL(this.datePicker).month]
      } de ${DateTime.fromSQL(this.datePicker).year}`;
    }
  },
  methods: {
    emitValueChange() {
      if (this.isActive === false) {
        return;
      }
      if (this.weekStart && this.datePicker?.length) {
        this.selectedValue = this.datePicker[0];
      } else if (this.datePicker?.length) {
        this.selectedValue = this.datePicker;
      }

      this.$emit("dateSelected", this.selectedValue);
    },
    allowedDates(val: string): boolean {
      if (this.monthPicker) {
        return (
          DateTime.fromSQL(`${val}-01`).toMillis() < DateTime.now().toMillis()
        );
      }
      const lastWeekDay = DateTime.now().minus({ days: 7 }).endOf("week");
      let extraRules = true;

      return (
        DateTime.fromSQL(val).toMillis() < lastWeekDay.toMillis() && extraRules
      );
    },
  },
  watch: {
    isActive(newValue) {
      if (newValue === false) {
        this.selectedValue = [];
      }
    },
  },
});
