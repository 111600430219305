


































































import mixins from "vue-typed-mixins";
import { FormMixin } from "@/mixins/form-mixin";
import toolTip from "../general/tool-tip.vue";

export default mixins(FormMixin).extend({
  name: "FormSelect",
  components: { toolTip },
  props: {
    isReadOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      otherFieldValue: null,
    };
  },
  created() {
    this.parseFieldOptions();
  },
  computed: {
    renderOtherTextField(): boolean {
      const availableSelectOptions = this.fieldOptions?.map(
        ({ value }) => value
      );

      const isOtherValue =
        (this.fieldValue &&
          !availableSelectOptions.includes(this.fieldValue)) ||
        this.fieldValue?.toLowerCase() === "outro" ||
        this.fieldValue?.toLowerCase() === "outra";

      if (this.fieldValue) {
        this.otherFieldValue =
          this.fieldValue?.toLowerCase() == "outro" ||
          this.fieldValue?.toLowerCase() == "outra"
            ? ""
            : this.fieldValue;
      }

      return isOtherValue;
    },
    shouldShowLabel(): boolean {
      return (
        this.formItem.show_title &&
        this.formItem.show_title_as_float &&
        (this.isFieldLabelVisible ||
          (this.formItem.title && this.formItem.title !== ""))
      );
    },
  },
  methods: {
    handleOnLeaveAndEmpty(): void {
      if (!this.otherFieldValue) {
        this.resetField();
      }
    },
  },
});
