


















import Vue from "vue";
import { DateTime } from "luxon";

export default Vue.extend({
  name: "TimeRangeCard",
  components: {},
  props: {
    time1: {
      required: true,
      type: Date,
    },
    time2: {
      required: true,
      type: Date,
    },
  },
  data() {
    return {};
  },
  methods: {
    getCurrentHour(dateMili: any): string {
      return dateMili.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
});
