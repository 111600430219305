
































































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import Snackbar from "@/components/general/snackbar.vue";
import FooterGeneralNoBg from "@/components/general/footer-general-no-bg.vue";

export default mixins(GeneralMixin).extend({
  name: "Login",
  components: {
    Snackbar,
    FooterGeneralNoBg,
  },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      rules: {
        required: (value: any) => !!value || "Obrigatório!",
        email: (value: any) =>
          !this.isEmailValid(value) ? this.error_message.invalid_email : true,
      },
      isRecovery: false,
    };
  },
  methods: {
    async postLoginForm(): Promise<any> {
      await this.$store.dispatch("toggleSnackbar", null);
      if (this.form.validate()) {
        this.loading = true;
        const data = { email: this.email, password: this.password };
        return this.$store.dispatch("login", data).then(() => {
          this.loading = false;
        });
      } else {
        return this.$store.dispatch("toggleSnackbar", {
          show: true,
          message: this.error_message.form_has_error,
        });
      }
    },
  },
});
