





























import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";

export default mixins(GeneralMixin).extend({
  name: "Collapser",
  props: {
    idkey: {
      required: false,
      type: Number,
    },
    title: {
      required: true,
      type: String,
    },
    content: {
      required: true,
      type: String,
    },
  },
  computed: {
    opened_support_id: {
      get(): string {
        return this.$store.getters["supportData/openedSupportId"];
      },
    },
  },
  methods: {
    handleClick(): any {
      const val =
        this.idkey.toString() == this.opened_support_id ? -1 : this.idkey;
      this.$store.commit("supportData/updateOpenedSupportId", val);
    },
  },
});
