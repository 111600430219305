var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 py-0"},[_c('v-row',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeMenu),expression:"closeMenu"}],staticClass:"menu justify-end flex-nowrap",attrs:{"align":"center"},on:{"click":_vm.showMenu}},[_c('v-col',{staticClass:"d-flex justify-center px-0 py-0 mr-n5",attrs:{"cols":"3"}}),_c('v-col',{staticClass:"pl-sm-1 pl-md-2 d-none d-sm-inline-block clickable-content",class:{
      'profile-info-mobile':
        _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm,
      'profile-info':
        _vm.$vuetify.breakpoint.md ||
        _vm.$vuetify.breakpoint.lg ||
        _vm.$vuetify.breakpoint.xl,
    },attrs:{"cols":"auto"}},[_c('p',{staticClass:"text-x mb-0 dropdown-component",class:{
        'profile-name-mobile':
          _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm,
        'profile-name':
          _vm.$vuetify.breakpoint.md ||
          _vm.$vuetify.breakpoint.lg ||
          _vm.$vuetify.breakpoint.xl,
      }},[_vm._v(" "+_vm._s(_vm.profile_info && _vm.profile_info.first_name !== undefined ? _vm.profile_info.first_name : "")+" "+_vm._s(_vm.profile_info && _vm.profile_info.last_name !== undefined ? _vm.profile_info.last_name : "")+" ")]),_c('p',{staticClass:"text-2 mb-0 dropdown-component",class:{
            'profile-position-mobile':
              _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm,
            'profile-position':
              _vm.$vuetify.breakpoint.md ||
              _vm.$vuetify.breakpoint.lg ||
              _vm.$vuetify.breakpoint.xl,
          }},[_vm._v(" "+_vm._s(_vm.profile_info && _vm.profile_info["participant_type"] !== undefined ? _vm.profile_info["participant_type"] : "")+" ")])]),_c('v-col',{staticClass:"pl-0 py-1 pr-7 dropdown-box",attrs:{"cols":"auto"}},[_c('v-avatar',{class:{
            'profile-image-mobile':
              _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm,
            'profile-image':
              _vm.$vuetify.breakpoint.md ||
              _vm.$vuetify.breakpoint.lg ||
              _vm.$vuetify.breakpoint.xl,
          },attrs:{"width":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? '25px' : '44px',"height":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? '25px' : '44px',"min-width":"25px"}},[_c('img',{staticClass:"dropdown-component clickable-content",attrs:{"src":_vm.profile_info && _vm.profile_info.passport_photo
            ? _vm.profile_info.passport_photo
            : require('@/assets/icons/icon-passport-small-avatar.svg'),"alt":_vm.profile_info && _vm.profile_info[2] !== undefined
    ? _vm.profile_info[2]
    : '-'}})]),_c('div',{staticClass:"dropdown-content",class:{
        'dropdown-content-mobile': _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ,
        'dropdown-show': _vm.show_menu,
      }},[_c('router-link',{staticClass:"d-flex",attrs:{"to":{ path: '/participant/profile' }}},[_c('img',{staticClass:"list-icon",attrs:{"src":require("@/assets/icons/menus/icon-profile.svg")}}),_vm._v(" O Meu Perfil")]),_c('router-link',{staticClass:"d-flex",attrs:{"to":{ path: '/participant/profile' }},nativeOn:{"click":function($event){return _vm.displayPasswordModal()}}},[_c('img',{staticClass:"list-icon",attrs:{"src":require("@/assets/icons/menus/icon-lock.svg"),"height":"18px"}}),_vm._v(" Mudar Palavra-Passe")]),_c('div',{staticClass:"dropdown-liner"}),_c('router-link',{staticClass:"d-flex",attrs:{"to":{ path: '/logout' }}},[_c('img',{staticClass:"list-icon",attrs:{"src":require("@/assets/icons/menus/icon-logout.svg")}}),_vm._v(" Logout")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }