



































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import SolidButton from "@/components/general/solid-button.vue";

export default mixins(GeneralMixin).extend({
  name: "ParticipantSucessModal",
  components: {
    SolidButton,
  },
  props: [
    "modalName",
    "modalTitle",
    "modalContent",
    "modalImage",
    "modalButtonLabel",
    "reloadOnClose"
  ],
  computed: {
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        this.closeModal(this.modalName, this.reloadOnClose);
      },
    },
  },
  methods: {
    routeParentAction() {
      this.$emit("callParentAction");
    },
  },
});
